import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import DashboardHeader from 'components/DashboardHeader';
import FaqCard from 'components/FAQ/FaqCard';
import SetupAssistantBanner from 'components/SetupAssistantBanner';
import useAssistantManager from 'hooks/useAssistantManager';
import React from 'react';

function AssistantConfigSection() {
  const { hasSubmittedAnyData } = useAssistantManager({ statusControlEnabled: false });

  if (hasSubmittedAnyData) {
    return (
      <BdsGrid gap="1" lg="8" xg="8" md="8">
        <DashboardHeader />
        <FaqCard />
      </BdsGrid>
    );
  }

  return <SetupAssistantBanner />;
}

export default AssistantConfigSection;
