import BlipBallon from 'assets/images/blip-logo-balloon.svg';
import { BdsChipTag, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import NameInitials from 'components/NameInitials';
import SidebarItem from 'components/SidebarItem';
import { VERSION } from 'libs/app';
import React from 'react';
import './style.scss';

function Sidebar() {
  return (
    <div className="sidebar">
      <BdsGrid
        gap="4"
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        height="100%"
        padding="y-4"
      >
        <BdsGrid gap="1" alignItems="center" direction="column" justifyContent="center">
          <img className="blip-balloon-logo" alt="Blip logo" src={BlipBallon} />
          <BdsChipTag color="outline">COEX</BdsChipTag>
        </BdsGrid>

        <BdsGrid gap="2" alignItems="center" direction="column" justifyContent="center">
          <SidebarItem route="/dashboard" moduleName="dashboard" tooltip="Home" icon="home" />
          <SidebarItem route="/ads" moduleName="ads" tooltip="Gerenciador de anúncios" icon="target" />
          <SidebarItem route="/contacts" moduleName="contacts" tooltip="Contatos" icon="contact" />
          <SidebarItem route="/audience" moduleName="campaign" tooltip="Mensagens ativas" icon="message-sent" />
          <SidebarItem route="/config" moduleName="config" tooltip="Configuração do Assistente" icon="robot-2" />
        </BdsGrid>
        <BdsGrid direction="column" gap="1">
          <NameInitials />
          <BdsTypo variant="fs-12">v{VERSION}</BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </div>
  );
}

export default Sidebar;
