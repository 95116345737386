import { BdsChipTag, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import BotAvatar from '../../FaqWizard/BotAvatar';
import './style.scss';

type GptSimulatorHeaderProps = {
  openModal: () => void;
};

function GptSimulatorHeader({ openModal }: GptSimulatorHeaderProps) {
  const isMobileView = useMobileView();

  const handleCloseTester = () => {
    openModal();
  };

  return (
    <>
      <BdsGrid class="chatheader-container" padding="y-2" alignItems="center">
        <BdsGrid xxs="8" padding="l-3" direction="row" gap="2">
          <BdsGrid>
            <BotAvatar />
          </BdsGrid>
          <BdsGrid direction="column">
            <BdsTypo variant="fs-16" bold="bold" lineHeight="plus">
              Assistente Virtual
            </BdsTypo>
            <BdsChipTag color="outline">Teste</BdsChipTag>
          </BdsGrid>
        </BdsGrid>
        {isMobileView && (
          <BdsGrid xxs="4" padding="r-3" justifyContent="flex-end">
            <BdsIcon class="cursor-pointer" onClick={handleCloseTester} name="close" type="icon" size="large" />
          </BdsGrid>
        )}
      </BdsGrid>
    </>
  );
}

export default GptSimulatorHeader;
