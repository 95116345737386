import { BdsButton, BdsGrid, BdsIcon, BdsLoadingSpinner, BdsPagination, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MainContainer from 'components/MainContainer';
import MainHeader from 'components/MainHeader';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import useBlipGoApi from 'hooks/useBlipGoApi';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './CampaignsAudience.module.scss';
import CampaignAudienceItem from 'components/CampaignAudienceItem/CampaignAudienceItem';
import { useAppContext } from 'contexts/AppContext';
import WhatsAppDisconnected from 'components/WhatsappDisconected';
import useTrack from 'hooks/useTrack';
import { COEX_ACTIVE_MESSAGE_NEW, COEX_ACTIVE_MESSAGE_REFRESH } from 'libs/trackingEvents';

function CampaignsAudience() {
  const isMobileView = useMobileView();
  const { track } = useTrack();

  const { getCampaignsAudience } = useBlipGoApi();
  const { whatsAppActivated } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<CampaignData | undefined>();

  const PAGE_SIZE = 10;
  const INITIAL_PAGE = 1;

  const navigate = useNavigate();

  const loadCampaigns = async (pageNumber?: number, pageSize?: number) => {
    try {
      setIsLoading(true);
      let campaignsData = await getCampaignsAudience(pageNumber, pageSize);
      setCampaigns(campaignsData);
    } catch (error) {
      navigate('/campaign');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (whatsAppActivated) {
      loadCampaigns(INITIAL_PAGE, PAGE_SIZE);
    }
  }, []);

  const handleNewMessageButton = () => {
    track(COEX_ACTIVE_MESSAGE_NEW);
    navigate('/campaign');
  };

  const handleRefreshButton = () => {
    track(COEX_ACTIVE_MESSAGE_REFRESH);
    loadCampaigns(INITIAL_PAGE, PAGE_SIZE);
  };

  return (
    <MainContainer>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <BdsGrid gap="2" direction="column">
        <BdsGrid
          justifyContent="space-between"
          lg="12"
          xg="12"
          margin={!isMobileView ? 'b-3' : 'b-1'}
          direction={!isMobileView ? 'row' : 'column'}
        >
          <MainHeader
            title="Mensagens ativas"
            description="Dispare mensagens personalizáveis para vários clientes ao mesmo tempo."
            hideHelpButton
          />
          {whatsAppActivated && (
            <BdsGrid margin={!isMobileView ? 'none' : 't-3'}>
              <BdsButton variant="primary" onClick={() => handleNewMessageButton()}>
                Enviar nova mensagem
              </BdsButton>
            </BdsGrid>
          )}
        </BdsGrid>
        {isMobileView && <div className="horizontal-line" />}
        {whatsAppActivated ? (
          <>
            <BdsGrid justifyContent="space-between">
              <BdsGrid alignItems="center">
                <BdsTypo variant="fs-16" bold="bold">
                  Acompanhamento de disparos
                </BdsTypo>
              </BdsGrid>
              <BdsGrid className={style['refresh-button']} gap="half" onClick={() => handleRefreshButton()}>
                <BdsIcon name="refresh"></BdsIcon>
                <BdsTypo>Atualizar</BdsTypo>
              </BdsGrid>
            </BdsGrid>
            {isLoading ? <Loader /> : <CampaingsSection campaigns={campaigns} />}
            <BdsGrid justifyContent="center">
              <BdsPagination
                className="contacts-pagination"
                numberItems={campaigns?.totalItems}
                startedPage={1}
                pageCounter={true}
                pages={campaigns?.totalPages}
                optionsPosition="top"
                onBdsPaginationChange={ev => loadCampaigns(ev?.detail, PAGE_SIZE)}
              />
            </BdsGrid>
          </>
        ) : (
          <WhatsAppDisconnected />
        )}
      </BdsGrid>
    </MainContainer>
  );
}

function Loader() {
  return (
    <BdsGrid gap="1" padding="y-4" alignItems="center" justifyContent="center">
      <BdsLoadingSpinner size="small" />
      <BdsTypo variant="fs-14"> Carregando campanhas...</BdsTypo>
    </BdsGrid>
  );
}

function CampaingsSection({ campaigns }: Readonly<{ campaigns: CampaignData | undefined }>) {
  return (
    <BdsGrid direction="column" gap="1" className="fade-in">
      {campaigns?.items?.map((campaign: CampaignItem) => (
        <CampaignAudienceItem key={campaign.id} campaign={campaign} />
      ))}
    </BdsGrid>
  );
}

export default CampaignsAudience;
