import App from 'App';
import { JSX as LocalJSX, defineCustomElements } from 'blip-ds/loader';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { createRoot } from 'react-dom/client';

type StencilToReactElements<T = LocalJSX.IntrinsicElements> = {
  [P in keyof T]?: T[P] &
    Omit<DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>, 'className'> & {
      class?: string;
    };
};
type StencilToReactRef<T = HTMLElementTagNameMap> = {
  [P in keyof T]: {
    ref?: DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>['ref'];
  };
};
type StencilToReact<T = LocalJSX.IntrinsicElements, U = HTMLElementTagNameMap> = StencilToReactElements<T> &
  StencilToReactRef<U>;
declare global {
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact {}
  }
}
defineCustomElements(window);

const launchdarklyId = process.env.REACT_APP_LAUNCHDARKLY_ID ?? '';

const analyticsWriteKey = process.env.REACT_APP_ANALYTICS_KEY;
if (analyticsWriteKey) {
  analytics.load(analyticsWriteKey);
  analytics.page();
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchdarklyId,
  });

  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Failed to find the root element');
  const root = createRoot(rootElement);

  root.render(
    <LDProvider>
      <App />
    </LDProvider>,
  );
})();
