type CampaignItem = {
  id: string;
  name: string;
  created: Date | string;
  scheduled: Date | string;
  campaignStatus: string;
  campaignType: string;
  total: number;
  processed: number;
  received: number;
  read: number;
  failed: number;
};

type CampaignData = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  items: CampaignItem[];
};

export enum AudienceSource {
  Group = 'group',
  Custom = 'custom',
}
