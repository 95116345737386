import { selectedFacebookPage } from 'atoms/AppAtoms';
import {
  BdsAvatar,
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsLoadingSpinner,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import FacebookConectedAccount from 'components/Instagram/FacebookConectedAccount';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { InstagramSteps } from 'libs/instagramSteps';
import {
  CONNECT_INSTAGRAM_CLICK_CONNECT,
  CONNECT_INSTAGRAM_CREATE_FB_PAGE,
  CONNECT_INSTAGRAM_PAGE_ERROR,
  CONNECT_INSTAGRAM_PAGE_ERROR_BACK,
  CONNECT_INSTAGRAM_PAGE_NOTFOUND_CLICK,
  CONNECT_INSTAGRAM_VIEWPAGE_CONNECT,
} from 'libs/trackingEvents';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FbPagesResult } from 'types/Facebook';
import InstagramFacebookPageNotListedModal from '../InstagramFacebookPageNotListedModal';
import './style.scss';

type InstagramStepFacebookPagesProps = {
  setCurrentStep: (step: InstagramSteps) => void;
};

function InstagramStepFacebookPages({ setCurrentStep }: InstagramStepFacebookPagesProps) {
  const [, setSelectedFacebookPage] = useAtom(selectedFacebookPage);
  const [pageList, setPageList] = useState<FbPagesResult[] | undefined>(undefined);
  const { getFacebookConnectedData } = useGraphApi();
  const { getPages } = useGraphApi();
  const { createToastWarning } = useToast();
  const { track } = useTrack();
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const fbConnectedData = getFacebookConnectedData();
  const pageNotListedModal = useRef<HTMLBdsModalElement | null>(null);

  const handleUserPageClick = (selectedPage: FbPagesResult) => {
    track(CONNECT_INSTAGRAM_CLICK_CONNECT);
    setSelectedFacebookPage(selectedPage);
    if (!selectedPage?.connected_instagram_account || !selectedPage?.connected_instagram_account?.id) {
      setCurrentStep(InstagramSteps.InstagramPageNotLinked);
    } else {
      setCurrentStep(InstagramSteps.InstagramActivation);
    }
  };

  const handlePageNotListedClick = () => {
    track(CONNECT_INSTAGRAM_PAGE_NOTFOUND_CLICK);
    pageNotListedModal.current?.toggle();
  };

  const getFacebookPages = async () => {
    if (!fbConnectedData) {
      track(CONNECT_INSTAGRAM_PAGE_ERROR, { status: 'error', error: 'No facebook data found' });
      createToastWarning('Por favor, realize o login com o Facebook novamente.');
      return navigate('/dashboard');
    }

    const pageList = await getPages(fbConnectedData.userID, fbConnectedData.accessToken);
    setPageList(pageList);

    if (pageList.length === 0) {
      return track(CONNECT_INSTAGRAM_PAGE_ERROR, {
        status: 'error',
        pageviewFB: 0,
        error: 'No facebook pages found',
      });
    }

    track(CONNECT_INSTAGRAM_VIEWPAGE_CONNECT, { status: 'success', pageviewFB: pageList.length });
  };

  useEffect(() => {
    getFacebookPages();
  }, []);

  if (!pageList) {
    return <BdsLoadingSpinner />;
  }

  return (
    <>
      <BdsGrid
        direction={!isMobileView ? 'row' : 'column'}
        alignItems={!isMobileView ? 'center' : 'flex-start'}
        gap="4"
        margin="b-5"
        justifyContent="space-between"
      >
        <FacebookConectedAccount setCurrentStep={setCurrentStep} />
        {pageList.length ? (
          <BdsGrid gap="4" direction="column">
            <BdsTypo tag="span" variant={!isMobileView ? 'fs-24' : 'fs-20'} bold="bold">
              Selecione a página da sua empresa
            </BdsTypo>
            {!isMobileView && (
              <BdsTypo tag="p">
                Veja abaixo as <b>páginas comerciais</b> do Facebook que a conta {fbConnectedData?.name} tem acesso e
                escolha a que você usa para <b>a sua empresa:</b>
              </BdsTypo>
            )}
            <BdsGrid direction="column">
              {pageList.map(page => {
                return (
                  <BdsGrid
                    key={page.id}
                    className="page-item"
                    direction={!isMobileView ? 'row' : 'column'}
                    justifyContent="space-between"
                    alignItems={!isMobileView ? 'center' : 'flex-start'}
                  >
                    <BdsGrid gap="3" margin={!isMobileView ? undefined : 'b-2'}>
                      <BdsAvatar id="avatar" name={page.name} size={!isMobileView ? 'standard' : 'small'} />
                      <BdsTypo variant="fs-16" bold="bold" className={!isMobileView ? 'p-t-1' : 'p-t-05'}>
                        {page.name}
                      </BdsTypo>
                    </BdsGrid>
                    <MaterialButton
                      text="Usar essa página"
                      variant="secondary"
                      isMobileView={isMobileView}
                      onClickCallback={() => handleUserPageClick(page)}
                    />
                  </BdsGrid>
                );
              })}
              <BdsGrid alignItems={isMobileView ? 'center' : undefined} direction="column" margin="t-2">
                <BdsButton variant="secondary" icon="info" onClick={handlePageNotListedClick}>
                  Não encontrei minha página
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
          </BdsGrid>
        ) : (
          <NoPagesFound />
        )}
      </BdsGrid>
      <InstagramFacebookPageNotListedModal ref={pageNotListedModal} />
    </>
  );
}

const NoPagesFound = () => {
  const isMobileView = useMobileView();
  const { track } = useTrack();
  const { logoutFacebook } = useGraphApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const createNewPageHandler = () => {
    track(CONNECT_INSTAGRAM_CREATE_FB_PAGE);
    window.open('https://www.facebook.com/pages/creation/', '_blank', 'noopener,noreferrer');
  };

  const changeAccountHandler = async () => {
    setLoading(true);
    await logoutFacebook();
    track(CONNECT_INSTAGRAM_PAGE_ERROR_BACK);
    setLoading(false);
    return navigate('/dashboard');
  };

  return (
    <>
      <BdsGrid direction="column" gap="3">
        <BdsTypo variant="fs-24" bold="bold" tag="h3">
          Não encontramos páginas do Facebook para essa conta.
        </BdsTypo>
        <BdsGrid gap="1">
          <BdsIcon name="blip-ideas" />
          <BdsTypo bold="bold" tag="p">
            Como resolver:
          </BdsTypo>
        </BdsGrid>
        <BdsTypo variant="fs-14" tag="p">
          <li>Caso sua empresa ainda não tenha uma página:</li>
        </BdsTypo>
        <MaterialButton
          text="Crie uma nova página no Facebook"
          variant="tertiary"
          startIconBdsName="facebook"
          isMobileView={isMobileView}
          onClickCallback={createNewPageHandler}
          cssVariant="w-50"
        />
        <BdsTypo variant="fs-14" tag="p">
          <li>Caso sua página seja administrada por outra conta de Facebook:</li>
        </BdsTypo>
        {loading ? (
          <BdsLoadingSpinner size="small" />
        ) : (
          <MaterialButton
            text="Troque de conta"
            onClickCallback={changeAccountHandler}
            variant="tertiary"
            startIconBdsName="arrow-left"
            isMobileView={isMobileView}
            cssVariant="w-50"
          />
        )}
      </BdsGrid>
    </>
  );
};

export default InstagramStepFacebookPages;
