import { BdsGrid, BdsInput, BdsLoadingPage } from 'blip-ds/dist/blip-ds-react/components';
import FaqChatBubble from 'components/FaqWizard/FaqChatBubble';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { MAX_FAQ_QUESTIONS } from 'libs/app';
import { FAQ_NEWFAQ_CANCEL, FAQ_NEWFAQ_CONTINUE, FAQ_NEWFAQ_NEXTQUESTIONS } from 'libs/trackingEvents';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Faq } from 'types/Faq';
import FaqInputContinueModal from './FaqInputContinueModal';

function FaqInputNew() {
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const { faqs, setFaqs } = useAppContext();
  const { patchFaq } = usePlgApi();
  const { track } = useTrack();
  const { createToastError } = useToast();
  const [isFaqValid, setIsFaqValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [answerPlaceHolder, setAnswerPlaceHolder] = useState('Digite aqui o que você geralmente responde');
  const inputQuestionRef = useRef<HTMLBdsInputElement>(null);
  const inputAnswerRef = useRef<HTMLBdsInputElement>(null);
  const faqInputContinueModalRef = useRef<HTMLBdsModalElement | null>(null);

  const trackStep = (
    questionID: string,
    question: string,
    openAnswer: string,
    status: string,
    error?: string | undefined,
  ) => {
    const payload = {
      typeQuestion: 'ADD_PELO_USER',
      questionName: undefined,
      booleanAnswer: undefined,
      questionID,
      question,
      openAnswer,
      questionSource: 'new',
      amountQuestions: faqs.length,
      status,
      error,
    };
    track(FAQ_NEWFAQ_NEXTQUESTIONS, payload);
  };

  const handleOnAddMoreClick = () => {
    faqInputContinueModalRef.current?.toggle();
  };

  const handleCancelClick = () => {
    track(FAQ_NEWFAQ_CANCEL, { typeQuestion: 'ADD_PELO_USER', questionName: 'NEW_FAQ' });
    navigate('/dashboard');
  };

  const handleNextClick = async () => {
    if (faqs.length >= MAX_FAQ_QUESTIONS) {
      createToastError(
        'Você atingiu o limite de assuntos',
        'Para adicionar mais assuntos, exclua algum assunto já existente',
      );
      return;
    }

    const question = inputQuestionRef.current?.value as string;
    const answer = inputAnswerRef.current?.value as string;
    const faq = {
      id: crypto.randomUUID(),
      question: question,
      answer: answer,
    } as Faq;
    await saveFaq(faq);
    resetForm();
    faqInputContinueModalRef.current?.toggle();
    track(FAQ_NEWFAQ_CONTINUE);
  };

  const saveFaq = async (faq: Faq) => {
    const faqsToPatch = faqs.filter(currentFaq => currentFaq.id !== faq.id);
    faqsToPatch.unshift(faq);

    try {
      setIsLoading(true);
      await patchFaq(faqsToPatch);
      setFaqs(faqsToPatch);
      trackStep(faq.id as string, faq.question, faq.answer, 'success');
    } catch (error) {
      const err = error as Error;
      createToastError('Erro ao salvar assunto', err.message);
      trackStep(faq.id as string, faq.question, faq.answer, 'error', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    inputQuestionRef?.current?.clear();
    inputAnswerRef?.current?.clear();
  };

  const validateInputs = async () => {
    const isValid = (await inputQuestionRef.current?.isValid()) && (await inputAnswerRef.current?.isValid());
    setIsFaqValid(isValid || false);
    const inputQuestion = inputQuestionRef.current as HTMLBdsInputElement;
    if (!inputQuestion.value) {
      setAnswerPlaceHolder('Digite aqui o que você geralmente responde');
      return;
    }
    setAnswerPlaceHolder(`Digite aqui o que você geralmente responde sobre "${inputQuestion.value}"`);
  };

  return (
    <>
      {isLoading && <BdsLoadingPage />}
      <FaqChatBubble contentHTML={'Qual assunto seus clientes sempre perguntam antes de comprarem com você?'} />
      <BdsGrid padding="l-1" xxs="12">
        <BdsInput
          id="answer-input"
          ref={inputQuestionRef}
          onBdsChange={validateInputs}
          isTextarea
          required
          counterLength
          rows={3}
          maxlength={300}
          placeholder="Descreva o assunto com poucas palavras."
          requiredErrorMessage="Este campo é obrigatório"
        />
      </BdsGrid>
      <FaqChatBubble contentHTML={'O que você responde quando perguntam sobre esse assunto?'} />
      <BdsGrid padding="l-1" xxs="12">
        <BdsInput
          id="answer-input"
          ref={inputAnswerRef}
          onBdsChange={validateInputs}
          isTextarea
          required
          counterLength
          rows={3}
          maxlength={300}
          placeholder={answerPlaceHolder}
          requiredErrorMessage="Este campo é obrigatório"
        />
      </BdsGrid>
      <BdsGrid xxs="12" margin="t-4" gap="2" direction="column-reverse">
        <MaterialButton
          onClickCallback={handleCancelClick}
          text="Cancelar"
          variant="tertiary"
          startIconBdsName="arrow-left"
          isMobileView={isMobileView}
        ></MaterialButton>
        <MaterialButton
          onClickCallback={handleNextClick}
          text="Confirmar e continuar"
          variant="primary"
          endIconBdsName="arrow-right"
          isMobileView={isMobileView}
          disabled={!isFaqValid}
        ></MaterialButton>
      </BdsGrid>
      <FaqInputContinueModal
        ref={faqInputContinueModalRef}
        amountQuestions={faqs.length}
        onAddMoreCallback={handleOnAddMoreClick}
      ></FaqInputContinueModal>
    </>
  );
}
export default FaqInputNew;
