export function detectDevice() {
  const userAgent = navigator.userAgent.toLowerCase();

  const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];

  for (const keyword of mobileKeywords) {
    if (userAgent.includes(keyword)) {
      return 'Mobile';
    }
  }

  return 'Desktop';
}

export function getScreenResolution() {
  const width = window.screen.width;
  const height = window.screen.height;

  return width + 'x' + height;
}

export function getOperatingSystem() {
  const userAgent = navigator.userAgent;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iphone|ipad|ipod/i.test(userAgent)) {
    return 'iOS';
  }

  if (/mac/i.test(userAgent)) {
    return 'Mac OS';
  }

  if (/windows/i.test(userAgent)) {
    return 'Windows';
  }

  if (/linux/i.test(userAgent)) {
    return 'Linux';
  }

  return 'Unknown';
}
