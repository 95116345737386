import { BdsButton, BdsGrid, BdsInput, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import MainContainerOnboarding from 'components/MainContainerOnboarding';
import { useAtom } from 'jotai';
import { signerInfo } from 'atoms/AppAtoms';
import useTrack from 'hooks/useTrack';
import { COEX_SETUP_NO_CNPJ, COEX_SETUP_START } from 'libs/trackingEvents';
import usePlgApi from 'hooks/usePlgApi';
import { useAuth } from 'oidc-react';
import { HubspotDeal } from 'types/Hubspot';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useToast from 'hooks/useToast';

dayjs.extend(utc);

function CompanyForm() {
  const navigate = useNavigate();
  const [companyForm, setCompanyForm] = useAtom(signerInfo);
  const { track } = useTrack();
  const {
    updateHubspotContact,
    updateHubspotDeal,
    getSubscriptionByEmail,
    updateSubscription,
    getHubspotContactByProperty,
  } = usePlgApi();
  const { createToastError } = useToast();
  const userEmail = useAuth().userData?.profile.email as string;

  const isFormValid = () => {
    let valid = true;

    if (!companyForm?.companyName) {
      valid = false;
    }

    if (!companyForm?.cnpj || !isCnpjValid(companyForm?.cnpj)) {
      valid = false;
    }

    if (!companyForm?.whatsapp || !/^\(\d{2}\)\s?\d{4,5}-?\d{4}$/.test(companyForm?.whatsapp)) {
      valid = false;
    }

    return valid;
  };

  const saveField = (field: keyof SignerInfo, value: string) => {
    setCompanyForm(prev => {
      if (!prev) {
        return { [field]: value } as SignerInfo;
      }
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const updateContact = async (phone: string) => {
    try {
      const contactResponse = await getHubspotContactByProperty('email', userEmail);
      updateHubspotContact(contactResponse.id, { properties: { phone } });
    } catch (error) {
      createToastError('Erro ao buscar informações do assistente', 'Por favor, tente novamente mais tarde');
    }
  };

  const handleSubmit = async () => {
    try {
      const subscriptionResponse = await getSubscriptionByEmail(userEmail);
      // TODO: Remove comments when the HubSpot integration goes live
      //await updateContact(companyForm?.whatsapp as string);
      const dealInfo: HubspotDeal = {
        name: companyForm?.companyName,
        customProperties: {
          cnpj: companyForm?.cnpj,
          empresa_contratada: 'Curupira',
          e_mail_para_envio_de_nota_fiscal: userEmail,
          e_mail_do_representante_legal: userEmail,
          contrato_associado: 'true',
          status_do_contrato: 'Ativo',
          data_da_primeira_mensalidade: dayjs().utc().add(1, 'month').startOf('month').toISOString(),
        },
      };
      //await updateHubspotDeal(subscriptionResponse.dealId as string, dealInfo);
      await updateSubscription(subscriptionResponse.subscriptionId as string, {
        cnpj: companyForm?.cnpj,
        companyName: companyForm?.companyName,
      });
    } catch (error) {
      createToastError('Erro ao salvar informações do assistente', 'Por favor, tente novamente mais tarde');
    }
    track(COEX_SETUP_START);
    navigate('/onboarding/terms');
  };

  const handleCNPJInput = (input: string): string => {
    const digits = input.replace(/\D/g, '');
    const formatted = digits
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,4})/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2');

    return formatted;
  };

  const handlePhoneInput = (input: string): string => {
    const digits = input.replace(/\D/g, '');
    const formatted = digits.replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d{4,5})(\d{4})/, '$1-$2');

    return formatted;
  };

  const isCnpjValid = (cnpj: string) => {
    cnpj = cnpj.replace(/[^\d]/g, '');

    if (!cnpj || cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    const calculateDigit = (base: string): number => {
      let sum = 0;
      let position = base.length - 7;

      for (const char of base) {
        sum += parseInt(char) * position--;
        if (position < 2) position = 9;
      }

      return sum % 11 < 2 ? 0 : 11 - (sum % 11);
    };

    const base = cnpj.slice(0, 12);
    const digits = cnpj.slice(12);

    const firstDigit = calculateDigit(base);
    const secondDigit = calculateDigit(base + firstDigit);

    return firstDigit === parseInt(digits[0]) && secondDigit === parseInt(digits[1]);
  };

  const handleMissingCnpj = () => {
    track(COEX_SETUP_NO_CNPJ);
    navigate('/onboarding/cnpj-required');
  };

  return (
    <MainContainerOnboarding
      subtitle="Preencha os dados essenciais iniciar a configuração do Blip Coex."
      title="Nos conte sobre seu negócio"
    >
      <BdsGrid direction="column" gap="1">
        <BdsTypo variant="fs-14" bold="bold">
          Razão social
        </BdsTypo>
        <BdsInput
          data-testid="company-name-input"
          value={companyForm?.companyName}
          onBdsChange={e => saveField('companyName', e?.target?.value ?? '')}
          className="company-form-input"
          icon="company"
          placeholder="Razão social da empresa"
        ></BdsInput>
      </BdsGrid>
      <BdsGrid direction="column" gap="1">
        <BdsTypo variant="fs-14" bold="bold">
          CNPJ da empresa
        </BdsTypo>
        <BdsInput
          data-testid="cnpj-input"
          value={companyForm?.cnpj}
          onBdsChange={e => saveField('cnpj', handleCNPJInput(e.target.value ?? ''))}
          className="company-form-input"
          icon="business"
          placeholder="00.000.000/0000-00"
        ></BdsInput>
      </BdsGrid>
      <BdsGrid direction="column" gap="1">
        <BdsGrid direction="column">
          <BdsTypo variant="fs-14" bold="bold">
            WhatsApp Business
          </BdsTypo>
          <BdsTypo variant="fs-12" className="company-form-subtitle">
            Informe o WhatsApp que futuramente será conectado ao Blip
          </BdsTypo>
        </BdsGrid>
        <BdsInput
          data-testid="whatsapp-input"
          value={companyForm?.whatsapp}
          onBdsChange={e => saveField('whatsapp', handlePhoneInput(e.target.value ?? ''))}
          className="company-form-input"
          icon="whatsapp"
          placeholder="(99) 99999-9999"
        ></BdsInput>
      </BdsGrid>
      <BdsGrid justifyContent="space-between">
        <BdsButton variant="text" color="content" onBdsClick={handleMissingCnpj}>
          Não tenho CNPJ
        </BdsButton>
        <BdsButton disabled={!isFormValid()} arrow={true} onBdsClick={handleSubmit}>
          Continuar
        </BdsButton>
      </BdsGrid>
    </MainContainerOnboarding>
  );
}

export default CompanyForm;
