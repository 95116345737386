import { BdsStep, BdsStepper } from 'blip-ds/dist/blip-ds-react/components';
import React, { useEffect } from 'react';

type InstagramHeaderProps = {
  currentStep: number;
  stepsCount: number;
};

function InstagramHeader({ currentStep, stepsCount }: InstagramHeaderProps) {
  useEffect(() => {
    const setStepActive = (step: number) => {
      const stepperComponent = document.querySelector('bds-stepper') as HTMLBdsStepperElement;
      if (step > 1) {
        stepperComponent.setCompletedStep(0);
        stepperComponent.setActiveStep(1);
      } else {
        stepperComponent.setActiveStep(0);
      }
    };
    setStepActive(currentStep);
  }, [currentStep, stepsCount]);

  return (
    <BdsStepper>
      <BdsStep active={true}>Selecionar página</BdsStep>
      <BdsStep last={true}>Conectar Instagram</BdsStep>
    </BdsStepper>
  );
}

export default InstagramHeader;
