import { BdsAvatar, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import { formatDate, formatPhoneNumberBrazil } from 'utils/formaters';

interface ContactInfoProps {
  contactName: string;
  contactPhone: string;
  lastUpdateDate: string | Date;
}

function ContactInfo({ contactName, contactPhone, lastUpdateDate }: Readonly<ContactInfoProps>) {
  const avatarProps = contactName && { name: contactName };

  return (
    <BdsGrid gap="2" justifyContent="space-between" alignItems="center" className="contact-drawer-container">
      <BdsGrid gap="2" alignItems="center">
        <BdsAvatar size="small" {...avatarProps} />
        <BdsGrid direction="column">
          <BdsTypo variant="fs-16" bold="bold">
            {contactName || contactPhone}
          </BdsTypo>
          <BdsTypo variant="fs-14">{formatPhoneNumberBrazil(contactPhone, true)}</BdsTypo>
        </BdsGrid>
      </BdsGrid>

      <BdsTypo variant="fs-14">{formatDate(lastUpdateDate)}</BdsTypo>
    </BdsGrid>
  );
}

export default ContactInfo;
