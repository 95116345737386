import { BdsChipTag, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { pluralSuffix } from 'utils/formaters';

function FaqCard() {
  const { faqs } = useAppContext();
  const navigate = useNavigate();
  const hasSubmittedFaq = faqs.length > 0;
  let tagText = 'sem assuntos cadastrados';
  if (hasSubmittedFaq) {
    const plural = pluralSuffix(faqs.length);
    tagText = `${faqs.length} assunto${plural} cadastrado${plural}`;
  }

  const navigateToFaqRoute = () => {
    if (faqs.length > 0) {
      navigate('/faq');
    } else {
      navigate('/faq-wizard');
    }
  };

  return (
    <BdsPaper className="paper-pattern" elevation="none">
      <BdsGrid direction="column" padding="2" gap="1">
        <BdsGrid gap="2" margin="none" padding="1">
          <div className="rounded-square icon-background-faq ">
            <BdsIcon name="faq" size="xxx-large" />
          </div>
          <BdsGrid direction="column" gap="1">
            <BdsTypo variant="fs-20" tag="h4" bold="bold" margin={false}>
              Central de assuntos
            </BdsTypo>
            <BdsChipTag icon="checkball" color="outline">
              {tagText}
            </BdsChipTag>
          </BdsGrid>
        </BdsGrid>
        <BdsTypo variant="fs-14" tag="p">
          Confira e altere os assuntos que vou responder no seu inbox.
        </BdsTypo>
        <BdsGrid margin="t-1">
          <MaterialButton onClickCallback={navigateToFaqRoute} text="Ver assuntos" variant="secondary" isMobileView />
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
}

export default FaqCard;
