import FaqContainer from 'components/FAQ/FaqContainer';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import './style.scss';

function Faq() {
  const isMobileView = useMobileView();

  return (
    <>
      <div className="faq-list-container">
        {!isMobileView && <Sidebar />}
        <FaqContainer />
      </div>
    </>
  );
}

export default Faq;
