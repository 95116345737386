// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facebookIconContainer {
  display: flex;
  align-self: flex-start;
}

.facebookIcon {
  width: 5rem !important;
  height: 5rem !important;
  min-height: 5rem !important;
  max-height: 5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramSwitchFacebookProfile/style.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,2BAAA;AADF","sourcesContent":["@use '/src/styles/colors';\n\n.facebookIconContainer {\n  display: flex;\n  align-self: flex-start;\n}\n\n.facebookIcon {\n  width: 5rem !important;\n  height: 5rem !important;\n  min-height: 5rem !important;\n  max-height: 5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
