import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import useTrack from 'hooks/useTrack';
import { COEX_CAMPAIGN_BACK_BUTTON } from 'libs/trackingEvents';
import React from 'react';

type ActiveMessagesCancelAlertProps = {
  confirmCallback: () => void;
};

function ActiveMessagesCancelAlert({ confirmCallback }: Readonly<ActiveMessagesCancelAlertProps>) {
  const { track } = useTrack();

  const handleBackButton = () => {
    track(COEX_CAMPAIGN_BACK_BUTTON, { stage: 'modalCancel' });
    const alert = document.getElementById('cancel-alert') as HTMLBdsAlertElement;
    alert?.toggle();
  };

  return (
    <BdsAlert data-testid="cancel-alert" id="cancel-alert">
      <BdsAlertHeader variant="warning" icon="warning">
        Deseja cancelar o envio?
      </BdsAlertHeader>
      <BdsAlertBody>
        <BdsTypo variant="fs-14">
          Ao <b>confirmar</b>, você perderá todo o progresso e precisará preencher novamente as informações.
        </BdsTypo>
      </BdsAlertBody>
      <BdsAlertActions>
        <BdsButton variant="secondary" onBdsClick={handleBackButton}>
          Voltar
        </BdsButton>
        <BdsButton variant="secondary" onBdsClick={confirmCallback}>
          Confirmar
        </BdsButton>
      </BdsAlertActions>
    </BdsAlert>
  );
}

export default ActiveMessagesCancelAlert;
