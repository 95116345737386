import { BdsGrid, BdsIcon, BdsIllustration, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { ReactNode } from 'react';
import styles from './WhatsappConnectionMobile.module.scss';
import { useMobileView } from 'contexts/MobileViewContext';
import { Divider } from '@mui/material';
import useToast from 'hooks/useToast';
import MaterialButton from 'components/UI/MaterialButton';
import { COEX_HELP_CLICKED } from 'libs/trackingEvents';
import useTrack from 'hooks/useTrack';

function WhatsappConnectionMobile() {
  const isMobileView = useMobileView();
  const { createToastSuccess } = useToast();
  const { track } = useTrack();

  const handleCopyUrl = () => {
    navigator.clipboard.writeText('https://assist.cs.blip.ai').then(() => {
      createToastSuccess('Link copiado para a área de transferência!');
    });
  };

  const handleLinkClick = () => {
    track(COEX_HELP_CLICKED, { screenName: location.pathname });
    const coexLink = `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_COEX_SUPPORT_PHONE}&text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20o%20Blip%20Go!%20COEX%20Beta`;
    window.open(coexLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <BdsGrid direction="column" gap="2">
      <Divider />
      <BdsGrid alignItems="center" justifyContent="space-between" padding="x-6">
        <BdsIllustration type="default" name="user-1"></BdsIllustration>
      </BdsGrid>
      <BdsGrid direction="column" gap="2">
        <BdsTypo variant="fs-16" bold="bold">
          Conecte usando um computador
        </BdsTypo>
        <BdsTypo variant="fs-14">
          Para conectar, é necessário um computador para abrir uma sessão do Facebook. Você deverá utilizar o celular
          para escanear um QR Code que aparecerá na tela.
        </BdsTypo>
        <BdsTypo variant="fs-14" bold="semi-bold">
          Abra o navegador no computador e insira o link abaixo para continuar o processo:
        </BdsTypo>
      </BdsGrid>
      <BdsPaper elevation="none" className={styles['paper-copy-link']}>
        <BdsGrid justifyContent="space-between">
          <BdsTypo variant="fs-14" bold="bold">
            https://assist.cs.blip.ai
          </BdsTypo>
          <BdsIcon name="copy" onClick={handleCopyUrl} data-testid="copy-icon" />
        </BdsGrid>
      </BdsPaper>
      <BdsTypo variant="fs-14" className={styles['desktop-need']}>
        O uso do computador é necessário apenas nesta etapa inicial. Depois, você poderá usar normalmente também pelo
        celular.
      </BdsTypo>
      <MaterialButton
        onClickCallback={handleLinkClick}
        text="Preciso de ajuda"
        startIconBdsName="agent"
        variant="secondary"
        cssVariant="bold-border"
      />
      <MaterialButton
        onClickCallback={handleLinkClick}
        text="Já finalizei a conexão"
        startIconBdsName="check"
        variant="no-border"
      />
    </BdsGrid>
  );
}

export default WhatsappConnectionMobile;
