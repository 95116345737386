import { BdsButton, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import WhatsappConnectionContainer from 'components/WhatsappConnectionContainer';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import './styles.scss';
import WhatsappBusinessIllustration from 'assets/images/whatsapp-business-illustration.svg';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_BUSINESS_ACTIVE, COEX_CANCEL_CONNECTION } from 'libs/trackingEvents';

function WhatsappNumberCheck() {
  const isMobileView = useMobileView();
  const { userData } = useAuth();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleNextClick = () => {
    track(COEX_BUSINESS_ACTIVE);
    navigate('/whatsapp/business-check');
  };

  const handleOnClose = () => {
    track(COEX_CANCEL_CONNECTION);
    navigate('/');
  };

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <BdsGrid justifyContent="center">
        <BdsGrid direction="column" class="main-container" gap="3">
          <MainHeader
            title={`Boas vindas, ${userName}`}
            description="Gerencie o seu atendimento e campanhas com o Blip Go Coex!"
          />
          <WhatsappConnectionContainer onClose={handleOnClose}>
            <BdsGrid class="number-check-container" gap="7">
              <BdsGrid direction="column" gap="3">
                <BdsGrid direction="column" gap="1">
                  <BdsTypo variant="fs-20" bold="bold">
                    Para começar, confirme que seu número está pronto
                  </BdsTypo>
                  <BdsTypo>
                    Seu número deve estar ativo e recebendo mensagens pelo aplicativo do WhatsApp <b>Business</b>,
                    lembre-se que não é o mesmo aplicativo do WhatsApp convencional.
                  </BdsTypo>
                </BdsGrid>
                <BdsGrid gap="1">
                  <BdsIcon name="info" class="info-text" />
                  <BdsTypo variant="fs-14" class="info-text">
                    <b>Não possui o aplicativo do WhatsApp Business? </b>
                    <a href="https://business.whatsapp.com/products/business-app" target="_blank" className="info-text">
                      Faça download
                    </a>{' '}
                    em um celular e adicione seu número.
                  </BdsTypo>
                </BdsGrid>
                <BdsGrid padding="b-3">
                  <BdsButton arrow={true} onBdsClick={handleNextClick}>
                    Meu número está ativo no Business
                  </BdsButton>
                </BdsGrid>
              </BdsGrid>
              {!isMobileView && <img src={WhatsappBusinessIllustration} alt="whatsapp business" />}
            </BdsGrid>
          </WhatsappConnectionContainer>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default WhatsappNumberCheck;
