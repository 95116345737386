import { BdsGrid } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import './style.scss';
import PrivateReplyTest from '../PrivateReplyTest';

function PrivateReplyContainer() {
  const isMobileView = useMobileView();

  return (
    <BdsGrid class={isMobileView ? 'p-size' : undefined} justifyContent={isMobileView ? 'center' : undefined}>
      <BdsGrid justifyContent="center" gap="3" padding={!isMobileView ? '5' : '2'}>
        <PrivateReplyTest />
      </BdsGrid>
    </BdsGrid>
  );
}
export default PrivateReplyContainer;
