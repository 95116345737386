import {
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsPaper,
  BdsRadio,
  BdsRadioGroup,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import AudienceFileUpload from 'components/AudienceFileUpload';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AudienceAndVariables, Template } from 'types/ActiveMessage';
import './style.scss';
import CampaignGroupsSelector from 'components/CampaignGroupsSelector';
import {
  COEX_CAMPAIGN_BACK_BUTTON,
  COEX_CAMPAIGN_NEXT_BUTTON,
  COEX_SELECT_AUDIENCE_SOURCE,
  COEX_SELECT_GROUP_AUDIENCE,
} from 'libs/trackingEvents';
import useTrack from 'hooks/useTrack';
import { Divider } from '@mui/material';
import { useMobileView } from 'contexts/MobileViewContext';
import { AudienceSource } from 'types/Campaigns';

function MessageConfiguration() {
  const [audienceSource, setAudienceSource] = useState<AudienceSource>();
  const [audience, setAudience] = useState<AudienceAndVariables[]>([]);
  const [audienceGroup, setAudienceGroup] = useState('');
  const locationState = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useTrack();
  const selectedTemplate: Template = locationState.state.template;
  const hasVariables = !!selectedTemplate.body.variablesExamples.length;
  const isMobileView = useMobileView();

  useEffect(() => {
    const defaultAudience = hasVariables ? AudienceSource.Custom : AudienceSource.Group;
    setAudienceSource(defaultAudience);
  }, []);

  const htmlParsedString = selectedTemplate.body.text
    .replace(/\\n+/g, match => '<br>'.repeat(match.length / 2))
    .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
    .replace(/\{\{(\d+)\}\}/g, (_, number) => `<strong>{{${number}}}</strong>`)
    .replace(/_(.*?)_/g, '<em>$1</em>')
    .replace(/~(.*?)~/g, '<del>$1</del>');

  const handleBackButton = () => {
    track(COEX_CAMPAIGN_BACK_BUTTON, { stage: 'preview ' });
    navigate('../template');
  };

  const handleContinueButton = () => {
    track(COEX_CAMPAIGN_NEXT_BUTTON, { stage: 'preview' });
    navigate('../confirm', { state: { ...location.state, audience, audienceGroup, audienceSource } });
  };

  const handleGroupSelection = (audience: AudienceAndVariables[], audienceGroup: string) => {
    setAudience(audience);
    setAudienceGroup(audienceGroup);
    track(COEX_SELECT_GROUP_AUDIENCE, { contactGroup: audienceGroup });
  };

  const handleAudienceSourceChange = (ev: CustomEvent) => {
    setAudienceSource(ev.detail.value);
    track(COEX_SELECT_AUDIENCE_SOURCE, { audienceType: ev.detail.value });
  };

  return (
    <BdsPaper style={{ backgroundColor: 'white' }} className="fade-in">
      <BdsGrid direction="column" padding="4" gap="2">
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Pré-visualização da mensagem
        </BdsTypo>
        <BdsGrid direction={isMobileView ? 'column' : 'row'} gap="4">
          <BdsGrid className="template-text-container">
            <BdsTypo className="template-text">{parse(htmlParsedString)}</BdsTypo>
          </BdsGrid>
          {hasVariables && (
            <BdsGrid direction="column" gap="2" className="grid-example-container">
              <BdsTypo bold="bold">Entenda as variáveis de texto</BdsTypo>
              <BdsTypo>
                Os espaços reservados com <b>{'{{Número}}'}</b> no texto serão preenchidos com os dados fornecidos no
                arquivo excel xlsx. Como por exemplo:
              </BdsTypo>
              <div className="grid-example">
                <BdsTypo variant="fs-12">telefone</BdsTypo>
                <BdsTypo variant="fs-12">{'{{1}}'}</BdsTypo>
                <BdsTypo variant="fs-12">{'{{2}}'}</BdsTypo>
                <BdsTypo variant="fs-12">+554198...</BdsTypo>
                <BdsTypo variant="fs-12">Nome Pessoa</BdsTypo>
                <BdsTypo variant="fs-12">Nome Produto</BdsTypo>
              </div>
              <BdsGrid alignItems="center" gap="1">
                <BdsIcon size="x-small" name="info" />
                <BdsTypo variant="fs-12">Todas as variáveis devem ser preenchidas.</BdsTypo>
              </BdsGrid>
            </BdsGrid>
          )}
        </BdsGrid>
        <BdsGrid direction="column" padding="y-2">
          <Divider />
        </BdsGrid>
        <BdsGrid direction="column" gap="3">
          <BdsGrid direction="column" gap="1">
            <BdsTypo variant="fs-20" tag="h4" bold="bold" margin={false}>
              Audiência
            </BdsTypo>
            <BdsTypo variant="fs-14" tag="p">
              Defina quem receberá o disparo em massa, você pode utilizar um grupo de contatos ou fazer upload de uma
              planilha com dados variáveis.
            </BdsTypo>
          </BdsGrid>
          <BdsRadioGroup value={audienceSource} onBdsRadioGroupChange={handleAudienceSourceChange}>
            <BdsGrid gap="5">
              <BdsTooltip
                position="top-left"
                disabled={!hasVariables}
                tooltipText="O envio para grupos de contatos está disponível apenas para modelos de mensagem sem variáveis."
              >
                <BdsGrid gap="1">
                  <BdsRadio value={AudienceSource.Group} label="Grupo de contatos" disabled={hasVariables} />
                  {hasVariables && <BdsIcon name="info" />}
                </BdsGrid>
              </BdsTooltip>

              <BdsRadio value={AudienceSource.Custom} label="Planilha personalizada" />
            </BdsGrid>
          </BdsRadioGroup>
          {audienceSource === AudienceSource.Custom ? (
            <AudienceFileUpload onAudienceUpload={setAudience} />
          ) : (
            <CampaignGroupsSelector onGroupSelection={handleGroupSelection} />
          )}
          <BdsGrid gap="2" padding="t-1">
            <BdsButton variant="ghost" onBdsClick={handleBackButton}>
              Voltar
            </BdsButton>
            <BdsButton disabled={audience.length === 0} onBdsClick={handleContinueButton}>
              Continuar
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
}

export default MessageConfiguration;
