// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select {
  max-width: 22rem;
}

.p-size {
  height: 59.1rem;
  width: 23.4rem;
}

.tooltip {
  z-index: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/components/FacebookCatalog/FacebookCatalogSteps/FacebookCatalogConnection/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".select {\n  max-width: 22rem;\n}\n\n.p-size {\n  height: 59.1rem;\n  width: 23.4rem;\n}\n\n.tooltip {\n  z-index: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
