import { BdsButtonIcon, BdsGrid, BdsTooltip } from 'blip-ds/dist/blip-ds-react';
import useTrack from 'hooks/useTrack';
import { COEX_SIDEBAR_ITEM } from 'libs/trackingEvents';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ISidebarItemProps {
  icon: string;
  route: string;
  tooltip: string;
  moduleName: string;
}

function SidebarItem({ icon, route, tooltip, moduleName }: Readonly<ISidebarItemProps>) {
  const { track } = useTrack();
  const navigate = useNavigate();
  const location = useLocation();
  const isCurrentlyActive = location.pathname.includes(route);

  const handleNavigateToSidebarItem = () => {
    if (!isCurrentlyActive) {
      track(COEX_SIDEBAR_ITEM, { moduleName });
      navigate(route);
    }
  };

  return (
    <BdsGrid alignItems="center" justifyContent="center" height="3rem">
      <BdsTooltip position="right-center" tooltip-text={tooltip}>
        <BdsButtonIcon
          variant={isCurrentlyActive ? 'primary' : 'secondary'}
          style={{ cursor: 'pointer' }}
          onBdsClick={handleNavigateToSidebarItem}
          icon={icon}
        />
      </BdsTooltip>
    </BdsGrid>
  );
}

export default SidebarItem;
