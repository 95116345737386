import { BdsGrid, BdsInputPhoneNumber, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import React, { useRef } from 'react';

type StepProps = {
  number: number;
  title: string;
  description: string;
  buttonLabel: string;
  onClickCallback: (number?: string | null) => void;
  buttonArrow: boolean;
  startIconBdsName?: string;
};

function Step({
  number,
  title,
  description,
  buttonLabel,
  onClickCallback,
  buttonArrow,
  startIconBdsName,
}: Readonly<StepProps>) {
  const numberInputRef = useRef<HTMLBdsInputPhoneNumberElement>(null);
  const { botConfiguration } = useAppContext();

  return (
    <BdsPaper elevation="none" class="paper-pattern">
      <BdsGrid direction="column" class="dashboard-card">
        <BdsTypo variant="fs-14">Passo {number}:</BdsTypo>
        <BdsTypo bold="bold">{title}</BdsTypo>
        <br />
        <BdsTypo variant="fs-14">
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </BdsTypo>
        <br />
        {number === 3 && (
          <>
            <BdsInputPhoneNumber
              ref={numberInputRef}
              text={botConfiguration.whatsApp.split('55')[1]}
              required
              label="WhatsApp da Loja"
            />
            <br />
          </>
        )}
        <MaterialButton
          text={buttonLabel}
          onClickCallback={() => onClickCallback(numberInputRef.current?.text)}
          variant="primary"
          endIconBdsName={buttonArrow ? 'arrow-right' : undefined}
          startIconBdsName={startIconBdsName}
        />
      </BdsGrid>
    </BdsPaper>
  );
}

export default Step;
