export enum InstagramSteps {
  ErrorPage,
  FacebookPages,
  InstagramActivation,
  InstagramPageNotLinked,
  InstagramSwitchFacebookProfile,
  InstagramAllowMessagesAccess,
}

export enum InstagramActivationErrors {
  Unknown,
  InvalidInstagramAccount,
  NoBindedPagesFound,
  NoMessagesAccess,
}
