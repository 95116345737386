import dayjs from 'dayjs';

export function formatPhoneNumberBrazil(phoneNumber: string, removeDDI?: boolean) {
  if (!phoneNumber) {
    return 'Número inválido';
  }
  const ddi = removeDDI ? '' : '+55 ';
  // Remove all non-digit characters from the input
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  // Remove the "55" prefix
  const numberWithoutPrefix = cleanedNumber.slice(2);

  // Check the length of the remaining number
  const length = numberWithoutPrefix.length;

  if (length === 11) {
    // Format as +55 (XX) XXXXX-XXXX
    return `${ddi}(${numberWithoutPrefix.slice(0, 2)}) ${numberWithoutPrefix.slice(2, 7)}-${numberWithoutPrefix.slice(
      7,
    )}`;
  } else {
    // Format as +55 (XX) XXXX-XXXX (Assuming it's always at least 10 digits)
    return `${ddi}(${numberWithoutPrefix.slice(0, 2)}) ${numberWithoutPrefix.slice(2, 6)}-${numberWithoutPrefix.slice(
      6,
    )}`;
  }
}

export function formatDate(lastMessageDate: Date | string | undefined) {
  const date = dayjs(lastMessageDate);
  if (!date.isValid()) return 'Data inválida';

  const time = date.format('HH:mm');
  if (date.isToday()) return `Hoje, ${time}`;
  if (date.isYesterday()) return `Ontem, ${time}`;

  return date.format(`DD [de] MMM, ${time}`);
}

export function pluralSuffix(quantity: number) {
  return quantity > 1 ? 's' : '';
}
