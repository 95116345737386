import { HubspotDeal } from 'types/Hubspot';

export enum ContactStatus {
  Lead = 'Lead',
  Installed = 'Blip Assist instalado',
  InstagramConnected = 'Blip Assist conectado',
  Active = 'Bot ativo',
  Paused = 'Bot pausado',
}
export const HUBSPOT_ASSIST_PARAMETER = 'blip_assist_status_do_contato';

export const HubspotContactAssociation = {
  associationTypeId: 3,
  toObjectType: 'HUBSPOT_DEFINED',
};

export const HubspotLineItemAssociation = {
  associationTypeId: 19,
  toObjectType: 'HUBSPOT_DEFINED',
};

export enum WhatsappConnectionStatus {
  Available = 'Disponível para Conexão',
  Connected = 'Whatsapp Conectado',
  Rejected = 'WABA Reprovado',
}

export const HUBSPOT_CUSTOM_PARAMS_INSTALL = {
  customProperties: {
    status_de_ativacao__blip_go_plg_: WhatsappConnectionStatus.Available,
    wa__numero_pronto_para_ativar_: 'true',
    wa__site_pronto_para_ativar_: 'true',
    wa__status_do_numero: 'Ativo em WhatsApp Business',
    contrato_no_blip: process.env.REACT_APP_PACK_TENANT,
  },
};

export const HUBSPOT_CUSTOM_PARAMS_TERMS_AGREEMENT = {
  customProperties: {
    tipo_de_contrato: 'Blip Go! Coex',
    regra_de_cobranca: 'Blip Go! Coex',
    packs__categoria: 'Blip Go! Coex',
    dealtype: '[LAND] Novo contrato',
    wa__ativacao_em_cloud_api_: 'true',
    ativacao_sera_com_numero_novo_ou_migracao_: 'Irá migrar número com WABA já ativada',
    whatsapp_contratado: 'WhatsApp',
  },
};

export const HUBSPOT_DEAL_SEARCH_PARAMS = {
  defaultOperator: 'EQ',
  contactAssociation: 'associations.contact',
  pipeline: 'pipeline',
};

export const HUBSPOT_DEAL_SEARCH_PIPELINE = {
  propertyName: 'pipeline',
  operator: HUBSPOT_DEAL_SEARCH_PARAMS.defaultOperator,
  value: process.env.REACT_APP_HUBSPOT_COEX_BOARD_ID as string,
};

export const HUBSPOT_COEX_DEAL_DEFAULT_PARAMS: HubspotDeal = {
  ownerId: process.env.REACT_APP_HUBSPOT_COEX_OWNER_ID,
  currencyId: 'BRL',
  comercialConditionId: '20% Ativação (Startup)',
  originId: '[Blip Go] Base Blip Go',
  countryId: 'Brasil',
  boardId: Number(process.env.REACT_APP_HUBSPOT_COEX_BOARD_ID),
  columnId: Number(process.env.REACT_APP_HUBSPOT_COEX_COLUMN_ID),
  customProperties: {},
};

export const HUBSPOT_COEX_ITEM_LINE_ASSOCIATION = {
  to: { id: Number(process.env.REACT_APP_HUBSPOT_COEX_PRODUCT_ID) },
  types: [
    {
      associationTypeId: HubspotLineItemAssociation.associationTypeId,
      associationCategory: HubspotLineItemAssociation.toObjectType,
    },
  ],
};

export const HUBSPOT_COEX_CONTACT_ID_ASSOCIATION = (contactId: number) => {
  return {
    to: { id: contactId },
    types: [
      {
        associationTypeId: HubspotContactAssociation.associationTypeId,
        associationCategory: HubspotContactAssociation.toObjectType,
      },
    ],
  };
};
