// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-group-selector {
  max-width: 18.75rem;
  width: 100%;
  z-index: 9;
}`, "",{"version":3,"sources":["webpack://./src/components/CampaignGroupsSelector/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,WAAA;EACA,UAAA;AACF","sourcesContent":[".campaign-group-selector {\n  max-width: 18.75rem;\n  width: 100%;\n  z-index: 9;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
