import { BdsAvatar } from 'blip-ds/dist/blip-ds-react/components';
import useTrack from 'hooks/useTrack';
import { useAuth } from 'oidc-react';
import React, { useState } from 'react';
import './style.scss';
import UserCard from 'components/UserCard';
import { Box, ClickAwayListener, Fade, Popper } from '@mui/material';
import { USER_LOGO } from 'libs/trackingEvents';

function NameInitials() {
  const userName = useAuth().userData?.profile.FullName as string;
  const { track } = useTrack();
  const [anchorEl, setAnchorEl] = useState<HTMLBdsAvatarElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLBdsAvatarElement>) => {
    track(USER_LOGO);
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };

  const clickAwayHandler = () => setOpen(false);

  return (
    <>
      <BdsAvatar name={userName} onClick={ev => handleClick(ev)} class="cursor-pointer" size="small" />
      <Popper sx={{ zIndex: 1200 }} open={open} anchorEl={anchorEl} placement="right-end" transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={clickAwayHandler}>
            <Fade {...TransitionProps} timeout={350}>
              <Box className="user-card-popup">
                <UserCard backgroundColor="white" />
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

export default NameInitials;
