// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paper-question {
  border-radius: 0.875rem;
  box-shadow: none;
  max-width: 35.125rem;
}

.grid-survey-container {
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Survey/style.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,gBAAA;EACA,oBAAA;AADF;;AAGA;EACE,mBAAA;AAAF","sourcesContent":["@use '/src/styles/colors';\n\n.paper-question {\n  border-radius: 0.875rem;\n  box-shadow: none;\n  max-width: 35.125rem;\n}\n.grid-survey-container {\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
