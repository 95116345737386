import { Divider } from '@mui/material';
import { BdsAvatar, BdsChipTag, BdsGrid, BdsIllustration, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import useTrack from 'hooks/useTrack';
import { FacebookCatalogSteps } from 'libs/facebookCatalogSteps';
import {
  CONNECT_CATALOG_CHANGE_ACCOUNT,
  CONNECT_CATALOG_ERROR,
  CONNECT_CATALOG_ERROR_TRY_AGAIN,
} from 'libs/trackingEvents';
import React, { useEffect } from 'react';
import './style.scss';

function FacebookCatalogNoOrganizationFound({
  setCurrentStep,
}: {
  setCurrentStep: (step: FacebookCatalogSteps) => void;
}) {
  const { getFacebookConnectedData } = useGraphApi();
  const fbConnectedData = getFacebookConnectedData();
  const isMobileView = useMobileView();
  const { track } = useTrack();
  const errorScreen = 'nenhuma organização encontrada';

  const handleChangeFbAccount = () => {
    track(CONNECT_CATALOG_CHANGE_ACCOUNT, { errorScreen: errorScreen });
    setCurrentStep(FacebookCatalogSteps.FacebookCatalogSwitchProfile);
  };

  const handleFBCatalogConnection = () => {
    track(CONNECT_CATALOG_ERROR_TRY_AGAIN, { errorScreen: errorScreen });
    setCurrentStep(FacebookCatalogSteps.FacebookCatalogConnection);
  };

  useEffect(() => {
    track(CONNECT_CATALOG_ERROR, { errorScreen: errorScreen });
  }, [fbConnectedData?.accessToken]);

  return (
    <BdsGrid
      justifyContent={isMobileView ? undefined : 'center'}
      gap={isMobileView ? undefined : '8'}
      direction={isMobileView ? 'column' : 'row'}
      padding="2"
    >
      <BdsGrid>
        {isMobileView ? (
          <BdsGrid alignItems="center" gap="1" margin="b-2">
            <BdsGrid direction="row">
              <BdsAvatar id="avatar" color="success" name={fbConnectedData?.name} size="small"></BdsAvatar>
            </BdsGrid>
            <BdsTypo variant="fs-12" bold="semi-bold">
              {fbConnectedData?.name}
            </BdsTypo>
          </BdsGrid>
        ) : (
          <BdsGrid direction="column" gap="1" justifyContent="center" alignItems="center">
            <BdsAvatar id="avatar" color="success" name={fbConnectedData?.name} size="extra-large"></BdsAvatar>
            <BdsTypo variant="fs-16" bold="semi-bold">
              {fbConnectedData?.name}
            </BdsTypo>
            <BdsChipTag color="success">Conectado</BdsChipTag>
          </BdsGrid>
        )}
      </BdsGrid>
      <Divider orientation={isMobileView ? 'horizontal' : 'vertical'} variant="middle" flexItem />
      <BdsGrid class={isMobileView ? 'mobile-content' : 'desktop-content'} direction="column">
        <BdsGrid gap="1">
          <BdsGrid class="illustation">
            <BdsIllustration name="letter-cancel-error" type="spots" />
          </BdsGrid>
          <BdsGrid class={isMobileView ? 'mobile-title' : undefined}>
            <BdsTypo variant="fs-16" bold="bold">
              Não econtramos nenhuma organização para esta conta.
            </BdsTypo>
          </BdsGrid>
        </BdsGrid>
        <BdsGrid direction="column" gap="4">
          <BdsGrid>
            <BdsTypo variant="fs-14" bold="regular">
              Verifique as permissões do seu usuário no Facebook ou faça login com outro usuário.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid
            gap={isMobileView ? '2' : '3'}
            direction={isMobileView ? 'column' : undefined}
            justifyContent={isMobileView ? undefined : 'flex-end'}
          >
            <MaterialButton
              onClickCallback={handleChangeFbAccount}
              text="Trocar de conta"
              variant="secondary"
              isMobileView={isMobileView}
            />
            <MaterialButton
              onClickCallback={handleFBCatalogConnection}
              text="Tentar novamente"
              variant="primary"
              isMobileView={isMobileView}
            />
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
}
export default FacebookCatalogNoOrganizationFound;
