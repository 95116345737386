import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import useTrack from 'hooks/useTrack';
import { COEX_SIDEBAR_ITEM } from 'libs/trackingEvents';

interface IMobileDrawerItemProps {
  icon: string;
  label: string;
  onItemClick: () => void;
  route: string;
  moduleName: string;
}

function MobileDrawerItem({ icon, label, onItemClick, route, moduleName }: Readonly<IMobileDrawerItemProps>) {
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useTrack();

  const isCurrentlyActive = location.pathname === route;

  const handleItemClick = () => {
    if (!isCurrentlyActive) {
      track(COEX_SIDEBAR_ITEM, { moduleName });
      navigate(route);
    }
    onItemClick();
  };

  return (
    <BdsGrid
      className={`host-mobile-drawer-item ${isCurrentlyActive && 'host-active'}`}
      gap="1"
      onClick={handleItemClick}
    >
      <BdsIcon name={icon} className={`${isCurrentlyActive && 'host-active'}`}></BdsIcon>
      <BdsTypo variant="fs-14" bold="bold" className={`${isCurrentlyActive && 'host-active'}`}>
        {label}
      </BdsTypo>
    </BdsGrid>
  );
}

export default MobileDrawerItem;
