import { BdsGrid, BdsLoadingPage, BdsPaper } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import usePlgApi from 'hooks/usePlgApi';
import useTrack from 'hooks/useTrack';
import { FAQ_NEWFAQ_NEXTQUESTIONS } from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WizardTempFaq } from 'types/Faq';
import FaqWizardHeader from '../FaqWizardHeader';
import FaqInputCatalog from './FaqInputCatalog';
import FaqInputNew from './FaqInputNew';
import FaqInputOpeningHours from './FaqInputOpeningHours';
import FaqInputOtherTopics from './FaqInputOtherTopics/FaqInputOtherTopics';
import FaqInputPaymentOptions from './FaqInputPaymentOptions';
import FaqInputSegment from './FaqInputSegment';
import FaqInputStoreAddress from './FaqInputStoreAddress';
import './style.scss';

type FaqWizardInputProps = {
  setPageHeaderTracking: (payload: any) => void;
};

function FaqWizardInput({ setPageHeaderTracking }: FaqWizardInputProps) {
  const navigate = useNavigate();
  const { track } = useTrack();
  const { faqs, setFaqs } = useAppContext();
  const { patchFaq } = usePlgApi();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [wizardTempFaqs, setWizardTempFaqs] = useState<WizardTempFaq[]>([]);

  const trackStep = (
    currentStep: number,
    status: string,
    option?: string | undefined,
    questionID?: string | undefined,
    openAnswer?: string | undefined,
    dontApply?: boolean | undefined,
  ) => {
    let booleanAnswer = undefined;
    if (option === 'yes') {
      booleanAnswer = true;
    } else if (option === 'no') {
      booleanAnswer = false;
    }
    const payload = {
      typeQuestion: currentStep === 6 ? 'ADD_PELO_USER' : 'PADRÃO',
      questionName: steps[currentStep].label,
      booleanAnswer,
      question: undefined,
      openAnswer,
      questionID,
      questionSource: 'new',
      amountQuestions: faqs.length,
      status,
      error: undefined,
      doNotApply: dontApply,
    };
    track(FAQ_NEWFAQ_NEXTQUESTIONS, payload);
  };

  const setStepActive = (step: number) => {
    if (step > steps.length - 1) return;
    if (step < 0) navigate('/dashboard');
    setPageHeaderTracking({
      typeQuestion: currentStep === 6 ? 'ADD_PELO_USER' : 'PADRÃO',
      questionName: steps[currentStep].label,
    });
    setCurrentStep(step);
  };

  const moveNextStep = () => {
    setStepActive(currentStep + 1);
  };

  const getStepContentByIndex = (index: number) => {
    return steps.find(step => step.index === index)?.content;
  };

  const nextClickCallback = (tempFaq: WizardTempFaq | null) => {
    if (!tempFaq?.faq) return;

    if (tempFaq?.doNotApply) {
      trackStep(
        currentStep,
        'success',
        tempFaq.selectedOption,
        tempFaq.faq.id,
        tempFaq.faq.answer,
        tempFaq?.doNotApply,
      );
      moveNextStep();
      return;
    }

    wizardTempFaqs[currentStep] = tempFaq || wizardTempFaqs.push(tempFaq);
    if (wizardTempFaqs[currentStep]) {
      wizardTempFaqs[currentStep] = tempFaq;
    } else {
      wizardTempFaqs.push(tempFaq);
    }
    setWizardTempFaqs(wizardTempFaqs);

    trackStep(currentStep, 'success', tempFaq.selectedOption, tempFaq.faq.id, tempFaq.faq.answer);
    moveNextStep();
  };

  const saveWizardFaqs = async (selectedOption: string) => {
    const faqsToPatch = wizardTempFaqs.map(faq => faq.faq).filter(faq => faq !== null);
    try {
      faqsToPatch.shift();
      setIsLoading(true);
      await patchFaq(faqsToPatch);
      setFaqs(faqsToPatch);
      trackStep(currentStep, 'success', selectedOption);
      if (selectedOption === 'yes') {
        moveNextStep();
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      const err = error as Error;
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    {
      index: 0,
      label: 'SEGMENTO',
      content: <FaqInputSegment tempFaq={wizardTempFaqs[0]} onNextClickCallback={nextClickCallback} />,
    },
    {
      index: 1,
      label: 'CATALOGO_SITE',
      content: <FaqInputCatalog tempFaq={wizardTempFaqs[1]} onNextClickCallback={nextClickCallback} />,
    },
    {
      index: 2,
      label: 'LOJA_FISICA',
      content: <FaqInputStoreAddress tempFaq={wizardTempFaqs[2]} onNextClickCallback={nextClickCallback} />,
    },
    {
      index: 3,
      label: 'HORARIO_FUNCIONAMENTO',
      content: <FaqInputOpeningHours tempFaq={wizardTempFaqs[3]} onNextClickCallback={nextClickCallback} />,
    },
    {
      index: 4,
      label: 'PAYMENT_OPTIONS',
      content: <FaqInputPaymentOptions tempFaq={wizardTempFaqs[4]} onNextClickCallback={nextClickCallback} />,
    },
    {
      index: 5,
      label: 'OUTROS_ASSUNTOS',
      content: <FaqInputOtherTopics onNextClickCallback={saveWizardFaqs} />,
    },
    {
      index: 6,
      label: 'CADASTRO_FAQ',
      content: <FaqInputNew />,
    },
  ];

  return isLoading ? (
    <BdsLoadingPage />
  ) : (
    <>
      <BdsPaper class="wizard-paper">
        <BdsGrid direction="column">
          <FaqWizardHeader
            questionName={steps[currentStep].label}
            typeQuestion={currentStep === 5 ? 'ADD_PELO_USER' : 'PADRÃO'}
          />
          <BdsGrid class="faq-content" direction="column" gap="2" alignItems="flex-start">
            {getStepContentByIndex(currentStep)}
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
    </>
  );
}

export default FaqWizardInput;
