import { firstDashboardVisit } from 'atoms/AppAtoms';
import { BdsGrid } from 'blip-ds/dist/blip-ds-react/components';
import AssistantConfigSection from 'components/AssistantConfigSection';
import FaqCard from 'components/FAQ/FaqCard';
import FaqInputContinueModal from 'components/FaqWizard/FaqWizardInput/FaqInputNew/FaqInputContinueModal';
import InstallationErrorHeader from 'components/InstallationErrorHeader';
import MainHeader from 'components/MainHeader';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import WhatsappConnectionCard from 'components/WhatsapppConectionCard/WhatsapppConectionCard';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import usePacksApi from 'hooks/usePacksApi';
import useTrack from 'hooks/useTrack';
import { useAtomValue } from 'jotai';
import { COEX_CONNECTION_START, INSTALLATION, OPENED } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const { botConfiguration, installation, whatsAppActivated } = useAppContext();
  const firstVisit = useAtomValue(firstDashboardVisit);
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const isMobileView = useMobileView();
  const { pollInstallationStatusWithRetries } = usePacksApi();
  const { track } = useTrack();
  const navigate = useNavigate();
  const { userData } = useAuth();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;

  const pollInstallationStatus = async (botId: string) => {
    if (installation.statusProcess?.status === 'Completed') return;
    try {
      await pollInstallationStatusWithRetries(botId);
    } catch (error) {
      track(INSTALLATION, { status: 'Error', error: error });
      setShowErrorHeader(true);
    }
  };

  const handleStartConectionClick = () => {
    track(COEX_CONNECTION_START);
    navigate('/whatsapp/number-check');
  };

  useEffect(() => {
    if (firstVisit) {
      track(OPENED);
      if (!botConfiguration?.botId) {
        return navigate('/');
      }
      pollInstallationStatus(botConfiguration.botId);
    }
  }, []);

  return (
    <>
      {showErrorHeader && <InstallationErrorHeader hideError={() => setShowErrorHeader(false)} />}
      {
        <>
          {isMobileView ? <MobileToolbar /> : <Sidebar />}
          <BdsGrid justifyContent="center">
            <BdsGrid direction="column" className="main-container" gap="3">
              <MainHeader
                title={`Boas vindas, ${userName}`}
                description="Gerencie o seu atendimento e campanhas com o Blip Go Coex!"
              />
              {whatsAppActivated ? (
                <BdsGrid
                  xs="auto"
                  lg="auto"
                  md="8"
                  gap="3"
                  direction={!isMobileView ? 'row' : 'column'}
                  padding="half"
                  margin="none"
                >
                  <FaqCard />
                </BdsGrid>
              ) : (
                <>
                  <WhatsappConnectionCard
                    description="Com a conexão ativa, você poderá usar nosso assistente com IA, e iremos fornecer dados para te ajudar a atender melhor e aumentar as vendas."
                    onActionClick={handleStartConectionClick}
                    statusType="warning"
                    statusName="Importante"
                    title="Conecte ao WhatsApp Business"
                  />
                  <AssistantConfigSection />
                </>
              )}
            </BdsGrid>
          </BdsGrid>
          <FaqInputContinueModal amountQuestions={5} />
        </>
      }
    </>
  );
}

export default Dashboard;
