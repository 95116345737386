export function DataLayer() {
  const TriggerInstagramConversion = () => {
    (window as any).dataLayer.push({
      event: 'channel_activated',
      channel: 'Instagram',
    });
  };

  return { TriggerInstagramConversion };
}
