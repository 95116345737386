// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-cotainer {
  width: 100%;
  text-wrap: wrap;
}
.step-cotainer img {
  height: 25rem;
}
@media (max-width: 767.98px) {
  .step-cotainer img {
    height: 100%;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramBindPages/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;AACJ;AAAI;EAFF;IAGI,YAAA;IACA,WAAA;EAGJ;AACF","sourcesContent":[".step-cotainer {\n  width: 100%;\n  text-wrap: wrap;\n\n  img {\n    height: 25rem;\n    @media (max-width: 767.98px) {\n      height: 100%;\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
