import { BdsButton, BdsGrid, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import useTrack from 'hooks/useTrack';
import { REACTIVATE_ADD_FAQ } from 'libs/trackingEvents';
import React, { Ref, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

const InsertDataModal = forwardRef(function InsertDataModal(props: {}, ref: Ref<HTMLBdsModalElement>) {
  const { track } = useTrack();
  const modalRef = document.querySelector('#info-modal') as HTMLBdsModalElement;
  const navigate = useNavigate();

  const navigateToFaqWizard = () => {
    track(REACTIVATE_ADD_FAQ);
    modalRef?.toggle();
    navigate('/faq-wizard');
  };

  return (
    <>
      <BdsModal id="info-modal" ref={ref}>
        <div>
          <BdsTypo variant="fs-20" bold="bold">
            Como você deseja adicionar informações ao seu assistente?
          </BdsTypo>
          <BdsTypo variant="fs-14">
            O assistente precisa de uma fonte de informação conectada para criar resposta spara seus clientes. Escolha a
            melhor forma para adicionar suas informações:
          </BdsTypo>
        </div>
        <BdsGrid direction="column" alignItems="center">
          <BdsButton onClick={navigateToFaqWizard}>Adicionar tópicos de conversa</BdsButton>
        </BdsGrid>
      </BdsModal>
    </>
  );
});

export default InsertDataModal;
