import { blipchatKey, instagramUsername } from 'atoms/AppAtoms';
import { BlipChat } from 'blip-chat-widget';
import { useAppContext } from 'contexts/AppContext';
import { useAtom, useAtomValue } from 'jotai';
import { GRAPH_API_VERSION } from 'libs/graphApiRoutes';
import { ContactStatus, HUBSPOT_ASSIST_PARAMETER } from 'libs/hubspotParameters';
import { InstagramActivationErrors } from 'libs/instagramSteps';
import * as packsApiRoutes from 'libs/packsApiRoutes';
import { TEMPLATE_URL_ASSIST } from 'libs/templatesActiveMessage';
import { ASSISTANT_ACTIVATED } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import { ILogArgs } from 'packs-template-baseweb';
import { useCallback, useMemo } from 'react';
import { BotConfiguration, BotStatus } from 'types/Bot';
import { BotChannels } from 'types/Channels';
import { FacebookAppInformation } from 'types/Facebook';
import { HubspotContactUpdate } from 'types/Hubspot';
import {
  InstagramActivateCommand,
  InstagramBusinessAccount,
  InstagramBusinessAccountDetails,
  enableStoryReplyCommand,
} from 'types/Instagram';
import { InstallationStatus, Pack, PackInstallation, UpdateResponse } from 'types/Pack';
import { isInstagramChannelActive, isWhatsAppChannelActive } from 'utils/assertions';
import { RequestErrorBoundary } from 'utils/request';
import useBlipShopApi from './useBlipShopApi';
import usePlgApi from './usePlgApi';
import useTrack from './useTrack';

const logArgs: ILogArgs = {
  className: 'usePacksApi',
};

const usePacksApi = () => {
  const { userData } = useAuth();
  const { createHubspotContactAndTicket } = useBlipShopApi();
  const packId = process.env.REACT_APP_PACK_ID;
  const tenant = process.env.REACT_APP_PACK_TENANT;
  const [, setInstagramUsername] = useAtom(instagramUsername);
  const { setBotStatus, saveInstagramData, sendActiveMessage } = usePlgApi();
  const { setInstagramActivated } = useAppContext();
  const { track } = useTrack();

  const chatKey = useAtomValue(blipchatKey);
  const header = useMemo(() => {
    return {
      'Content-Type': 'application/json',
      Authorization: `bearer ${userData?.access_token}`,
    };
  }, [userData?.access_token]);

  const installPack = async (): Promise<Pack> => {
    const body = {
      applicationId: packId,
      clientIdentity: userData?.profile.Email,
      clientEmail: userData?.profile.Email,
      tenant: tenant,
    };
    logArgs.methodName = 'installPack';
    try {
      const res = await fetch(packsApiRoutes.INSTALL_PACK, {
        headers: header,
        method: 'POST',
        body: JSON.stringify(body),
      });
      return await RequestErrorBoundary(res, logArgs);
    } catch (error) {
      const err = error as Error;
      return Promise.reject(err.message);
    }
  };

  const getInstallationStatus = useCallback(
    async (installationId: string): Promise<InstallationStatus> => {
      logArgs.methodName = 'getInstallationStatus';
      const res = await fetch(
        `${packsApiRoutes.INSTALLATION_STATUS}?applicationId=${packId}&installations=${installationId}`,
        { headers: header },
      );
      const data = await res.json();
      return Promise.resolve(data[0]);
    },
    [header, packId],
  );

  const getBotIdIfInstalled = useCallback(async (): Promise<string | undefined> => {
    logArgs.methodName = 'getBotIdIfInstalled';
    try {
      const res = await fetch(`${packsApiRoutes.GET_BOTID}?packId=${packId}&identity=${userData?.profile.email}`, {
        headers: header,
      });
      const botId = (await RequestErrorBoundary(res, logArgs)) as string;
      return Promise.resolve(botId);
    } catch {
      return Promise.resolve(undefined);
    }
  }, [header, packId, userData?.profile.email]);

  const getIsInstagramActivated = useCallback(
    async (botId: string): Promise<boolean> => {
      const res = await fetch(packsApiRoutes.GET_CHANNEL_STATUS(botId), { headers: header });
      const data = (await RequestErrorBoundary(res, logArgs)) as BotChannels;
      return Promise.resolve(isInstagramChannelActive(data));
    },
    [header],
  );

  const getIsWhatsAppActivated = useCallback(
    async (botId: string) => {
      const res = await fetch(packsApiRoutes.GET_CHANNEL_STATUS(botId), { headers: header });
      const data = (await RequestErrorBoundary(res, logArgs)) as BotChannels;
      return Promise.resolve(isWhatsAppChannelActive(data));
    },
    [header],
  );

  const getInstallationChannelsStatus = useCallback(
    async (botId: string) => {
      try {
        const res = await fetch(packsApiRoutes.GET_CHANNEL_STATUS(botId), { headers: header });
        const data = (await RequestErrorBoundary(res, logArgs)) as BotChannels;
        return Promise.resolve(data);
      } catch (error) {
        if (error instanceof Error && error.message.includes('404')) {
          return null;
        }
        return Promise.reject(new Error(JSON.stringify(error)));
      }
    },
    [header],
  );

  const retryInstallation = useCallback(async () => {
    logArgs.methodName = 'retryInstallation';
    try {
      const res = await fetch(`${packsApiRoutes.RETRY_INSTALLATION}?packId=${packId}`, {
        headers: header,
        method: 'POST',
      });
      const data = (await RequestErrorBoundary(res, logArgs)) as UpdateResponse;
      return Promise.resolve(data.installationIds[0].toString());
    } catch {
      return Promise.reject('Erro de retentativa');
    }
  }, [packId, header]);

  const getPackInstallation = useCallback(
    async (botId: string) => {
      logArgs.methodName = 'getPackInstallation';
      try {
        const res = await fetch(`${packsApiRoutes.GET_LAST_INSTALLATION}?shortName=${botId}`, { headers: header });
        const data = (await RequestErrorBoundary(res, logArgs)) as PackInstallation;
        return Promise.resolve(data);
      } catch {
        return Promise.reject('Erro ao buscar instalação');
      }
    },
    [header],
  );

  const pollInstallationStatusWithRetries = useCallback(
    async (botId: string, installationId?: string) => {
      const pollingRate = 10000;
      let maxRetries = 3;
      let currentInstallationId = '';

      if (!installationId) {
        const pack = await getPackInstallation(botId);
        if (pack.statusProcess === null) {
          return Promise.resolve();
        }
        currentInstallationId = pack.installationId.toString();
      }

      const poll = async () => {
        const res = await getInstallationStatus(installationId ?? currentInstallationId);
        if (res.status === 'Canceled' && maxRetries === 0) {
          return Promise.reject('API failure');
        } else if (res.status === 'Completed') {
          return Promise.resolve();
        } else if (res.status === 'Canceled') {
          try {
            //vou deixar aqui ainda o código para quando pack ajustar voltarmos a usar
            //currentInstallationId = await retryInstallation();
            // maxRetries--;
            return Promise.reject('API failure');
          } catch (error) {
            return Promise.reject('API failure');
          }
        }
        setTimeout(poll, pollingRate);
      };

      setTimeout(poll, pollingRate);
    },
    [getInstallationStatus, getPackInstallation],
  );

  const mountBlipChat = useCallback(
    async (key?: string) => {
      const chatStyle = `#blip-chat-header {
      display: none;
      }
      #app {
        padding: 0 !important;
        height: 100% !important;
      }`;
      const customChatUrl = `https://${process.env.REACT_APP_PACK_TENANT}.${process.env.REACT_APP_BLIPCHAT_SUFFIX}.blip.ai`;
      new BlipChat()
        .withAppKey(chatKey ?? key)
        .withCustomCommonUrl(customChatUrl)
        .withTarget('chat')
        .withCustomStyle(chatStyle)
        .withEventHandler(BlipChat.LOAD_EVENT, function () {})
        .build();
    },
    [chatKey],
  );

  const getInstagramBusinessAccounts = useCallback(
    async (botId: string, userMetaToken: string) => {
      logArgs.methodName = 'getInstagramBusinessAccounts';
      try {
        const requestHeader = { ...header, userMetaToken: userMetaToken };
        const res = await fetch(`${packsApiRoutes.GET_INSTAGRAM_BUSINESS_ACCOUNTS}/${botId}`, {
          headers: requestHeader,
        });
        const data = (await RequestErrorBoundary(res, logArgs)) as InstagramBusinessAccount;
        return Promise.resolve(data);
      } catch {
        return Promise.reject('Erro ao buscar contas do Instagram');
      }
    },
    [header],
  );

  const activateInstagram = useCallback(
    async (
      botId: string,
      userId: string,
      instagramBusinessAccountDetails: InstagramBusinessAccountDetails,
    ): Promise<void> => {
      logArgs.methodName = 'activateInstagram';
      try {
        const body: InstagramActivateCommand = {
          shortName: botId,
          isChannelActive: 'true',
          instagramBusinessAccountId: instagramBusinessAccountDetails.instagramBusinessAccountId,
          pageId: instagramBusinessAccountDetails.pageId,
          pageAccessToken: instagramBusinessAccountDetails.pageAccessToken,
          userId: userId,
        };
        const res = await fetch(`${packsApiRoutes.ACTIVATE_INSTAGRAM}`, {
          headers: header,
          method: 'POST',
          body: JSON.stringify(body),
        });

        return await RequestErrorBoundary(res, logArgs);
      } catch (error: any) {
        const err = error as Error;
        if (err.message.includes('DisabledAccessInstagramMessages') || err.message.includes('[64]'))
          return Promise.reject(InstagramActivationErrors.NoMessagesAccess);

        if (err.message.includes('vincul') || err.message.includes('#100'))
          return Promise.reject(InstagramActivationErrors.NoBindedPagesFound);

        return Promise.reject(InstagramActivationErrors.Unknown);
      }
    },
    [header],
  );

  const enableStoryReply = useCallback(
    async (shortName: string): Promise<void> => {
      logArgs.methodName = 'enableStoryReply';
      try {
        const body: enableStoryReplyCommand = {
          shortName: shortName,
          enableStoryReply: true,
        };
        const res = await fetch(`${packsApiRoutes.ENABLE_STORY_REPLY}`, {
          headers: header,
          method: 'POST',
          body: JSON.stringify(body),
        });

        return await RequestErrorBoundary(res, logArgs);
      } catch (error: any) {
        return Promise.reject('Falha ao ativar respostas ao story no instagram');
      }
    },
    [header],
  );

  const getFacebookAppInfo = useCallback(
    async (botId: string) => {
      logArgs.methodName = 'getFacebookAppInfo';
      try {
        const res = await fetch(
          `${packsApiRoutes.GET_FACEBOOK_APP_INFORMATION}?shortName=${botId}&tenantId=${tenant}`,
          {
            headers: header,
          },
        );
        const data = (await RequestErrorBoundary(res, logArgs)) as FacebookAppInformation;
        return Promise.resolve(data);
      } catch (error) {
        const defaultAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
        if (!defaultAppId) {
          return Promise.reject('Default App Id not found!');
        }
        const defaultAppInfo: FacebookAppInformation = {
          appId: defaultAppId,
          graphApiVersion: GRAPH_API_VERSION,
          authApiVersion: 'v5.0',
        };
        return Promise.resolve(defaultAppInfo);
      }
    },
    [header, tenant],
  );

  const updateHubspotContact = useCallback(
    async (hubsportProperty: string, contactStatus: any, botId: string) => {
      logArgs.methodName = 'updateHubspotContact';
      const body: HubspotContactUpdate = {
        properties: [
          {
            property: hubsportProperty,
            value: contactStatus,
          },
        ],
      };
      try {
        const res = await fetch(packsApiRoutes.HUBSPOT_UPDATE_CONTACT, {
          headers: header,
          method: 'POST',
          body: JSON.stringify(body),
        });
        if (!res.ok) {
          const errorResponse = await res.json();
          if (
            errorResponse.Error.ErrorCode === 163 ||
            errorResponse.Error.Message === 'Falha ao atualizar as propriedades do contato'
          ) {
            await createHubspotContactAndTicket(botId);
            const secondTry = await fetch(packsApiRoutes.HUBSPOT_UPDATE_CONTACT, {
              headers: header,
              method: 'POST',
              body: JSON.stringify(body),
            });
            return await RequestErrorBoundary(secondTry, logArgs);
          }
        }
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        return false;
      }
    },
    [header],
  );

  const updateUserInstagramStatusAndData = useCallback(
    async (botConfiguration: BotConfiguration, selectedAccount: InstagramBusinessAccountDetails) => {
      setInstagramActivated(true);
      setInstagramUsername(selectedAccount.userName);
      await Promise.all([
        setBotStatus(BotStatus.Active),
        saveInstagramData(selectedAccount),
        updateHubspotContact(HUBSPOT_ASSIST_PARAMETER, ContactStatus.InstagramConnected, botConfiguration.botId),
        sendActiveMessage(botConfiguration, TEMPLATE_URL_ASSIST),
      ]);
      track(ASSISTANT_ACTIVATED);
    },
    [
      saveInstagramData,
      setBotStatus,
      setInstagramActivated,
      setInstagramUsername,
      updateHubspotContact,
      sendActiveMessage,
      track,
    ],
  );

  return {
    installPack,
    mountBlipChat,
    getInstallationStatus,
    getPackInstallation,
    getBotIdIfInstalled,
    getIsInstagramActivated,
    getIsWhatsAppActivated,
    getInstallationChannelsStatus,
    pollInstallationStatusWithRetries,
    getInstagramBusinessAccounts,
    activateInstagram,
    getFacebookAppInfo,
    updateHubspotContact,
    updateUserInstagramStatusAndData,
    enableStoryReply,
  };
};

export default usePacksApi;
