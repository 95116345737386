import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { Contact } from 'types/Contact';

export const exportToExcel = async (contacts: Contact[]) => {
  const headers = ['telefone', 'nome'];

  const data = contacts.map(contact => ({
    Telefone: contact.phoneNumber,
    Nome: contact.name || '',
  }));

  const ws = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
  XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Contacts');

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const formattedDate = dayjs().format('DD-MM-YY');

  const fileName = `contacts_${formattedDate}.xlsx`;

  saveAs(blob, fileName);
};
