import { BdsGrid, BdsInput, BdsRadio, BdsRadioGroup } from 'blip-ds/dist/blip-ds-react/components';
import FaqChatBubble from 'components/FaqWizard/FaqChatBubble';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import React, { useEffect, useRef, useState } from 'react';
import { Faq, WizardTempFaq } from 'types/Faq';

type FaqInputSegmentProps = {
  tempFaq: WizardTempFaq | null;
  onNextClickCallback: (faq: WizardTempFaq | null) => void;
};

function FaqInputSegment({ tempFaq, onNextClickCallback }: FaqInputSegmentProps) {
  const isMobileView = useMobileView();
  const [selectedOption, setSelectedOption] = useState(tempFaq?.selectedOption);
  const [isFaqValid, setIsFaqValid] = useState(false);
  const inputAnswerRef = useRef<HTMLBdsInputElement>(null);

  const defaultQuestion = 'Qual é o seu segmento?';

  const handleNextClick = () => {
    let tempFaq = { selectedOption } as WizardTempFaq;
    const randomId = crypto.randomUUID();
    tempFaq.faq = {
      id: randomId,
      question: defaultQuestion,
      answer: selectedOption,
    } as Faq;
    onNextClickCallback(tempFaq);
  };

  const validateInputs = async () => {
    setIsFaqValid(true);
  };

  useEffect(() => {
    const clearForm = () => {
      inputAnswerRef?.current?.clear();
    };

    const radio = document.querySelector('#rbg-options') as HTMLBdsInputElement;
    radio.addEventListener('bdsRadioGroupChange', (ev: any) => {
      clearForm();
      const option = ev?.detail?.value;
      setSelectedOption(option);
    });
  }, [selectedOption, tempFaq?.faq]);

  useEffect(() => {
    setIsFaqValid(selectedOption != undefined);
  }, [selectedOption, tempFaq]);

  return (
    <>
      <FaqChatBubble contentHTML={'Vamos passar por algumas perguntas sobre o seu negócio.'} />
      <FaqChatBubble contentHTML={'Assim vou saber melhor como conversar com seu públlico.'} />
      <FaqChatBubble contentHTML={'Primeiro, escolha o <b>segmento</b> que mais se adequa ao seu negócio'} />
      <BdsGrid padding="l-1">
        <BdsRadioGroup id="rbg-options">
          <BdsGrid direction="column" gap="1">
            <BdsRadio
              value="retail"
              label="Varejo"
              onBdsClickChange={() => {
                validateInputs();
              }}
              checked={selectedOption === 'retail'}
            />
            <BdsRadio
              value="services"
              label="Serviços"
              onBdsClickChange={() => {
                validateInputs();
              }}
              checked={selectedOption === 'services'}
            />
            <BdsRadio
              value="others"
              label="Outros"
              onBdsClickChange={() => {
                validateInputs();
              }}
              checked={selectedOption === 'others'}
            />
          </BdsGrid>
        </BdsRadioGroup>
      </BdsGrid>
      <BdsGrid xxs="12" margin="t-4" gap="2" direction="column-reverse">
        <MaterialButton
          onClickCallback={handleNextClick}
          text="Confirmar e continuar"
          variant="primary"
          endIconBdsName="arrow-right"
          isMobileView={isMobileView}
          disabled={!isFaqValid}
        ></MaterialButton>
      </BdsGrid>
    </>
  );
}

export default FaqInputSegment;
