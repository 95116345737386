// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-list-container {
  max-width: 80rem !important;
  padding-left: 8rem;
  padding-top: 4rem;
}
@media (max-width: 768px) {
  .faq-list-container {
    padding-left: 0;
    padding-top: 0;
    max-width: 10rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Faq/style.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,kBAAA;EACA,iBAAA;AACF;AACE;EALF;IAMI,eAAA;IACA,cAAA;IACA,gBAAA;EAEF;AACF","sourcesContent":[".faq-list-container {\n  max-width: 80rem !important;\n  padding-left: 8rem;\n  padding-top: 4rem;\n\n  @media (max-width: 768px) {\n    padding-left: 0;\n    padding-top: 0;\n    max-width: 10rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
