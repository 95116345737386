import { BotConfiguration } from 'types/Bot';

export const prepareSimulatorPrompt = async (
  botConfiguration: BotConfiguration,
  prompt: Prompt,
  faqMarkdown: string,
) => {
  if (prompt.content) {
    const updatedContent = prompt.content.replace(/\{faqMarkdown\}/g, faqMarkdown);
    return updatedContent;
  }
};
