import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import MainContainerOnboarding from 'components/MainContainerOnboarding';
import { useMobileView } from 'contexts/MobileViewContext';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_SETUP_ACCEPT_TERMS } from 'libs/trackingEvents';
import usePlgApi from 'hooks/usePlgApi';
import useIpifyApi from 'hooks/useIpifyApi';
import { useAuth } from 'oidc-react';
import { HubspotDeal } from 'types/Hubspot';
import useToast from 'hooks/useToast';
import { HUBSPOT_CUSTOM_PARAMS_TERMS_AGREEMENT } from 'libs/hubspotParameters';
import { getBrowser } from 'utils/device';
import { ILogArgs } from 'packs-template-baseweb';
import { generalErrorHandler } from '/utils/generalErrorHandler';

const logArgs: ILogArgs = {
  className: 'TermsAgreement',
};

interface DeviceInfo {
  ip: string;
  browser: string;
  location: string;
}

function TermsAgreement() {
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [communicationAgreement, setCommunicationAgreement] = useState(false);
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const { track } = useTrack();
  const { updateSubscription, getSubscriptionByEmail, updateHubspotDeal } = usePlgApi();
  const { getPublicIP } = useIpifyApi();
  const { createToastError } = useToast();
  const userEmail = useAuth().userData?.profile.email as string;
  let approximateLocation = 'Não informado.';

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success => {
      const { latitude, longitude } = success.coords;
      approximateLocation = `${latitude},${longitude}`;
    });
  }, []);

  const isEventALinkClick = (event: React.MouseEvent) => {
    return event.target instanceof HTMLAnchorElement;
  };

  const handleTermsChange = async (event: React.MouseEvent) => {
    if (isEventALinkClick(event)) {
      return;
    }
    setTermsAgreement(!termsAgreement);
  };

  const handleNextStep = async () => {
    logArgs.methodName = 'handleNextStep';

    try {
      const deviceInfo = await collectDeviceInformation();

      await processSubscription(deviceInfo);
    } catch (error) {
      generalErrorHandler(error, logArgs);
    }
  };

  const collectDeviceInformation = async () => {
    try {
      const [publicIPResponse, browserInfo] = await Promise.allSettled([getPublicIP(), getBrowser()]);

      if (publicIPResponse.status === 'rejected') {
        throw new Error('IP_RETRIEVAL_FAILED');
      }

      return {
        ip: publicIPResponse.value.ip,
        browser: browserInfo.status === 'fulfilled' ? browserInfo.value : 'Navegador não identificado',
        location: approximateLocation,
      };
    } catch (error) {
      throw new Error('DEVICE_INFO_COLLECTION_FAILED');
    }
  };

  const processSubscription = async (deviceInfo: DeviceInfo) => {
    try {
      const subscriptionResponse = await getSubscriptionByEmail(userEmail);

      if (!subscriptionResponse?.subscriptionId) {
        throw new Error('SUBSCRIPTION_NOT_FOUND');
      }

      const signerInfo = {
        device: `IP:${deviceInfo.ip} | Navegador: ${deviceInfo.browser}`,
        approximateLocation: deviceInfo.location,
        subscriptionPlan: 'Blip Coex Starter',
      };

      await updateSubscription(subscriptionResponse.subscriptionId, signerInfo);
      track(COEX_SETUP_ACCEPT_TERMS);
      navigate('/onboarding/installing');
    } catch (error) {
      throw new Error('SUBSCRIPTION_PROCESS_FAILED');
    }
  };

  return (
    <MainContainerOnboarding
      subtitle="Para finalizar, aceite os termos de adesão do produto."
      title="Termos e condições"
    >
      <BdsGrid justifyContent="space-between" gap={isMobileView ? '2' : '4'} className="plan-container">
        <BdsGrid direction="column" justifyContent="center">
          <BdsTypo variant="fs-16" bold="bold">
            Plano Coex Starter
          </BdsTypo>
          <BdsTypo variant="fs-14">
            Análise de conversas, assistente virtual, disparo de mensagens em massa e acompanhamento de conversão em
            anúncios.
          </BdsTypo>
        </BdsGrid>
        <BdsGrid alignItems="center">
          <BdsTypo bold="bold" className="plan-price">
            Grátis
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>
      <BdsGrid
        gap="2"
        alignItems="center"
        className={`term-checkbox-container ${termsAgreement && 'selected-checkbox'}`}
        onClick={handleTermsChange}
      >
        <Checkbox
          checked={termsAgreement}
          onChange={ev => setTermsAgreement(ev.target.checked)}
          className="custom-checkbox"
        />
        <BdsTypo>
          Eu concordo com os{' '}
          <a className="terms-link" target="_blank" href="">
            Termos e Condições de Uso
          </a>{' '}
          do Blip Coex.
        </BdsTypo>
      </BdsGrid>

      <BdsGrid
        gap="2"
        className={`term-checkbox-container ${communicationAgreement && 'selected-checkbox'}`}
        onClick={() => setCommunicationAgreement(!communicationAgreement)}
      >
        <Checkbox
          checked={communicationAgreement}
          onChange={ev => setCommunicationAgreement(ev.target.checked)}
          className="custom-checkbox"
        />
        <BdsTypo>
          Aceito receber comunicações do Blip Coex no WhatsApp que podem incluir orientações e avisos sobre o produto.
        </BdsTypo>
      </BdsGrid>
      <BdsGrid justifyContent="space-between">
        <BdsButton variant="text" color="content" onBdsClick={() => navigate('/onboarding/company-form')}>
          Voltar
        </BdsButton>
        <BdsButton disabled={!termsAgreement || !communicationAgreement} onBdsClick={handleNextStep}>
          Acessar Blip Coex
        </BdsButton>
      </BdsGrid>
    </MainContainerOnboarding>
  );
}

export default TermsAgreement;
