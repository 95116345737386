import { BdsGrid, BdsStep, BdsStepper } from 'blip-ds/dist/blip-ds-react';
import MainHeader from 'components/MainHeader';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import WhatsAppDisconnected from 'components/WhatsappDisconected';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function ActiveMessages() {
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { whatsAppActivated } = useAppContext();

  useEffect(() => {
    const stepper = document.querySelector('bds-stepper');
    stepper?.setActiveStep(0);
    navigate('template');
  }, []);

  useEffect(() => {
    const stepper = document.querySelector('bds-stepper');
    switch (pathname) {
      case '/campaign/template':
        stepper?.resetCompletedSteps();
        stepper?.setActiveStep(0);
        break;
      case '/campaign/configuration':
        stepper?.resetCompletedSteps();
        stepper?.setCompletedStep(0);
        stepper?.setActiveStep(1);
        break;
      case '/campaign/confirm':
        stepper?.resetCompletedSteps();
        stepper?.setCompletedStep(0);
        stepper?.setCompletedStep(1);
        stepper?.setActiveStep(2);
        break;
      default:
        break;
    }
  }, [pathname]);

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <BdsGrid justifyContent="center">
        <BdsGrid direction="column" gap="3" className="main-container">
          <MainHeader
            title="Mensagens ativas"
            description="Dispare mensagens personalizáveis para vários clientes ao mesmo tempo."
          />
          {whatsAppActivated ? (
            <>
              {!isMobileView && (
                <BdsStepper data-testid="campaign-stepper">
                  <BdsStep>Selecionar mensagem</BdsStep>
                  <BdsStep>Configurar disparo</BdsStep>
                  <BdsStep>Confirmar envio</BdsStep>
                </BdsStepper>
              )}

              <div>
                <Outlet />
              </div>
            </>
          ) : (
            <WhatsAppDisconnected />
          )}
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default ActiveMessages;
