import { BotChannels } from 'types/Channels';

export function isEmpty(value: any): boolean {
  if (value === undefined) {
    return true;
  }

  if (typeof value === 'string') {
    return value.trim() === '';
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0;
  }

  return false;
}

export function findKeyInObject(obj: Record<string, string>, searchKey: string): boolean {
  const lowercaseSearchKey: string = searchKey.toLowerCase();

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const lowercaseKey: string = key.toLowerCase();
      if (lowercaseKey === lowercaseSearchKey) {
        return true;
      }
    }
  }

  return false;
}

export function areObjectsIdentical<T extends object>(obj1: T, obj2: T): boolean {
  const obj1Keys = Object.keys(obj1) as (keyof T)[];
  const obj2Keys = Object.keys(obj2) as (keyof T)[];

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (const key of obj1Keys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export function isInstagramChannelActive(data: BotChannels | null): boolean {
  if (!data?.instagram) {
    return false;
  }

  for (const item of data.instagram) {
    if (item.name.toLocaleLowerCase() === 'ischannelactive' && item.value.toLocaleLowerCase() === 'true') {
      return true;
    }
  }
  return false;
}

export function isWhatsAppChannelActive(data: BotChannels | null): boolean {
  if (!data?.wa) {
    return false;
  }

  for (const item of data.wa) {
    if (item.name.toLocaleLowerCase() === 'ischannelactive' && item.value.toLocaleLowerCase() === 'true') {
      return true;
    }
  }
  return false;
}

export function isStringMadeOfNumbers(input: string): boolean {
  return /^\d+$/.test(input);
}
