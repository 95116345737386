import { atom } from 'jotai';
import { FacebookAppInformation, FbPagesResult } from 'types/Facebook';

export const firstDashboardVisit = atom(true);
export const blipchatKey = atom<null | string>(null);
export const currentActivationStep = atom(1);
export const instagramUsername = atom<null | string>(null);
export const facebookAppInfo = atom<null | FacebookAppInformation>(null);
export const selectedFacebookPage = atom<null | FbPagesResult>(null);
export const whatsappActive = atom(false);
