// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .simulator-container {
    max-width: 48rem !important;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 768px) {
  .simulator-container {
    padding-top: 0 !important;
    margin: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AssistantTester/style.scss"],"names":[],"mappings":"AAEA;EACE;IACE,2BAAA;IACA,qBAAA;EADF;AACF;AAIA;EACE;IACE,yBAAA;IACA,oBAAA;EAFF;AACF","sourcesContent":["@use '/src/styles/colors';\n\n@media (min-width: 768px) {\n  .simulator-container {\n    max-width: 48rem !important;\n    margin-bottom: 1.5rem;\n  }\n}\n\n@media (max-width: 768px) {\n  .simulator-container {\n    padding-top: 0 !important;\n    margin: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
