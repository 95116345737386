// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  border: 1px solid var(--color-border-2, #e0e0e0);
  border-radius: 0.75rem;
  cursor: pointer;
}

.selected {
  border: 2px solid var(--color-surface-primary, #1e6bf1);
}`, "",{"version":3,"sources":["webpack://./src/pages/ActiveMessages/MessageResponseDirection/style.scss"],"names":[],"mappings":"AAEA;EACE,gDAAA;EACA,sBAAA;EACA,eAAA;AADF;;AAIA;EACE,uDAAA;AADF","sourcesContent":["@use '/src/styles/colors';\n\n.card-container {\n  border: 1px solid colors.$color-border-2;\n  border-radius: 0.75rem;\n  cursor: pointer;\n}\n\n.selected {\n  border: 2px solid colors.$color-surface-primary;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
