import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsButtonIcon,
  BdsGrid,
  BdsInput,
  BdsPaper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import ActiveMessagesCancelAlert from 'components/ActiveMessagesCancelAlert';
import useBlipGoApi from 'hooks/useBlipGoApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import {
  COEX_CAMPAIGN_BACK_BUTTON,
  COEX_CAMPAIGN_CANCEL_BUTTON,
  COEX_CAMPAIGN_CONFIRM,
  COEX_CAMPAIGN_CONFIRM_CANCELLING_ALERT,
  COEX_CAMPAIGN_EDIT_BUTTON,
} from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AudienceAndVariables, Template } from 'types/ActiveMessage';
import './style.scss';

function Confirmation() {
  const [loading, setLoading] = useState(false);
  const { dispatchWhatsAppCampaign } = useBlipGoApi();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const audience: AudienceAndVariables[] = state?.audience;
  const template: Template = state?.template;
  const audienceAmount = audience?.length;
  const { createToastError, createToastSuccess } = useToast();
  const { track } = useTrack();

  const handleBackButton = () => {
    track(COEX_CAMPAIGN_BACK_BUTTON, { stage: 'summary ' });
    navigate('../configuration', { state: state });
  };

  const handleDispatchButton = async () => {
    setLoading(true);
    const input = document.querySelector('bds-input');

    if (!input?.value) {
      createToastError('Nome da campanha não informado', 'Por favor, informe um nome para a campanha.');
      return;
    }

    const campaignName = input.value;
    try {
      await dispatchWhatsAppCampaign(campaignName, audience, template);
      track(COEX_CAMPAIGN_CONFIRM, { status: 'success' });
      createToastSuccess('Campanha criada com sucesso', 'As mensagens estão sendo enviadas.');
      navigate('/dashboard');
    } catch (error) {
      const err = error as Error;
      track(COEX_CAMPAIGN_CONFIRM, { status: 'failed', error: err.message });
      createToastError('Erro ao enviar mensagens', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenCancelAlert = () => {
    track(COEX_CAMPAIGN_CANCEL_BUTTON, { stage: 'summary' });
    const alert = document.getElementById('cancel-alert') as HTMLBdsAlertElement;
    alert?.toggle();
  };

  const handleConfirmCancelling = () => {
    track(COEX_CAMPAIGN_CONFIRM_CANCELLING_ALERT, { stage: 'summary' });
    navigate('../template');
  };

  const handleEditButton = () => {
    track(COEX_CAMPAIGN_EDIT_BUTTON);
    const alert = document.getElementById('edit-alert') as HTMLBdsAlertElement;
    alert?.toggle();
  };

  return (
    <>
      <BdsPaper style={{ backgroundColor: 'white' }}>
        <BdsGrid justifyContent="stretch" direction="column" gap="2" padding="4">
          <BdsTypo variant="fs-20" bold="bold" tag="h4" margin={false}>
            Resumo do envio
          </BdsTypo>
          <BdsGrid
            className="summary-container"
            alignItems="center"
            justifyContent="space-between"
            gap="3"
            padding="2"
            lg="12"
            xg="12"
            md="12"
          >
            <BdsGrid justifyContent="space-between" lg="4" xg="4" md="4" gap="3">
              <div>
                <BdsTypo variant="fs-14" bold="bold">
                  Modelo
                </BdsTypo>
                <BdsTypo variant="fs-14">{template.templateId}</BdsTypo>
              </div>
              <div>
                <BdsTypo variant="fs-14" bold="bold">
                  Audiência
                </BdsTypo>
                <BdsTypo variant="fs-14">{audienceAmount} contatos</BdsTypo>
              </div>
            </BdsGrid>
            <BdsButtonIcon
              data-testid="edit-button-icon"
              variant="secondary"
              onBdsClick={handleEditButton}
              icon="edit"
            />
          </BdsGrid>
          <BdsGrid direction="column" padding="y-2">
            <div className="horizontal-line" />
          </BdsGrid>
          <BdsTypo variant="fs-20" bold="bold" tag="h4" margin={false}>
            Nome do disparo
          </BdsTypo>
          <BdsTypo variant="fs-14">Defina um nome que será usado para identificação do disparo.</BdsTypo>
          <BdsInput
            errorMessage="Este campo não pode ficar em branco!"
            placeholder="Nome para identificação (uso interno)"
          />
          <BdsGrid justifyContent="space-between" margin="t-2">
            <BdsGrid gap="2">
              <BdsButton variant="tertiary" onBdsClick={handleBackButton}>
                Voltar
              </BdsButton>
              <BdsButton bdsLoading={loading} onBdsClick={handleDispatchButton}>
                Enviar mensagens
              </BdsButton>
            </BdsGrid>
            <BdsButton onBdsClick={handleOpenCancelAlert} variant="secondary">
              Cancelar
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <EditAlert />
      <ActiveMessagesCancelAlert confirmCallback={handleConfirmCancelling} />
    </>
  );
}

function EditAlert() {
  const navigate = useNavigate();
  const template = useLocation().state?.template;

  const handleBackButton = () => {
    const alert = document.getElementById('edit-alert') as HTMLBdsAlertElement;
    alert?.toggle();
  };

  const handleConfirm = () => {
    const alert = document.getElementById('edit-alert') as HTMLBdsAlertElement;
    alert?.toggle();
    navigate('../configuration', { state: { template } });
  };

  return (
    <BdsAlert data-testid="edit-alert" id="edit-alert">
      <BdsAlertHeader variant="warning" icon="warning">
        Deseja editar o envio?
      </BdsAlertHeader>
      <BdsAlertBody>
        <BdsTypo variant="fs-14">
          Ao <b>confirmar</b>, você retornará à etapa de configuração do disparo, e será preciso carregar novamente a
          planilha de audiência.
        </BdsTypo>
      </BdsAlertBody>
      <BdsAlertActions>
        <BdsButton variant="secondary" onBdsClick={handleBackButton}>
          Voltar
        </BdsButton>
        <BdsButton variant="secondary" onBdsClick={handleConfirm}>
          Confirmar
        </BdsButton>
      </BdsAlertActions>
    </BdsAlert>
  );
}

export default Confirmation;
