// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.illustation {
  width: 50px;
  height: 50px;
}

.desktop-content {
  width: 23.125rem;
  gap: 0.625rem;
}

.mobile-content {
  width: 19.4375rem;
  gap: 0.625rem;
  margin-top: 1rem;
}

.mobile-title {
  width: 15.6875rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FacebookCatalog/FacebookCatalogSteps/FacebookCatalogNoOrganizationFound/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".illustation{\n  width: 50px;\n  height: 50px;\n}\n\n.desktop-content{\n  width: 23.125rem;\n  gap: .625rem;\n}\n\n.mobile-content{\n  width: 19.4375rem;\n  gap: .625rem;\n  margin-top: 1rem;\n}\n\n.mobile-title{\n  width: 15.6875rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
