import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { COEX_HELP_CLICKED } from 'libs/trackingEvents';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface IMainHeaderProps {
  title: string;
  description: string;
}

function MainHeader({ title, description }: Readonly<IMainHeaderProps>) {
  const isMobile = useMobileView();
  const { track } = useTrack();
  const location = useLocation();

  const handleLinkClick = () => {
    track(COEX_HELP_CLICKED, { screenName: location.pathname });
    const coexLink =
      'https://api.whatsapp.com/send/?phone=553192670115&text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20o%20Blip%20Go!%20COEX%20Beta';
    window.open(coexLink, '_blank');
  };

  return (
    <BdsGrid justifyContent="space-between" alignItems="center">
      <BdsGrid direction="column" gap="1">
        <BdsTypo variant="fs-24" bold="bold" margin={false}>
          {title}
        </BdsTypo>
        <BdsTypo variant="fs-14">{description}</BdsTypo>
      </BdsGrid>
      {!isMobile && (
        <MaterialButton
          onClickCallback={handleLinkClick}
          text="Preciso de ajuda"
          startIconBdsName="agent"
          variant="secondary"
        />
      )}
    </BdsGrid>
  );
}

export default MainHeader;
