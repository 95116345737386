import {
  BdsButton,
  BdsGrid,
  BdsLoadingSpinner,
  BdsTable,
  BdsTableBody,
  BdsTableCell,
  BdsTableHeader,
  BdsTableRow,
  BdsTableTh,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import useBlipGoApi from 'hooks/useBlipGoApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import parse from 'html-react-parser';
import { COEX_CAMPAIGN_SELECT_TEMPLATE } from 'libs/trackingEvents';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Template } from 'types/ActiveMessage';
import './style.scss';

function MessageTemplates() {
  const { getMessagesTemplates } = useBlipGoApi();
  const [messageTemplates, setMessageTemplates] = useState<Template[]>([]);
  const { createToastError } = useToast();
  const { track } = useTrack();
  const navigate = useNavigate();

  const loadTemplates = async () => {
    try {
      const res = await getMessagesTemplates();
      setMessageTemplates(res);
    } catch {
      createToastError(
        'Erro ao carregar os templates de mensagem',
        'Por favor, verifique se existem templates cadastrados',
      );
      navigate('/quick-access');
    }
  };

  const handleTemplateClick = (template: Template) => {
    track(COEX_CAMPAIGN_SELECT_TEMPLATE);
    navigate('../configuration', { state: { template } });
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  if (!messageTemplates.length) {
    return (
      <BdsGrid justifyContent="center">
        <BdsLoadingSpinner data-testid="loading-spinner" />
      </BdsGrid>
    );
  }

  return (
    <BdsGrid direction="column" gap="4">
      <BdsTypo tag="h4" variant="fs-20" bold="bold" margin={false}>
        Comece selecionando um modelo da mensagem pré-aprovada
      </BdsTypo>

      <BdsTable class="templates-table">
        <BdsTableHeader>
          <BdsTableRow>
            <BdsTableTh>
              <BdsTypo variant="fs-12" bold="bold">
                Nome
              </BdsTypo>
            </BdsTableTh>
            <BdsTableTh>
              <BdsTypo variant="fs-12" bold="bold">
                Mensagem
              </BdsTypo>
            </BdsTableTh>
            <BdsTableTh></BdsTableTh>
          </BdsTableRow>
        </BdsTableHeader>
        <BdsTableBody>
          {messageTemplates.map((template, index) => {
            return (
              <TemplateListItem
                key={index + 1}
                id={template.templateId}
                text={template.body?.text}
                onTemplateClick={() => handleTemplateClick(template)}
              />
            );
          })}
        </BdsTableBody>
      </BdsTable>
    </BdsGrid>
  );
}

type ITemplateListItemProps = {
  id: string;
  text: string;
  onTemplateClick: () => void;
};

const TemplateListItem = ({ id, text, onTemplateClick }: ITemplateListItemProps) => {
  const parsedText = text
    .replace(/\\n+/g, match => '<br>'.repeat(match.length / 2))
    .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
    .replace(/\{\{(\d+)\}\}/g, (_, number) => `<strong>{{variavel${number}}}</strong>`)
    .replace(/_(.*?)_/g, '<em>$1</em>')
    .replace(/~(.*?)~/g, '<del>$1</del>');

  return (
    <BdsTableRow>
      <BdsTableCell>
        <BdsTypo variant="fs-14" bold="semi-bold">
          {id}
        </BdsTypo>
      </BdsTableCell>
      <BdsTableCell style={{ padding: '1rem' }}>
        <BdsTypo variant="fs-14" bold="semi-bold">
          {parse(parsedText)}
        </BdsTypo>
      </BdsTableCell>

      <BdsTableCell style={{ width: '8rem' }}>
        <BdsButton variant="tertiary" onBdsClick={onTemplateClick} data-testid={`template-${id}`}>
          Usar modelo
        </BdsButton>
      </BdsTableCell>
    </BdsTableRow>
  );
};

export default MessageTemplates;
