import { BdsChipTag, BdsGrid, BdsIllustration, BdsLoadingPage, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { FacebookCatalogSteps } from 'libs/facebookCatalogSteps';
import { CONNECT_CATALOG_FACEBOOK } from 'libs/trackingEvents';
import React, { useEffect, useState } from 'react';

function FacebookCatalogLogin({ setCurrentStep }: { setCurrentStep: (step: FacebookCatalogSteps) => void }) {
  const { botConfiguration } = useAppContext();
  const { initFacebookSdk, launchFacebookSignup, getFacebookConnectedData } = useGraphApi();
  const isMobileView = useMobileView();
  const { createToastError } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { track } = useTrack();

  const handleConnectFacebook = async () => {
    track(CONNECT_CATALOG_FACEBOOK);
    await initFacebookSdk(botConfiguration.botId);
    setIsLoading(true);

    try {
      await launchFacebookSignup('catalog_connection', async response => {
        if (!response || response.status !== 'connected') {
          createToastError('Falha ao conectar com o Facebook', 'Por favor, tente novamente');
          setIsLoading(false);
          return;
        }
        setCurrentStep(FacebookCatalogSteps.FacebookCatalogConnection);
        setIsLoading(false);
      });
    } catch {
      createToastError('Falha ao conectar com o Facebook', 'Por favor, tente novamente');
      setIsLoading(false);
      return null;
    }
  };

  useEffect(() => {
    const fbConnectedData = getFacebookConnectedData();
    if (fbConnectedData && fbConnectedData.scope === 'catalog_connection') {
      setCurrentStep(FacebookCatalogSteps.FacebookCatalogConnection);
    }
  });

  return (
    <>
      {isLoading && <BdsLoadingPage />}
      <BdsGrid alignItems="center" padding="3" gap="4" justifyContent="space-between">
        {!isMobileView && (
          <BdsGrid direction="column">
            <BdsIllustration name="blip-sales"></BdsIllustration>
          </BdsGrid>
        )}
        <BdsGrid direction="column">
          <BdsChipTag color="success" icon="checkball">
            Seu assistente já está ativo!
          </BdsChipTag>
          <BdsGrid margin="t-2">
            <BdsTypo tag="span" variant={!isMobileView ? 'fs-24' : 'fs-20'} bold="bold">
              Melhore seu assistente com o Catálogo do Facebook
            </BdsTypo>
          </BdsGrid>
          <BdsGrid margin={!isMobileView ? 't-4' : 't-3'}>
            <BdsTypo tag="p" lineHeight="plus">
              Ao conectar seu catálogo do Facebook é possível melhorar a eficiência do assistente, permitindo o
              envio&nbsp;
              <b>links de compra</b> para seus clientes.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column" gap="1" margin={!isMobileView ? 't-4' : 't-3'}>
            <bds-typo tag="p" lineHeight="plus">
              <b>Importante:</b> para fazer esta conexão é preciso ter um&nbsp;
              <a href="https://www.facebook.com/business/help/890714097648074?id=725943027795860" target="_blank">
                Catálogo do Facebook
              </a>
              &nbsp;configurado.
            </bds-typo>
          </BdsGrid>
          <BdsGrid margin="t-4">
            <MaterialButton
              onClickCallback={() => handleConnectFacebook()}
              text="Iniciar conexão"
              variant="primary"
              startIconBdsName="facebook"
              isMobileView={isMobileView}
            ></MaterialButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}
export default FacebookCatalogLogin;
