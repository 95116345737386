export const featureflags = {
  'desk-sales-deshboard': true,
  'is-displaying-dashboard-csv-download-buttons': true,
  'messaging-hub-command-with-delegation': true,
  'click-tracker-display-big-numbers': true,
  'bds-toast': true,
  'builder-output-variable-state': true,
  'cookies-modal': true,
  'builder-test-bucket-flow': true,
  'sentry-monitoring-ai-fragment': false,
  'bot-general-metrics-on-iris': true,
  'has-permission-to-access-prepared-blocks': true,
  'sentry-error-tracking': false,
  'ai-agent-template-hosting': false,
  'enable-personalized-breaks': true,
  'remove-media-message-template': true,
  'contract-admin-can-migrate-bots': false,
  'use-builder-application': false,
  'display-complete-analysis': false,
  'enabled-settings-care-copilot': true,
  'empty-metric-report-and-monitoring-view': false,
  'auto-closing-of-tickets-by-customer-inactivity': true,
  'abc-test-menu-attendance-tag': 'A',
  'teste-bot-notification': false,
  'use-desk-micro-frontend': false,
  'monitoring-paginate-filter': false,
  'crm-process-contacts-by-consumer': true,
  'Intent-Library-Experimentation': true,
  'contacts-journey-cassandra-datasource': false,
  testeee: 'jhkkjh',
  'enable-tags-queue-attendance': true,
  'import-and-export-answers-in-AI-module': true,
  'is-showing-data-dictionary': true,
  'dashboard-active-message': false,
  'undo-redo-configurations': true,
  'new-application-menu-order': true,
  'blip-store-extension-subscription-text-testing': true,
  'is-displaying-dashboard-analytics-channel-section': true,
  'is-builder-fullscreen-enabled': true,
  'channels-using-configurations-service-messenger': true,
  'blip-store-integrations-plugins-recommendation': true,
  'sugar-ads-integration': false,
  'changes-display-active-campaign-data': true,
  'is-filtering-owners-in-advanced-configuration': false,
  'open-ticket-with-command': true,
  'enable-active-message-scheduling': false,
  'show-organization-plan': false,
  'channels-using-configurations-service-takeio': true,
  'ads-buying-hide-budget-validation': true,
  'blip-chat-show-authenticated-medias-configuration': false,
  'whatsapp-embed-signup': true,
  'iframe-banner-marketing': 'undefined',
  'is-displaying-analytics-dashboard-download-button': false,
  'showing-blipscore': false,
  'hide-bot-access-key-in-portal': false,
  'attendance-rules-in-builder': true,
  'is-displaying-block-listing-section': true,
  'desk-attendant-calls-permission-and-config': false,
  'click-tracker-enable-google': false,
  'click-tracker-enable-v2': true,
  'ticket-transfer-attendant-permissions': true,
  'engine-localtimezone-enabled': false,
  'builder-new-command-buttons': true,
  'show-chatbot-cluster': true,
  'undo-redo-input': true,
  'is-service-tree-feature': false,
  'can-delete-default-attendance-queue': false,
  'message-template-buttons': true,
  'enable-get-threadmessage-merged': true,
  'insert-attachment-into-desk-chat': true,
  'block-add-view-plugins-advanced-bot-configuration': false,
  'transact-show-catalog-in-menu': false,
  'new-attendance-rules-queue-management': false,
  'show-builder-channels-exclusive-components': false,
  'action-with-condition': true,
  'is-showing-oauth-on-webhooks': true,
  'channels-using-configurations-service-workplace': true,
  'attendance-monitoring-text-revision-v2': true,
  'desk-sales-dashboard-team-metrics': true,
  'oauth-gmail': false,
  'undo-redo-content': true,
  'contacts-journey-hiding-beta-tag': true,
  'ai-agents-analytics-menu': true,
  'customer-response-time-alerts': true,
  'content-assistant': true,
  'hide-test-user-checkbox': true,
  'is-report-data-extractor-compression': false,
  'builder-real-time': false,
  'cluster-component-list': {},
  'enable-get-tickethistory-merged': true,
  'blip-store-menu-list-plugins-home-bot': true,
  'show-languages-for-message-template': true,
  'active-messages-tip': false,
  'portal-notification-center': true,
  'blip-store-discount-coupon-testing': false,
  'billing-daily-active-users-consumption': false,
  hasPermissionToAccessRelease2OfSubflow: false,
  'new-instagram-activation-flow': true,
  'hide-sensitive-information-on-http-connection': true,
  'tenant-ai-settings-copilot': true,
  'active-message-ticket-priority': true,
  'builder-execute-template-action': false,
  'ads-buying-active-obo-integration': false,
  'contacts-journey-first-node-filter': true,
  'whatsapp-mfe-enabled': false,
  'show-power-bi-embedded': false,
  'channel-apple-business-chat': false,
  'data-retention-alert': true,
  'desk-report-mfe': false,
  'assign-attendant-in-queue': true,
  'import-ai-templates': true,
  'direct-ticket-transfer': true,
  'use-newest-message-template-categories': false,
  'smart-sales-multi-blocks-settings': true,
  'new-channel-messenger-mfe': false,
  'blip-store-menu-extension-home-bot': true,
  'channels-using-configurations-service-instagram': true,
  'is-displaying-analytics-overview-tab': true,
  'click-tracker-enabled': true,
  'whatsapp-is-cloud-api': true,
  'allow-pull-manually-ticket': true,
  'active-message-screen': true,
  'partial-match-content-assistant': false,
  'desk-show-template-content': true,
  'content-assistant-v2': false,
  'contact-tax-document': true,
  'are-failing-webhooks-being-blocked': true,
  'generate-model-metrics-from-file': false,
  'use-configurations-domain-on-attendance-operations': false,
  'sentry-configuration': {
    dns: 'https://8a9668fbd3163b533711865dd098e922@o72524.ingest.sentry.io/4505782917595136',
    isEnabled: true,
    sampleRate: 0.03,
    startTransactionOnLocationChange: true,
    startTransactionOnPageLoad: true,
    tracesSampleRate: 0.03,
    tracesSampler: 1,
  },
  'cdp-beta-allowed-tenants': false,
  'register-maintenance': true,
  enable_new_settings_ai_agent: true,
  'is-builder-sticker-disabled': false,
  'avg-first-response-time-monitoring': true,
  'blip-store-home-bot-plugins-recommendation': true,
  'track-contacts-journey': true,
  'portal-gaia-enable-create-data-sharing-button': false,
  'enable-text-rewrite-on-report': true,
  'channels-using-configurations-service-businessmessages': true,
  'is-setting-by-team-enabled': true,
  'enable-download-button': false,
  'insert-external-attachment-into-desk-chat': true,
  'ai-fragment-list-available-react-pages': [],
  'can-delete-template-headstart': false,
  'builder-prepared-block-desk-survey': false,
  'enable-ai-agent-application': true,
  'check-model-status': true,
  'hide-whatsapp-embed-signup': false,
  'attendance-monitoring-status-call': false,
  'billing-tracking-log': false,
  'queue-management-in-builder': true,
  'is-displaying-recurrence-section': true,
  'show-partial-matching-option-content-assistant': true,
  'action-execution-timeout': true,
  'can-access-template-headstart': true,
  'ai-check-provider-credentials': true,
  'content-menu': true,
  'ads-buying-display': false,
  'enable-number-customer-waiting': true,
  'ai-enable-react-view': false,
  'account-using-server-side-cache': true,
  'undo-redo-block-name': true,
  'permissions-migration': false,
  isHasapplicationDomainActivations: false,
  'desk-receive-calls-permission-and-config': true,
  'delete-attendant-delegation-disabled': true,
  'ai-agent-enable': true,
  'use-configurations-domain-on-advanced-configuration-properties': false,
  'is-enabled-active-campaign-mfe': false,
  'close-script-tab': true,
  'disable-tenant-websocket-connection': false,
  'billing-daily-active-users-plan-informations': false,
  'desk-active-message-settings-mfe': true,
  'gaia-allow-create-data-sharing': false,
  'builder-metadata-content': true,
  'attendance-monitoring-send-disconnect-attendant': true,
  'tls-upgrade-warning-enabled-on-http-transport': false,
  'teste-learning-loop-0109': false,
  'messenger-message-tags': true,
  'ticket-transfer-modal': true,
  'desk-sales-dashboard-funnel': true,
  'new-channel-gbm-mfe': true,
  'queues-attendance-management': true,
  'azure-ad-b-2-c': {
    IDP_CONTRACT_EXPIRE: 30000,
    JWKS_URI: '/discovery/v2.0/keys',
    RESPONSE_TYPE: 'code',
    ACCOUNT_MANAGEMENT_URL: 'https://accountmanagement-api-hmg.blip.ai',
    TOKEN_ENDPOINT: '/oauth2/v2.0/token',
    isEnabled: false,
    SCOPE: 'openid profile email',
    AUTHORIZE_REDIRECT: 'https://accountmanagement-authentication-hmg.blip.ai/redirect',
    USER_INFO_ENDPOINT: '/openid/v2.0/userinfo',
    IDP_STATUS_EXPIRE: 30000,
    END_SESSION_ENDPOINT: '/oauth2/v2.0/logout',
    AUTHORIZATION_ENDPOINT: '/oauth2/v2.0/authorize',
    isProviderSelectorEnabled: false,
    ISSUER: 'https://hmg-login.blip.ai/00366ad7-743c-4311-bc01-432d657faebd/v2.0/',
    BLIP_ACCOUNT_URL: 'https://hmg-login.blip.ai/00366ad7-743c-4311-bc01-432d657faebd/b2c_1a_blipsignupsignin',
    BLIP_ACCOUNT_CLIENT_ID: '6693086c-4fd2-4978-bc20-30f67fdf5593',
  },
  'priority-tickets-queue-attendance': true,
  'can-create-template-headstart': false,
  'hide-sensitive-information-on-webhooks-oauth': true,
  plugin: true,
  'fake-atendance-category': false,
  'hubspot-analytics-integration': false,
  'dashboard-feature-ready': true,
  'use-websocket-commands-on-http-transport': true,
  'billing-new-session-pricing': true,
  'cookies-banner': false,
  'ai-agent-skills-enabled': false,
  'show-payments-menu': false,
  'attendance-permission': true,
  'attendance-monitoring-filters-autocomplete': false,
  'billing-data-consumption': true,
  'undo-redo-configurations-variables': true,
  'desk-payment-link': true,
  'contacts-journey-csv-export-button': true,
  'edit-message-metadata': true,
  'fetch-data-only-for-visible-tables-on-desk-monitoring': false,
  'configuration-token-management': false,
  'microfront-ai': true,
  'marketplace-plugin': true,
  'add-attendance-batch': true,
  'billing-session-consumption': false,
  'is-displaying-channel-section-download-button': false,
  'enable-contact-history': true,
  'rule-select-atendance-queue-mfe': true,
  'action-with-survey': false,
  'portal-fragment-desk-mfe-sentry-configuration': { isEnabled: false },
  'scheduling-from-active-message-feature-experiment': false,
  'is-master-offline-feature': true,
  'templates-lazy-loading-active-campaign': false,
  'health-check': true,
  'rules-migration-mfe': true,
  'showing-contacts-journey': true,
  'click-tracker-enable-tiktok': false,
  'blip-store-sentry-configuration': { isEnabled: false },
  'click-tracker-loading-only-one-day-in-filter': false,
  'agent-client-name-cache': true,
  'allow-creating-ai-agent-in-mfe': true,
  'analytics-messages-general-info': true,
  'is-displaying-dashboard-csv-download-conversional-flow-button': false,
  'using-starts-with-on-contacts-search': true,
  hasPermissionToAccessSubflow: true,
  'builder-create-blocks-with-copilot': true,
  main_page_pagination: true,
  'builder-owner-identity': false,
  'easter-egg': 0,
  'blip-copilot-plugin-report-enabled': false,
  'contacts-journey-contacts-button': true,
  'builder-redirect-no-agents-available': true,
  'desk-webhook-provider': true,
  'billing-active-notification-consumption': true,
  'attendance-survey-dashboard': true,
  'can-edit-delete-template-headstart': false,
  'cdp-allowed-contracts': {
    tenants: [
      'teste-blip-9yr3l',
      'teste-blip-9pcf2',
      'afya',
      'claretiano',
      'teste',
      'novatelecominternet',
      'laserfastdepilacao1',
      'claretiano',
      'nissan',
      'laserfast',
      'grancursosonline',
      'elevaeducacao',
      'elevaeducacaoexemplo91',
      'culturainglesa',
      'montecarlo',
      'contatointeligentetake',
      'take',
      'aioptimization',
      'take-beagleaz',
      'take-carameloaz',
      'sre-akita',
      'aiagents',
      'allugator',
      'compliance-c5a30',
      'listen-and-reach',
      'santamaria',
      'wlck',
      'ideamaker',
      'bu-fin',
      'petite-fleur-patisserie',
    ],
  },
  'channel-business-messages': true,
  'blip-store-hide-extension-price': true,
  'tenant-agent-triggerable-column-is-visible': true,
  'dialogflow-ia-integration': true,
  'enable-custom-pauses': false,
  'is-displaying-analytics-tabs': false,
  'billing-active-notification-consumption-monthly-by-template': false,
  'click-tracker-download-button-is-primary': true,
  'enable-new-publish-flow-ai-agent': true,
  'is-displaying-list-contacts-button': true,
  'chatbot-sdk-debugger': false,
  'smart-sales-in-builder': true,
  'create-chatbot': true,
  'active-message-use-report': false,
  'ai-agent-conversational-experience-enabled': false,
  'enable-get-delegation-commands-logging': true,
  'sign-up-with-more-info': true,
  'global-actions': true,
  'show-account-plan': false,
  'undo-redo-block-position': true,
  'is-entry-points-private-replies-enabled': false,
  'action-process-content-assistant': true,
  'tenant-calls-settings': false,
  'chat-new-home': false,
  'copilot-training-queue': false,
  'analytics-data-extractor-sdk-bots': [
    'SDK',
    'integracaostudioteste',
    'chatbotcnutake',
    'vivoprimeprod',
    'miadiana',
    'principalseminovospesados',
    'financiamentoscarteirizacao',
  ],
  'application-insights-error-tracking': true,
  'use-new-message-template-categories': true,
  'builder-execute-script-v2-action': false,
  'builder-subflow-import-export': true,
  'active-messages-on-contacts-journey': false,
  'show-whatsapp-authenticated-media': false,
  'test-ai-model': true,
  'active-message-credit': false,
  'new-custom-replies-blip-desk': true,
  'whatsapp-hide-status-company-information': false,
  'builder-secret-configuration-enabled': true,
  'click-tracker-restructured-for-session': true,
  'desk-consider-closed-tickets-on-metrics': false,
  'is-identity-hostring-fallback': true,
  'show-whats-app-group-view': false,
  'blip-store-banner-service-page-visibility': true,
  'ads-buying-fakedoor': false,
  'using-custom-report-count-endpoint': false,
  'desk-lead-score-config': false,
  'ai-answers': false,
  'is-moving-entering-leaving-action-enabled': true,
  'download-ticket-history': true,
  'desk-agent-status-report': true,
  'show-ai-home-view': false,
  'team-column-open-ticket-monitoring': true,
  'attendant-recurrent-inactivity-alert': true,
  'user-profile-on-first-login': false,
  'desk-payment-link-permission': true,
  'mixpanel-analytics': false,
  'channel-instagram': true,
  'desk-salesforce-integration': true,
  'cdp-beta': false,
  'compiled-analysis-query': true,
  'filter-tickets-history-by-like-name-customer': false,
  'luis-region-parameter': true,
  'desk-active-message-multiple-routers': false,
  'channel-blipchat-show-external-medias': true,
  'undo-redo-add-remove-multiple-blocks': false,
  'is-to-show-in-processing-active-campaign-listing': true,
  'insight-extractor-response': [
    {
      SubscriptionEndDate: '2022-01-05',
      SubscriptionStartDate: '2022-01-03',
      CreatedDate: '2022-01-01',
      MetaData: 'texto não obrigatório',
      OwnerIdentity: 'bancopanrouterprd@msging.net',
    },
    {
      OwnerIdentity: 'gisav3principalproducao@msging.net',
      SubscriptionEndDate: '2022-01-05',
      SubscriptionStartDate: '2022-01-03',
      CreatedDate: '2022-01-01',
      MetaData: 'texto não obrigatório',
    },
    {
      MetaData: 'texto não obrigatório',
      OwnerIdentity: 'routerwhatsapp2@msging.net',
      SubscriptionEndDate: '2022-01-05',
      SubscriptionStartDate: '2022-01-03',
      CreatedDate: '2022-01-01',
    },
    {
      MetaData: 'texto não obrigatório',
      OwnerIdentity: 'aaaaaaaaaaaaaaaa',
      SubscriptionEndDate: '2022-01-05',
      SubscriptionStartDate: '2022-01-03',
      CreatedDate: '2022-01-01',
    },
  ],
  'tenant-consumption': false,
  'whatsapp-is-on-premise': false,
  'marketplace-template-visibility': false,
  'active-message-international-phone-number': true,
  'feature-test': false,
  'undo-redo-global': true,
  'bcom-show-smart-sales-in-menu': false,
  'monitoring-filters': true,
  'undo-redo-outputs': true,
  'builder-add-output-conditions-on-content-change': true,
  'builder-redirect-out-of-attendance-hour': true,
  'state-expiration': true,
  'active-messages-permission': true,
  'search-logs': true,
  'ai-agents-settings-screen-v-2': true,
  'ads-buying-flow-redirect': true,
  'care-copilot-permissions': true,
  'is-showing-data-extractor': true,
  'disable-auto-refresh-monitoring': false,
  'widget-blip-chat-help-center': true,
  'monitoring-item-per-page': 500,
  'builder-search': false,
  'technical-debt-complete-intention-parameter': true,
  'dashbot-analytics-integration': true,
  'undo-redo-actions': true,
  'use-threads-domain-on-contact-history': true,
  'ticket-export-download': true,
  'analytics-disable-statistics-endpoint': false,
  'showing-contacts-last-interaction': true,
  'dashboard-fullscreen-mode': true,
  'enable-data-extractor-frame-on-report': true,
  'use-message-template-authentication-category': true,
  'is-hiding-analytics-tabs': false,
  'active-messages-per-domain-table': true,
  'is-displaying-dashboard-analytics-channel-section-csv-download': false,
  'analytics-enable-sentry': false,
  'builder-simplified-desk-state': false,
  'desk-general-settings-mfe': true,
  'analytics-tenantCRM-showing-menu-button': false,
  'builder-local-debug-hosting': false,
  'desk-queue-management-mfe': false,
  'payer-account-tab': false,
  'iframe-banner-marketing-login': 'undefined',
  'is-enable-redirect-screen-refactor-in-active-campaign': true,
  'ai-track-provider-connection-error': true,
  'builder-new-desk-state-box-enabled': true,
  'new-campaingn-listing-active-message': true,
  'chatbot-debugger-view': true,
  'display-upgrade-desk-version': true,
  'analytics-is-hiding-event-track-label-and-value-blip-portal': true,
  'portal-fragment-onboarding-sentry-configuration': { isEnabled: false },
  'use-tunnel-owner-context': true,
  'customer-inactivity-as-default-initial-date': '',
  'message-template-video': true,
  'active-message-any-flux': true,
  'channels-using-configurations-service-wa': true,
  'cdp-crm-integration': false,
  'shared-view-create-buttons': true,
  new_team_members: true,
  'chat-send-multiple-files': false,
  'instability-banner': false,
  'new-enhancement-screen': true,
  'channel-infobip': false,
  'channels-using-configurations-service-telegram': true,
  'click-tracker-conversion-api': true,
  'auth-application-server': true,
  'call-recording-transcription': false,
  'blip-store-extension-free-trial': false,
  'active-campaign-attendance-redirect': true,
  'click-tracker-enable-download-button': true,
  'analytics-sentry-configuration': { isEnabled: false },
  'show-add-contract': false,
  'blip-chat-widget': false,
  'cdp-open-ai-segmentation': true,
  'monitoring-filter-attendant-status-attendant': false,
  'widget-blip-chat-help-center-monitoring': true,
  'create-bot-on-iris': true,
  'message-before-auto-closed': true,
  'transact-show-sales-tools-in-menu': false,
  'builder-execute-script-v2': false,
  'portal-fragment-ai-sentry-configuration': { isEnabled: false },
  'billing-agent-consumption': false,
  'rollback-builder-version': true,
  'analytics-chatbase-integration': false,
  'blip-profiler-header-button': false,
  'analytics-disable-redundant-consumers': true,
  'is-builder-improved-copy-paste-feature-enabled': true,
  'undo-redo-block-tag': true,
  'use-footer-with-blip-status': false,
  'message-template-marketing-categories': false,
  'is-hiding-home-metrics': true,
  'channel-filter-alert': false,
  'is-showing-custom-headers-on-webhooks': true,
  'is-displaying-dashboard-fixed-period-chips': true,
  'can-connect-blu-provider': true,
  'filter-customer-on-demand-typing': true,
  'agent-response-time-alert': true,
  'click-tracker-news': false,
  'use-sanitize-access-key-in-bot': false,
  'tenant-agent-summary-billing': true,
  'allow-ai-agent-access-default-screens': false,
  'mexico-promotion': false,
  'manager-attendance-rules': true,
  'whatsapp-coexistence-enabled': false,
  'blip-calls-dashboard': true,
  'builder-desk-survey': true,
  'new-onboarding-custom-ai': false,
  'is-showing-dispatchTypes-settings': true,
  'ai-agent-show-test-button': false,
  'desk-agent-paginator': false,
  'transact-show-payment-in-menu': false,
  'builder-microfront-varlibrary': false,
  'enable-email-registration-instagram': false,
  'can-connect-speech-provider': true,
  'tenant-agent-confirm-disabled': false,
  'allow-select-time-zone': true,
  'hide-rules-attendants': false,
  'teste-flag': false,
  'new-flow-and-id-listing-active-campaign': true,
  'tenant-auto-create': false,
  'disable-get-replied-message-by-id': true,
  'desk-attendant-mfe': false,
  'billing-session-consumption-by-bot': true,
  'click-tracker-enable-oauth': true,
  'blip-chat-show-audio-record-configuration': true,
  'enable-recurrent-notification-messages-in-scheduler': true,
  'onboarding-steps-on-builder': false,
  'concept-of-mau': true,
  'is-showing-gooddata': false,
  'enable-sentry-for-blip-status-mfe': false,
  'proactive-messages-dashboard': false,
  'builder-merge-tunnel-contact-desk-state': true,
  'batch-active-message': true,
  'builder-cassandra-insertion-enable': false,
  'whatsapp-skip-validate-ticket': false,
  'rd-station-o-auth': true,
  'channels-using-configurations-service-businesschat': true,
  'active-message-settings-validation': true,
  'use-new-message-attachment-route': false,
  'is-displaying-conversational-flow-section': true,
  'onboarding-sidebar-visibility': false,
  'http-transport-integration': true,
  'audio-transcription-copilot': false,
  'use-builder-left-sidebar-mfe': false,
  'record-audio': true,
  'widget-blip-chat-help-center-builder': true,
  'billing-unlimited-agent-consumption': false,
  'onboarding-steps-on-builder-trackings': false,
  'is-validating-router-cycle': false,
  'ads-buying-enable-marketing-messages-ads': false,
  'wait-user-input': true,
  'enable-download-report-in-scheduler': true,
  'ads-buying-flow-routing': false,
  'blip-store-visibility': true,
  'send-command': true,
};
