import { Faq } from '../types/Faq';

export const convertToFaqMarkdown = async (faqArray: Faq[]) => {
  let markdown = '# FAQ :\n\n';
  markdown += '| Pergunta | Resposta | Palavras-chave |\n';
  markdown += '| ------ | ------ | ------ |\n';

  faqArray.forEach((item: any) => {
    markdown += `| ${item.question} | ${item.answer} | |\n`;
  });

  return markdown;
};
