// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-size {
  height: 59.0625rem;
}

.help-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/PrivateReply/PrivateReplyContainer/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,SAAA;EACA,WAAA;AACF","sourcesContent":[".p-size{\n  height: 59.0625rem;\n}\n\n.help-banner {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
