import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsPaper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { DELETE_CONFIRM_QUESTION_FAQ } from 'libs/trackingEvents';
import React, { useState } from 'react';
import { Faq } from 'types/Faq';
import './style.scss';

function FaqItem({
  areChangesAllowed,
  faq,
  editCallback,
  deleteCallback,
}: {
  areChangesAllowed: boolean;
  faq: Faq;
  editCallback: (faq: Faq) => void;
  deleteCallback: (faq: Faq) => Promise<void>;
}) {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { createToastSuccess, createToastError } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { track } = useTrack();

  const deleteFaq = async () => {
    try {
      setIsLoading(true);
      await deleteCallback(faq);
      track(DELETE_CONFIRM_QUESTION_FAQ, {
        questionId: faq.id,
        status: 'Success',
      });
      setIsLoading(false);
      setShowAlertModal(false);
      createToastSuccess('Pergunta apagada com sucesso!');
    } catch (error) {
      const err = error as Error;
      track(DELETE_CONFIRM_QUESTION_FAQ, {
        questionId: faq.id,
        status: 'Error',
        error: err.message,
      });
      createToastError('Não foi possível apagar a pergunta.', err.message);
    }
  };

  return (
    <>
      <BdsPaper className="faq-item" elevation="none">
        <BdsGrid padding="2" alignItems="center" justifyContent="space-between">
          <BdsGrid direction="column" lg="8" md="8" xg="8">
            <BdsTypo bold="bold" margin={false}>
              {faq.question}
            </BdsTypo>
            <BdsTypo variant="fs-14">R: {faq.answer}</BdsTypo>
          </BdsGrid>
          <BdsGrid>
            <BdsButton
              variant="secondary"
              onBdsClick={() => editCallback(faq)}
              disabled={!areChangesAllowed}
              icon="edit"
            >
              Editar
            </BdsButton>
            <BdsButton
              onBdsClick={() => setShowAlertModal(true)}
              disabled={!areChangesAllowed}
              icon="trash"
              variant="secondary"
            >
              Excluir
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <BdsAlert open={showAlertModal}>
        <BdsAlertHeader variant="warning" icon="warning">
          Deseja excluir o assunto?
        </BdsAlertHeader>
        <BdsAlertBody>Ele não poderá ser recuperada após ser excluída.</BdsAlertBody>
        <BdsAlertActions>
          <BdsButton onClick={() => setShowAlertModal(false)} variant="secondary">
            Cancelar
          </BdsButton>
          <BdsButton bdsLoading={isLoading} onClick={deleteFaq} variant="secondary">
            Excluir
          </BdsButton>
        </BdsAlertActions>
      </BdsAlert>
    </>
  );
}

export default FaqItem;
