// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  max-width: 19rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FaqWizard/FaqWizardInput/FaqInputNew/FaqInputContinueModal/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".modal-content {\r\n  max-width: 19rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
