import { AppBar, Toolbar } from '@mui/material';
import { BdsChipTag, BdsGrid, BdsIcon } from 'blip-ds/dist/blip-ds-react/components';
import React, { useState } from 'react';
import './style.scss';
import MobileDrawer from 'components/MobileDrawer';
import blipLogo from 'assets/images/blip-logo-text.svg';
import MaterialButton from 'components/UI/MaterialButton';

const MobileToolbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <BdsGrid class="mobile-drawer-container">
      <AppBar position="fixed" elevation={0}>
        <Toolbar className="app-top-bar">
          <BdsGrid justifyContent="space-between" style={{ width: '100%' }}>
            <BdsGrid gap="1" alignItems="center">
              <BdsIcon name="blip-chat" type="logo" size="xx-large" />
              <img src={blipLogo} alt="Blip Logo" />
              <BdsChipTag color="outline">COEX</BdsChipTag>
            </BdsGrid>

            <MaterialButton
              onClickCallback={toggleDrawer(!isDrawerOpen)}
              text="Menu"
              startIconBdsName={`${isDrawerOpen ? 'close' : 'menu-hamburger'}`}
              variant="secondary"
            />
          </BdsGrid>
        </Toolbar>
      </AppBar>
      <MobileDrawer isDrawerOpen={isDrawerOpen} onClose={toggleDrawer(false)} />
    </BdsGrid>
  );
};

export default MobileToolbar;
