import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import useTrack from 'hooks/useTrack';
import { TEST_ASSISTANT_OPENED_POPUP, TEST_ASSISTANT_ANSWERED_POPUP } from 'libs/trackingEvents';
import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import usePlgApi from 'hooks/usePlgApi';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { BotStatus } from 'types/Bot';

function ModalTesterAssistant() {
  const navigate = useNavigate();
  const { botConfiguration } = useAppContext();
  const { track } = useTrack();
  const { setBotStatus } = usePlgApi();
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

  useEffect(() => {
    track(TEST_ASSISTANT_OPENED_POPUP);
  }, []);

  const handleIconClick = (iconName: string) => {
    setSelectedIcon(iconName === selectedIcon ? null : iconName);
  };

  const closeTester = async () => {
    if (selectedIcon) {
      const answer = selectedIcon == 'like' ? 'sim' : 'não';
      track(TEST_ASSISTANT_ANSWERED_POPUP, { answer: answer });
      {
        if (botConfiguration.status < BotStatus.Tested) {
          await setBotStatus(BotStatus.Tested);
        }
      }
      navigate('/dashboard');
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-wrapper"
    >
      <BdsGrid className="tester-modal-content" direction="column" gap="3">
        <BdsTypo className="modal-title" id="modal-modal-title" variant="fs-16" tag="h1" margin={true} bold="bold">
          Teste Finalizado
        </BdsTypo>
        <BdsTypo className="modal-description" id="modal-modal-description" variant="fs-14" tag="h1" margin={true}>
          Este teste te ajudou a entender como o BlipAssist funciona?
        </BdsTypo>
        <BdsGrid className="modal-actions">
          <BdsGrid
            className={`icon-text-container ${selectedIcon === 'like' ? 'selected' : ''}`}
            onClick={() => handleIconClick('like')}
          >
            <BdsIcon name="like" />
            <BdsTypo variant="fs-14" className="bds-text">
              Sim
            </BdsTypo>
          </BdsGrid>
          <BdsGrid
            className={`icon-text-container ${selectedIcon === 'dislike' ? 'selected' : ''}`}
            onClick={() => handleIconClick('dislike')}
          >
            <BdsIcon name="deslike" />
            <BdsTypo variant="fs-14" className="bds-text">
              Não
            </BdsTypo>
          </BdsGrid>
        </BdsGrid>
        <MaterialButton text={'Enviar'} variant="tertiary" onClickCallback={() => closeTester()} />
      </BdsGrid>
    </Modal>
  );
}

export default ModalTesterAssistant;
