import { BdsButton, BdsGrid, BdsInput, BdsInputPhoneNumber, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MaterialButton from 'components/UI/MaterialButton';
import useTrack from 'hooks/useTrack';
import { COEX_NEW_WPP_MESSAGE, COEX_NEW_WPP_MESSAGE_OPEN_TAB } from 'libs/trackingEvents';
import React, { useState } from 'react';

function NewWppMessageButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { track } = useTrack();

  const handleButtonClick = () => {
    track(COEX_NEW_WPP_MESSAGE);
    setIsModalOpen(true);
  };

  return (
    <div>
      <BdsButton variant="outline" color="content" icon="plus" onBdsClick={handleButtonClick}>
        Nova conversa
      </BdsButton>
      <NewWppMessageModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
}

type PhoneNumberEvent = {
  detail: { code: string; value: string };
};

type NewWppMessageModalProps = {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

const NewWppMessageModal = ({ isOpen, setIsModalOpen }: NewWppMessageModalProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const { track } = useTrack();

  const handleSetPhoneNumber = (ev: PhoneNumberEvent) => setPhoneNumber(`${ev.detail.code}${ev.detail.value}`);

  const handleButtonClick = () => {
    track(COEX_NEW_WPP_MESSAGE_OPEN_TAB);
    const messageInput = document.getElementById('message-input') as HTMLInputElement;
    const message = messageInput?.value;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message ?? '')}`;
    open(url, '_blank', 'noopener,noreferrer');
    setIsModalOpen(false);
  };

  return (
    <BdsModal
      size="dynamic"
      open={isOpen}
      onBdsModalChanged={event => setIsModalOpen(event.detail.modalStatus === 'opened')}
    >
      <BdsGrid gap="4" direction="column" height="100%" justifyContent="space-between">
        <BdsGrid gap="1" direction="column">
          <BdsTypo margin={false} variant="fs-24" tag="h3" bold="bold">
            Comece uma nova conversa
          </BdsTypo>
          <BdsTypo variant="fs-14" tag="p">
            Envie mensagem para qualquer contato sem adicionar na agenda.
          </BdsTypo>
        </BdsGrid>

        <BdsGrid direction="column" gap="2">
          <BdsInputPhoneNumber
            data-testid="whatsapp-input"
            onBdsPhoneNumberChange={ev => handleSetPhoneNumber(ev)}
            required
            label="WhatsApp"
          />
          <BdsInput
            data-testid="message-input"
            rows={3}
            isTextarea
            id="message-input"
            label="Mensagem"
            placeholder="Digite a mensagem que será enviada"
          />
        </BdsGrid>

        <MaterialButton
          text="Começar nova conversa"
          disabled={!phoneNumber}
          onClickCallback={handleButtonClick}
          endIconBdsName="arrow-right"
          isMobileView
        />
      </BdsGrid>
    </BdsModal>
  );
};

export default NewWppMessageButton;
