import { BdsGrid, BdsCheckbox, BdsInput } from 'blip-ds/dist/blip-ds-react/components';
import FaqChatBubble from 'components/FaqWizard/FaqChatBubble';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import React, { useEffect, useRef, useState } from 'react';
import { Faq, WizardTempFaq } from 'types/Faq';

type FaqInputPaymentOptionsProps = {
  tempFaq: WizardTempFaq | null;
  onNextClickCallback: (faq: WizardTempFaq | null) => void;
};

function FaqInputPaymentOptions({ tempFaq, onNextClickCallback }: FaqInputPaymentOptionsProps) {
  const isMobileView = useMobileView();
  const [isFaqValid, setIsFaqValid] = useState(false);
  const inputAnswerRef = useRef<HTMLBdsInputElement>(null);
  const [dontApply, setDontApply] = useState(false);

  const handleCheckboxChange = (event: any) => {
    const checked = event.target.checked;
    if (checked) {
      setIsFaqValid(true);
      inputAnswerRef.current?.clear();
    } else {
      setIsFaqValid(false);
    }
    setDontApply(event.target.checked);
  };

  const handleNextClick = () => {
    const faq = {
      id: crypto.randomUUID(),
      question: 'Quais são as formas de pagamento?',
      answer: inputAnswerRef.current?.value,
    } as Faq;

    if (dontApply) {
      onNextClickCallback({ faq: faq, doNotApply: true } as WizardTempFaq);
      return;
    }

    const newTempFaq = { faq } as WizardTempFaq;
    onNextClickCallback(newTempFaq);
  };

  const validateInputs = async () => {
    const isValid = await inputAnswerRef.current?.isValid();
    setIsFaqValid(isValid || false);
  };

  useEffect(() => {
    setIsFaqValid(!!tempFaq?.faq?.answer);
  }, [tempFaq]);

  return (
    <>
      <FaqChatBubble
        contentHTML={'O que você responde quando seus clientes te perguntam sobre <b>formas de pagamento</b>?'}
      />
      <BdsGrid padding="l-1" xxs="12">
        <BdsInput
          id="answer-input"
          ref={inputAnswerRef}
          onBdsChange={validateInputs}
          isTextarea
          required
          counterLength
          rows={2}
          maxlength={300}
          placeholder="Digite aqui o que você geralmente responde sobre formas de pagamento."
          requiredErrorMessage="Este campo é obrigatório"
          disabled={dontApply}
        />
      </BdsGrid>
      <BdsGrid padding="l-1" xxs="12">
        <BdsCheckbox
          onBdsChange={handleCheckboxChange}
          id="chkDonotApply"
          label="Não se aplica"
          name="chkDonotApply"
          refer="chkDonotApply"
        />
      </BdsGrid>
      <BdsGrid xxs="12" margin="t-4" gap="2" direction="column-reverse">
        <MaterialButton
          onClickCallback={handleNextClick}
          text="Confirmar e continuar"
          variant="primary"
          endIconBdsName="arrow-right"
          isMobileView={isMobileView}
          disabled={!isFaqValid}
        />
      </BdsGrid>
    </>
  );
}

export default FaqInputPaymentOptions;
