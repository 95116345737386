type ChannelRegister = {
  name: string;
  value: string;
};

export type BotChannels = {
  instagram?: ChannelRegister[];
  wa?: ChannelRegister[];
};

export enum Channel {
  None,
  Instagram,
  WhatsApp,
}

export enum ChannelStatus {
  None,
  Active,
  Paused,
  Disconnected,
}

export type ChannelConfiguration = {
  botId: string;
  channel: Channel;
  status: ChannelStatus;
  externalSettings: string;
};
