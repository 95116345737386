import { BdsBanner, BdsGrid, BdsLoadingSpinner, BdsSelect } from 'blip-ds/dist/blip-ds-react';
import useBlipGoApi from 'hooks/useBlipGoApi';
import { DEFAULT_CONTACT_GROUPS } from 'libs/contacts';
import React, { useState } from 'react';
import './style.scss';
import { COEX_ACTIVE_MESSAGE_AUDIENCE_GROUP } from 'libs/trackingEvents';
import useTrack from 'hooks/useTrack';
import { AudienceAndVariables } from 'types/ActiveMessage';

interface ICampaignGroupsSelector {
  onGroupSelection: (audience: AudienceAndVariables[], audienceGroup: string) => void;
}

function CampaignGroupsSelector({ onGroupSelection }: Readonly<ICampaignGroupsSelector>) {
  const [contactsError, setContactsError] = useState('');
  const [loadingContacts, setLoadingContacts] = useState(false);
  const { getAllContacts } = useBlipGoApi();
  const { track } = useTrack();

  const parsePhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber.startsWith('+')) {
      return `+${phoneNumber}`;
    }
    return phoneNumber;
  };

  const handleOptionChange = async (ev: CustomEvent) => {
    onGroupSelection([], ev.detail.value);
    setLoadingContacts(true);
    setContactsError('');
    try {
      const contacts = await getAllContacts('extras', ev.detail.value);

      if (contacts.length > 0) {
        const formattedAudience = contacts.map(contact => {
          return {
            telefone: parsePhoneNumber(contact.phoneNumber),
          };
        });
        onGroupSelection(formattedAudience, ev.detail.value);
      } else {
        setContactsError('O grupo selecionado está vazio. Escolha outro ou gerencie os grupos na área de contatos.');
      }
    } catch {
      setContactsError('Houve um erro ao carregar seus contatos. Por favor, tente novamente.');
    } finally {
      setLoadingContacts(false);
    }
  };

  return (
    <BdsGrid direction="column" gap="3">
      <BdsGrid gap="2" alignItems="center">
        <BdsSelect
          className="campaign-group-selector"
          label="Seus grupos"
          placeholder="Selecione um grupo de contato"
          onClick={() => track(COEX_ACTIVE_MESSAGE_AUDIENCE_GROUP)}
          onBdsChange={handleOptionChange}
          options={DEFAULT_CONTACT_GROUPS}
        ></BdsSelect>
        {loadingContacts && <BdsLoadingSpinner size="small" />}
      </BdsGrid>

      {contactsError && <BdsBanner variant="error">{contactsError}</BdsBanner>}
    </BdsGrid>
  );
}

export default CampaignGroupsSelector;
