import { BdsBanner, BdsButton, BdsGrid, BdsIcon, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import BotAvatar from 'components/FaqWizard/BotAvatar';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import {
  COEX_ACTIVATE_ASSISTANT,
  COEX_KEEP_ASSISTANT_DEACTIVATED,
  COEX_TEST_ASSISTANT,
  FAQ_NEWFAQ_CONTINUE_ANSWER,
} from 'libs/trackingEvents';
import React, { Ref, forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BotStatus } from 'types/Bot';
import './style.scss';

const FaqInputContinueModal = forwardRef(function FaqInputContinueModal(
  props: { amountQuestions: number },
  ref: Ref<HTMLBdsModalElement>,
) {
  const [isFirstStep, setIsFirstStep] = useState(true);

  const toggleModal = () => {
    const modal = document.getElementById('continue-add-modal') as HTMLBdsModalElement;
    modal?.toggle?.();
  };

  return (
    <BdsModal id="continue-add-modal" closeButton={false} ref={ref} size="dynamic">
      <BdsGrid direction="column" padding="2">
        {isFirstStep ? (
          <AddNewFaqQuestion
            toggleModalCallback={toggleModal}
            faqAmount={props.amountQuestions}
            nextStepCallback={() => setIsFirstStep(false)}
          />
        ) : (
          <ActivateAssistantQuestion toggleModalCallback={toggleModal} faqAmount={props.amountQuestions} />
        )}
      </BdsGrid>
    </BdsModal>
  );
});

type AddNewFaqQuestionProps = {
  faqAmount: number;
  toggleModalCallback: () => void;
  nextStepCallback: () => void;
};

const AddNewFaqQuestion = ({ faqAmount, toggleModalCallback, nextStepCallback }: AddNewFaqQuestionProps) => {
  const { track } = useTrack();

  const handleOnAddMoreClick = () => {
    track(FAQ_NEWFAQ_CONTINUE_ANSWER, {
      amountQuestions: faqAmount,
      option: 'ADICIONAR_NOVO',
      status: 'success',
    });

    toggleModalCallback();
  };

  return (
    <BdsGrid className="modal-content" direction="column" gap="2">
      <BdsGrid direction="row" gap="1">
        <BdsIcon name="like" type="icon" />
        <BdsTypo variant="fs-14" bold="bold" lineHeight="double">
          Informação foi salva com sucesso!
        </BdsTypo>
      </BdsGrid>
      <BdsTypo variant="fs-16" bold="bold">
        Como você prefere prosseguir?
      </BdsTypo>
      <BdsGrid xxs="12" margin="t-2" gap="1" direction="column">
        <MaterialButton
          onClickCallback={handleOnAddMoreClick}
          text="Adicionar novo assunto"
          variant="primary"
          startIconBdsName="add"
          isMobileView={true}
        />
        <MaterialButton
          onClickCallback={nextStepCallback}
          text="Finalizar"
          variant="tertiary"
          startIconBdsName="checkball"
          isMobileView={true}
        />
      </BdsGrid>
      <BdsBanner variant="system">Você poderá adicionar ou editar assuntos mais tarde.</BdsBanner>
    </BdsGrid>
  );
};

type ActivateAssistantQuestionProps = {
  faqAmount: number;
  toggleModalCallback: () => void;
};

const ActivateAssistantQuestion = ({ faqAmount, toggleModalCallback }: ActivateAssistantQuestionProps) => {
  const { track } = useTrack();
  const navigate = useNavigate();
  const { installation } = useAppContext();
  const { setBotStatus } = usePlgApi();
  const { createToastSuccess, createToastError } = useToast();

  const blipChatKey = installation.routerBlipChatAppKey;

  const handleOnEndClick = () => {
    track(FAQ_NEWFAQ_CONTINUE_ANSWER, {
      amountQuestions: faqAmount,
      option: 'FINALIZAR',
      status: 'success',
    });
    track(COEX_KEEP_ASSISTANT_DEACTIVATED);
    toggleModalCallback();
    navigate('/dashboard');
  };

  const handleTestClick = () => {
    track(COEX_TEST_ASSISTANT);
    const url = `https://${process.env.REACT_APP_PACK_TENANT}.${process.env.REACT_APP_BLIPCHAT_SUFFIX}.blip.ai?appKey=${blipChatKey}`;
    open(url, '_blank', 'noopener,noreferrer');
  };

  const handleActivateNow = async () => {
    try {
      await setBotStatus(BotStatus.Active);
      createToastSuccess(
        'Seu assistente foi ativado com sucesso!',
        'Ele já pode começar a interagir com novos clientes.',
      );
      track(COEX_ACTIVATE_ASSISTANT, { status: 'success' });
    } catch (error) {
      createToastError('Erro ao ativar o assistente', 'Tente novamente mais tarde ou entre em contato com o suporte.');
      track(COEX_ACTIVATE_ASSISTANT, { status: 'error', error });
    } finally {
      toggleModalCallback();
      navigate('/dashboard');
    }
  };

  return (
    <BdsGrid direction="column" gap="2">
      <BdsGrid padding="1" className="gray-section" justifyContent="space-between" alignItems="center">
        <BdsGrid>
          <BotAvatar />
          <BdsGrid direction="column" padding="x-1" margin="r-2">
            <BdsTypo variant="fs-16" bold="bold" tag="h4">
              Assistente Virtual
            </BdsTypo>
            <BdsTypo variant="fs-14" color="#454545">
              {faqAmount} {faqAmount > 1 ? 'assuntos cadastrados' : 'assunto cadastrado'}
            </BdsTypo>
          </BdsGrid>
        </BdsGrid>
        <BdsButton onBdsClick={handleTestClick} icon="builder-test-bot" variant="text" color="content">
          Testar
        </BdsButton>
      </BdsGrid>
      <BdsTypo bold="bold">Deseja ativar seu assistente virtual agora?</BdsTypo>
      <MaterialButton
        variant="primary"
        startIconBdsName="builder-publish-bot"
        text="Ativar assistente agora"
        onClickCallback={handleActivateNow}
      />
      <MaterialButton variant="tertiary" text="Manter desativado" onClickCallback={handleOnEndClick} />
      <BdsBanner variant="system">Você poderá ativar ou desativar a qualquer momento.</BdsBanner>
    </BdsGrid>
  );
};

export default FaqInputContinueModal;
