import { BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import './styles.scss';
import MaterialButton from 'components/UI/MaterialButton';

interface IQuickAccessCardProps {
  actionText: string;
  description: string;
  icon: string;
  onBtnClick: () => void;
  title: string;
}

function QuickAccessCard({ actionText, description, icon, onBtnClick, title }: Readonly<IQuickAccessCardProps>) {
  return (
    <BdsPaper elevation="none" class="paper-pattern">
      <BdsGrid direction="column" gap="2" justifyContent="space-between" height="18rem" padding="3">
        <BdsGrid direction="column" gap="2">
          <BdsIcon name={icon} size="xxx-large" />
          <BdsTypo variant="fs-20" bold="bold" margin={false}>
            {title}
          </BdsTypo>
          <BdsTypo variant="fs-14">{description}</BdsTypo>
        </BdsGrid>

        <MaterialButton
          onClickCallback={onBtnClick}
          text={actionText}
          startIconBdsName="external-file"
          variant="primary"
          isMobileView
        />
      </BdsGrid>
    </BdsPaper>
  );
}

export default QuickAccessCard;
