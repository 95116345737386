import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import usePlgApi from 'hooks/usePlgApi';
import { useAuth } from 'oidc-react';
import {
  HUBSPOT_COEX_CONTACT_ID_ASSOCIATION,
  HUBSPOT_COEX_DEAL_DEFAULT_PARAMS,
  HUBSPOT_COEX_ITEM_LINE_ASSOCIATION,
} from 'libs/hubspotParameters';
import useToast from 'hooks/useToast';
import { ILogArgs, logger } from 'packs-template-baseweb';
import { generalErrorHandler } from '/utils/generalErrorHandler';

const logArgs: ILogArgs = {
  className: 'AsyncPreparation',
};

function AsyncPreparation() {
  const navigate = useNavigate();
  const { botConfiguration } = useAppContext();
  const {
    getSubscriptionByEmail,
    setHubspotDeal,
    setSubscription,
    getHubspotContactByProperty,
    setHubspotContact,
    searchHubspotDeal,
  } = usePlgApi();
  const { createToastError } = useToast();
  const userEmail = useAuth().userData?.profile.email as string;
  const userName = useAuth().userData?.profile.FullName as string;

  useEffect(() => {
    if (botConfiguration?.botId) {
      return navigate('/dashboard');
    } else {
      checkSubscription();
    }
  }, []);

  const checkSubscription = async () => {
    logArgs.methodName = 'checkSubscription';
    try {
      const res = await getSubscriptionByEmail(userEmail);

      if (!res) {
        await createSubscription();
        return;
      }

      if (res?.subscriptionPlan) {
        navigate('/onboarding/installing');
      } else if (res?.cnpj) {
        navigate('/onboarding/terms');
      } else {
        navigate('/onboarding/company-form');
      }
    } catch (error) {
      generalErrorHandler(error, logArgs);
      createToastError('Erro ao buscar informações do assistente', 'Por favor, tente novamente mais tarde');
    }
  };

  const createContact = async () => {
    try {
      const res = await getHubspotContactByProperty('email', userEmail);
      return res.id;
    } catch (error) {
      const err = error as Error;

      if (err?.message.includes('404')) {
        try {
          const res = await setHubspotContact({
            properties: {
              firstname: userName,
              email: userEmail,
            },
          });
          return res.id;
        } catch (err) {
          createToastError('Erro ao criar informações do assistente', 'Por favor, tente novamente mais tarde');
        }
      } else {
        createToastError('Erro ao buscar informações do assistente', 'Por favor, tente novamente mais tarde');
      }
    }
  };

  const createDeal = async (contactId: number) => {
    try {
      const searchResponse = await searchHubspotDeal(String(contactId));
      if (searchResponse.results.length > 0) {
        const deal = searchResponse.results.shift();
        return deal.id;
      } else {
        const createDealResponse = await setHubspotDeal({
          name: userName,
          ...HUBSPOT_COEX_DEAL_DEFAULT_PARAMS,
          associations: [HUBSPOT_COEX_ITEM_LINE_ASSOCIATION, HUBSPOT_COEX_CONTACT_ID_ASSOCIATION(contactId)],
        });
        return createDealResponse.dealId;
      }
    } catch (error) {
      createToastError('Erro ao criar informações do assistente', 'Por favor, tente novamente mais tarde');
    }
  };

  const createSubscription = async () => {
    logArgs.methodName = 'createSubscription';
    try {
      // TODO: remove comments when the hubspot integration goes live
      /* const companyId = await createContact();
      const dealId = await createDeal(Number(companyId)); */

      await setSubscription({
        email: userEmail,
        //dealId: String(dealId),
        name: userName,
        //companyId,
      });

      navigate('/onboarding/company-form');
    } catch (error) {
      generalErrorHandler(error, logArgs);
      createToastError('Erro ao salvar informações do assistente', 'Por favor, tente novamente mais tarde');
    }
  };

  return <BdsLoadingPage />;
}

export default AsyncPreparation;
