import { useAppContext } from 'contexts/AppContext';

import {
  CREATE_MESSAGE_TEMPLATES_ROUTE,
  DISPATCH_WHATSAPP_CAMPAIGN_ROUTE,
  GET_CAMPAIGNS_AUDIENCE,
  GET_CONTACTS,
  GET_MESSAGE_TEMPLATES_ROUTE,
  GET_ROUTER_SKILLS_ROUTE,
  GET_WABAS_ROUTE,
  SET_STATE_CONFIGURATIONS,
  UPDATE_CONTACT,
} from 'libs/goApiRoutes';
import { ILogArgs } from 'packs-template-baseweb';
import { AudienceAndVariables, MessageCampaignBody, Template } from 'types/ActiveMessage';
import { RouterSkill } from 'types/Bot';
import { Contact, ContactsData, UpdateContact } from 'types/Contact';
import { WhatsappConnectionConfig } from 'types/Whatsapp';
import { RequestErrorBoundary } from 'utils/request';

const BLIPGO_API_ADMIN_USER = process.env.REACT_APP_BLIPGO_ADMIN_USER;
const BLIPGO_API_ADMIN_PASSWORD = process.env.REACT_APP_BLIPGO_ADMIN_PASSWORD;

const LOGS_ARGS: ILogArgs = {
  className: 'useBlipGoApi',
};

const BOT_FLOW_ID_PRD = '16bcaecd-60e0-46eb-8069-ac6509839569';
const BOT_STATE_ID_PRD_HUMAN = 'f50f9be0-5fd8-4ded-9ae0-13752896e2bb';

const useBlipGoApi = () => {
  const { botConfiguration } = useAppContext();
  const botId = botConfiguration.botId;
  const authCredentials = `${BLIPGO_API_ADMIN_USER}:${BLIPGO_API_ADMIN_PASSWORD}`;
  const basicAuth = `Basic ${Buffer.from(authCredentials).toString('base64')}`;
  const header = {
    'Content-Type': 'application/json',
    Authorization: basicAuth,
  };

  const getMessagesTemplates = async () => {
    LOGS_ARGS.methodName = 'getMessagesTemplates';
    try {
      const response = await fetch(GET_MESSAGE_TEMPLATES_ROUTE(botId), {
        headers: header,
      });
      const data = await RequestErrorBoundary(response, LOGS_ARGS);
      return data.items as Template[];
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  const createMessageTemplate = async (template: Template) => {
    LOGS_ARGS.methodName = 'createMessageTemplate';
    try {
      const response = await fetch(CREATE_MESSAGE_TEMPLATES_ROUTE(botId), {
        method: 'POST',
        headers: header,
        body: JSON.stringify(template),
      });
      await RequestErrorBoundary(response, LOGS_ARGS);
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  const dispatchWhatsAppCampaign = async (
    campaignName: string,
    audience: AudienceAndVariables[],
    template: Template,
  ) => {
    LOGS_ARGS.methodName = 'dispatchWhatsAppCampaign';
    try {
      const skills = await getRouterSkills();
      if (!skills.length) {
        throw new Error('Bot não encontrado');
      }
      const body: MessageCampaignBody = {
        name: campaignName,
        templateId: template.templateId,
        destinationBot: {
          botId: skills[0].shortName,
          flowId: BOT_FLOW_ID_PRD,
          stateId: BOT_STATE_ID_PRD_HUMAN,
        },
        audiences: audience.map(audienceItem => {
          const { telefone, ...parameters } = audienceItem;
          return {
            phoneNumber: telefone,
            parameters: parameters,
          };
        }),
      };

      const res = await fetch(DISPATCH_WHATSAPP_CAMPAIGN_ROUTE(botId), {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      });
      await RequestErrorBoundary(res, LOGS_ARGS);
    } catch (error) {
      const err = error as Error;
      if (err.message.includes('This name already exists')) {
        return Promise.reject(new Error('Já existe uma campanha com esse nome. Escolha um nome diferente.'));
      }
      return Promise.reject(new Error(err.message));
    }
  };

  const getRouterSkills = async () => {
    LOGS_ARGS.methodName = 'getRouterSkills';
    try {
      const response = await fetch(GET_ROUTER_SKILLS_ROUTE(botId), {
        headers: header,
      });
      const data = await RequestErrorBoundary(response, LOGS_ARGS);
      return data as RouterSkill[];
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  const getWabas = async (metaAccessToken: string) => {
    LOGS_ARGS.methodName = 'getWabas';
    try {
      const response = await fetch(GET_WABAS_ROUTE(botId, metaAccessToken), {
        headers: header,
      });
      const data = await RequestErrorBoundary(response, LOGS_ARGS);

      return data.items.flatMap((waba: any) =>
        waba.phoneNumbers.map((phone: any) => ({
          phoneNumber: phone.number,
          displayName: phone.displayName,
          wabaId: waba.id,
          wabaName: waba.name,
          phoneId: phone.id,
        })),
      );
    } catch (ex) {
      return Promise.reject(new Error(JSON.stringify(ex)));
    }
  };

  const activateNumber = async (whatsappConnectionConfig: WhatsappConnectionConfig) => {
    LOGS_ARGS.methodName = 'activateNumber';
    try {
      const response = await fetch(SET_STATE_CONFIGURATIONS(botId), {
        method: 'POST',
        headers: header,
        body: JSON.stringify(whatsappConnectionConfig),
      });

      return await RequestErrorBoundary(response, LOGS_ARGS);
    } catch (ex) {
      return Promise.reject(new Error(JSON.stringify(ex)));
    }
  };

  const getContacts = async (
    pageNumber?: number,
    pageSize?: number,
    containsField?: string,
    containsValue?: string,
  ): Promise<ContactsData> => {
    LOGS_ARGS.methodName = 'getContacts';
    try {
      const filterParams = containsField ? `&containsField=${containsField}&containsValue=${containsValue}` : '';
      const currentDate = new Date();

      const lastMessageDateStart = new Date(currentDate);
      lastMessageDateStart.setDate(currentDate.getDate() - 90);

      const lastMessageDateEnd = new Date(currentDate);
      lastMessageDateEnd.setDate(currentDate.getDate() + 1);

      const lastMessageDateStartIso = lastMessageDateStart.toISOString();
      const lastMessageDateEndIso = lastMessageDateEnd.toISOString();

      const queryParams = `?LastMessageDateStart=${encodeURIComponent(
        lastMessageDateStartIso,
      )}&LastMessageDateEnd=${encodeURIComponent(lastMessageDateEndIso)}`;

      const pagination = `&PageNumber=${pageNumber}&PageSize=${pageSize}`;

      const response = await fetch(GET_CONTACTS(botId, queryParams, pagination, filterParams), {
        headers: header,
      });
      return await RequestErrorBoundary(response, LOGS_ARGS);
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  const getAllContacts = async (containsField?: string, containsValue?: string): Promise<Contact[]> => {
    const PAGE_SIZE = 100;
    const START_PAGE = 1;
    const contacts: Contact[] = [];
    const contactsData: ContactsData = await getContacts(START_PAGE, PAGE_SIZE, containsField, containsValue);

    contacts.push(...contactsData.items.filter(contact => contact.phoneNumber));

    for (let i = 2; i <= contactsData.totalPages; i++) {
      const pageData: ContactsData = await getContacts(i, PAGE_SIZE, containsField, containsValue);

      contacts.push(...pageData.items.filter(contact => contact.phoneNumber));
    }

    return contacts;
  };

  const getCampaignsAudience = async (pageNumber?: number, pageSize?: number) => {
    LOGS_ARGS.methodName = 'getCampaignAudience';
    const pagination = `&PageNumber=${pageNumber}&PageSize=${pageSize}`;

    try {
      const response = await fetch(GET_CAMPAIGNS_AUDIENCE(botId, pagination), {
        headers: header,
      });

      const data = await RequestErrorBoundary(response, LOGS_ARGS);
      return data;
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  const updateContact = async (contact: UpdateContact) => {
    LOGS_ARGS.methodName = 'updateContact';
    try {
      const response = await fetch(UPDATE_CONTACT(botId), {
        method: 'PATCH',
        headers: header,
        body: JSON.stringify(contact),
      });

      return await RequestErrorBoundary(response, LOGS_ARGS);
    } catch (ex) {
      return Promise.reject(new Error(JSON.stringify(ex)));
    }
  };

  return {
    getMessagesTemplates,
    getRouterSkills,
    createMessageTemplate,
    dispatchWhatsAppCampaign,
    getWabas,
    activateNumber,
    getContacts,
    getCampaignsAudience,
    updateContact,
    getAllContacts,
  };
};

export default useBlipGoApi;
