import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import './style.scss';
import { useMobileView } from 'contexts/MobileViewContext';

type ContactDataProps = {
  title: string;
  subtitle: string;
  bold: 'semi-bold' | 'regular' | 'bold' | 'extra-bold';
};

function ContactDataSection({ title, subtitle, bold }: Readonly<ContactDataProps>) {
  const isMobileView = useMobileView();

  if (isMobileView) {
    return;
  }

  return (
    <BdsGrid direction="column" className="contact-data-section-container">
      <BdsTypo className="title" variant="fs-12">
        {title}
      </BdsTypo>
      <BdsTypo className="subtitle" variant="fs-14" bold={bold}>
        {subtitle}
      </BdsTypo>
    </BdsGrid>
  );
}

export default ContactDataSection;
