import React from 'react';
import { BdsGrid, BdsPaper, BdsRadio, BdsRadioGroup, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';

interface SurveyBoxProps {
  question: string;
  options: { label: string; description: string }[];
  onSelectOption: (question: string, option: string) => void;
  selectedOption: string | undefined;
}

const SurveyBox: React.FC<SurveyBoxProps> = ({ question, options, onSelectOption, selectedOption }) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value;
    onSelectOption(question, selectedOption);
  };

  return (
    <BdsGrid justifyContent="center" direction="column" alignItems="center" gap="2">
      <BdsTypo variant="fs-16" bold="bold">
        {question}
      </BdsTypo>
      <BdsRadioGroup id={`radio-group-${question}`} value={selectedOption} onChange={handleRadioChange as any}>
        <BdsGrid direction="column" gap="2">
          {options.map(option => (
            <BdsPaper border={false} elevation="primary" key={option.label} className="paper-question">
              <BdsGrid direction="column" gap="2" padding="2">
                <BdsGrid direction="row" gap="1" alignItems="flex-start">
                  <BdsRadio data-testid={option.label} value={option.label} checked={selectedOption === option.label} />
                  <BdsTypo variant="fs-16" bold="bold">
                    {option.label}
                  </BdsTypo>
                </BdsGrid>
                <BdsTypo variant="fs-12">{option.description}</BdsTypo>
              </BdsGrid>
            </BdsPaper>
          ))}
        </BdsGrid>
      </BdsRadioGroup>
    </BdsGrid>
  );
};

export default SurveyBox;
