import { BdsGrid, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { CONNECT_INSTAGRAM_CHOOSE_OTHER_PAGE_DOCUMENTATION } from 'libs/trackingEvents';
import React, { Ref, forwardRef } from 'react';
import './style.scss';

const InstagramChooseOtherPageModal = forwardRef(function InstagramChooseOtherPageModal(
  props: {},
  ref: Ref<HTMLBdsModalElement>,
) {
  const { track } = useTrack();
  const isMobileView = useMobileView();

  const openDocumentation = () => {
    track(CONNECT_INSTAGRAM_CHOOSE_OTHER_PAGE_DOCUMENTATION);
    window.open('https://www.facebook.com/help/1148909221857370', '_blank', 'noopener,noreferrer');
  };

  return (
    <BdsModal ref={ref} size="dynamic">
      <BdsGrid direction="column" gap="2" alignItems="center" className="modal">
        <BdsTypo variant="fs-20" bold="bold" lineHeight="plus" margin={false}>
          Para conectar uma outra página do Instagram você precisa desvincular a página atual do Facebook.
        </BdsTypo>
        <BdsTypo variant="fs-14" tag="p">
          Consulte a <u>documentação do Facebook</u> sobre como remover contas do Instagram. Faça todo o processo e
          tente a conexão aqui novamente.
        </BdsTypo>
        <MaterialButton
          onClickCallback={openDocumentation}
          startIconBdsName="facebook"
          text="Ver documentação"
          variant="tertiary"
          isMobileView={isMobileView}
          cssVariant="w-50"
        />
      </BdsGrid>
    </BdsModal>
  );
});

export default InstagramChooseOtherPageModal;
