import { BdsButton, BdsChipTag, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { ReactNode } from 'react';
import WhatsappLogo from 'assets/images/whatsapp-icon.svg';
import './styles.scss';
import { useMobileView } from 'contexts/MobileViewContext';

interface IWhatsappConnectionContainerProps {
  children: ReactNode;
  onClose: () => void;
}

function WhatsappConnectionContainer({ children, onClose }: Readonly<IWhatsappConnectionContainerProps>) {
  const isMobileView = useMobileView();

  return (
    <BdsPaper elevation="none" class="whatsapp-connection-paper">
      <BdsGrid direction="column" gap="3" padding="y-3" lg="12" xs="12">
        <BdsGrid alignItems="center" justifyContent="space-between" padding="x-6">
          <BdsGrid xs="12" lg="9" md="9" gap="3" alignItems="center">
            {!isMobileView && <img className="whatsapp-header-logo" src={WhatsappLogo} alt="whatsapp logo" />}

            <BdsGrid gap="1">
              <BdsTypo variant="fs-20" bold="bold" margin={false}>
                Conecte ao WhatsApp Business
              </BdsTypo>
              {!isMobileView && (
                <BdsChipTag color="warning" icon="warning">
                  Importante
                </BdsChipTag>
              )}
            </BdsGrid>
          </BdsGrid>

          <BdsGrid xs="12" lg="3" md="3" justifyContent="flex-end">
            <BdsButton variant="text" color="content" icon-left="close" onBdsClick={onClose} data-testid="close-btn">
              {!isMobileView && 'Cancelar conexão'}
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
        <BdsGrid padding="x-6">{children}</BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
}

export default WhatsappConnectionContainer;
