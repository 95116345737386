import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import './styles.scss';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_META_CONNECTION_COMPLETED_CLOSE } from 'libs/trackingEvents';
import WhatsapppConectionCard from 'components/WhatsapppConectionCard';

function WhatsappConnectionFinished() {
  const isMobileView = useMobileView();
  const { userData } = useAuth();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleOnClose = () => {
    track(COEX_META_CONNECTION_COMPLETED_CLOSE);
    navigate('/');
  };

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <BdsGrid justifyContent="center">
        <BdsGrid direction="column" class="main-container" gap="3">
          <MainHeader
            title={`Boas vindas, ${userName}`}
            description="Gerencie o seu atendimento e campanhas com o Blip Go Coex!"
          />
          <WhatsapppConectionCard
            description="Você já pode começar a aproveitar todos os recursos que Blip Coex oferece."
            onActionClick={handleOnClose}
            statusName="Ativo"
            statusType="success"
            title="Seu WhatsApp Business foi conectado com sucesso!"
          ></WhatsapppConectionCard>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default WhatsappConnectionFinished;
