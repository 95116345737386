import { BdsBanner, BdsGrid, BdsIcon, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import useTrack from 'hooks/useTrack';
import { FAQ_NEWFAQ_CONTINUE_ANSWER } from 'libs/trackingEvents';
import React, { Ref, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const FaqInputContinueModal = forwardRef(function FaqInputContinueModal(
  props: { onAddMoreCallback: () => void; amountQuestions: number },
  ref: Ref<HTMLBdsModalElement>,
) {
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleOnAddMoreClick = () => {
    track(FAQ_NEWFAQ_CONTINUE_ANSWER, {
      amountQuestions: props.amountQuestions,
      option: 'ADICIONAR_NOVO',
      status: 'success',
    });
    props.onAddMoreCallback();
  };

  const handleOnEndClick = () => {
    track(FAQ_NEWFAQ_CONTINUE_ANSWER, {
      amountQuestions: props.amountQuestions,
      option: 'FINALIZAR',
      status: 'success',
    });
    navigate('/dashboard');
  };

  return (
    <BdsModal id="continue-add-modal" closeButton={false} ref={ref} size="dynamic">
      <BdsGrid class="modal-content" direction="column" gap="2">
        <BdsGrid direction="row" gap="1">
          <BdsIcon name="like" type="icon" />
          <BdsTypo variant="fs-14" bold="bold" lineHeight="double">
            Informação foi salva com sucesso!
          </BdsTypo>
        </BdsGrid>
        <BdsTypo variant="fs-16" bold="bold">
          Como você prefere prosseguir?
        </BdsTypo>
        <BdsGrid xxs="12" margin="t-2" gap="1" direction="column-reverse">
          <MaterialButton
            onClickCallback={handleOnEndClick}
            text="Finalizar"
            variant="secondary"
            startIconBdsName="checkball"
            isMobileView={true}
          ></MaterialButton>
          <MaterialButton
            onClickCallback={handleOnAddMoreClick}
            text="Adicionar novo assunto"
            variant="secondary"
            startIconBdsName="add"
            isMobileView={true}
          ></MaterialButton>
        </BdsGrid>
        <BdsBanner variant="system">Você poderá adicionar ou editar assuntos mais tarde.</BdsBanner>
      </BdsGrid>
    </BdsModal>
  );
});

export default FaqInputContinueModal;
