import React from 'react';
import './style.scss';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react/components';

type GptChatBubbleProps = {
  contentHTML: string;
  sender: 'user' | 'assistant' | 'default';
};

function GptChatBubble({ contentHTML, sender }: GptChatBubbleProps) {
  const bubbleClass =
    sender === 'user'
      ? 'gpt-chat-bubble user'
      : sender === 'assistant'
      ? 'gpt-chat-bubble assistant'
      : 'gpt-chat-bubble default';
  return (
    <div className={bubbleClass} data-testid="chat-bubble">
      <BdsTypo variant="fs-12" lineHeight={sender === 'default' ? 'plus' : 'simple'}>
        <div dangerouslySetInnerHTML={{ __html: contentHTML }}></div>
      </BdsTypo>
    </div>
  );
}

export default GptChatBubble;
