import React from 'react';
import { Helmet } from 'react-helmet';

const prod = process.env.NODE_ENV === 'production';

export function MSClarity() {
  return prod ? (
    <Helmet>
      <script type="text/javascript">
        {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "ijx8x7gn43");
          `}
      </script>
    </Helmet>
  ) : (
    <></>
  );
}
