import {
  BdsGrid,
  BdsIllustration,
  BdsLoadingSpinner,
  BdsPagination,
  BdsPaper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import ContactItem from 'components/ContactItem';
import ExportContactsButton from 'components/ExportContactsButton';
import MainContainer from 'components/MainContainer';
import MainHeader from 'components/MainHeader';
import MobileToolbar from 'components/MobileToolbar';
import NewWppMessageButton from 'components/NewWppMessageButton';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import useBlipGoApi from 'hooks/useBlipGoApi';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react';
import { Contact, ContactsData, UpdateContact } from 'types/Contact';
import './style.scss';
import { Divider } from '@mui/material';
import ContactDrawer from 'components/ContactDrawer';
import useTrack from 'hooks/useTrack';
import { COEX_OPEN_CONTACT_INFO } from 'libs/trackingEvents';

const PAGE_SIZE = 50;
const INITIAL_PAGE = 1;

function Contacts() {
  const isMobileView = useMobileView();
  const { getContacts } = useBlipGoApi();
  const { createToastError } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState<ContactsData>();
  const isEmptyContacts = !contacts?.items.length;

  const loadContacts = async (pageNumber?: number, pageSize?: number) => {
    try {
      setIsLoading(true);
      let contactsData = await getContacts(pageNumber, pageSize);
      contactsData = {
        ...contactsData,
        items: contactsData.items.filter((contact: Contact) => contact.phoneNumber),
      };
      setContacts(contactsData);
    } catch (error) {
      createToastError('Houve algum erro ao carregar seus contatos', 'Tente novamente mais tarde');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadContacts(INITIAL_PAGE, PAGE_SIZE);
  }, []);

  return (
    <MainContainer>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <BdsGrid gap="2" direction="column">
        {!isMobileView && (
          <BdsGrid justifyContent="space-between" lg="12" xg="12" margin="b-3">
            <MainHeader
              title="Seus contatos"
              description="Esses são todos os contatos que já trocaram mensagem com seu número."
              hideHelpButton
            />
            <BdsGrid gap="2">
              <NewWppMessageButton />
              <ExportContactsButton isEmptyContacts={isEmptyContacts} />
            </BdsGrid>
          </BdsGrid>
        )}
        <Divider />
        <ContactsSection isLoading={isLoading} contacts={contacts} setContacts={setContacts} />
        <BdsGrid justifyContent="center">
          {contacts && (
            <BdsPagination
              className="contacts-pagination"
              numberItems={contacts.totalItems}
              startedPage={1}
              pageCounter={contacts.totalPages > 1}
              pages={contacts.totalPages}
              optionsPosition="top"
              onBdsPaginationChange={ev => loadContacts(ev?.detail, PAGE_SIZE)}
            />
          )}
        </BdsGrid>
      </BdsGrid>
    </MainContainer>
  );
}

function Loader() {
  return (
    <BdsGrid gap="1" padding="y-4" alignItems="center" justifyContent="center">
      <BdsLoadingSpinner size="small" />
      <BdsTypo variant="fs-14"> Carregando seus contatos...</BdsTypo>
    </BdsGrid>
  );
}

function ContactsSection({
  isLoading,
  contacts,
  setContacts,
}: Readonly<{
  isLoading: boolean;
  contacts?: ContactsData;
  setContacts: React.Dispatch<React.SetStateAction<ContactsData | undefined>>;
}>) {
  const [selectedContact, setSelectedContact] = useState<Contact & { index: number }>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { track } = useTrack();

  if (isLoading) {
    return <Loader />;
  }

  if (!contacts?.items.length) {
    return <EmptyContacts />;
  }

  const handleSelectContact = (contact: Contact, index: number) => {
    track(COEX_OPEN_CONTACT_INFO);
    setSelectedContact({ ...contact, index });
    setIsDrawerOpen(true);
  };

  const handleContactUpdate = (updatedContact: UpdateContact) => {
    setContacts((prev: ContactsData | undefined) => {
      if (!prev) return prev;
      const updatedItems = [...(prev.items || [])];

      if (selectedContact?.index !== undefined) {
        updatedItems[selectedContact.index] = {
          ...updatedItems[selectedContact.index],
          ...updatedContact,
        };
        setSelectedContact({
          ...selectedContact,
          extras: { coexGroup: updatedContact?.extras?.coexGroup ?? selectedContact.extras.coexGroup },
        });
      }

      return {
        ...prev,
        items: updatedItems,
      };
    });
  };

  return (
    <BdsGrid direction="column" gap="1" className="fade-in">
      {selectedContact && (
        <ContactDrawer
          onGroupChange={handleContactUpdate}
          contactIdentity={selectedContact.identity}
          contactName={selectedContact.name}
          contactPhone={selectedContact.phoneNumber}
          lastUpdateDate={selectedContact.lastMessageDate}
          group={selectedContact.extras.coexGroup}
          isDrawerOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}

      {contacts?.items?.map((contact, index) => (
        <ContactItem
          key={contact.phoneNumber}
          name={contact.name}
          phoneNumber={contact.phoneNumber}
          lastMessage={contact.lastMessageDate}
          group={contact.extras.coexGroup}
          onContactClick={() => handleSelectContact(contact, index)}
        />
      ))}
    </BdsGrid>
  );
}
function EmptyContacts() {
  return (
    <BdsPaper elevation="none" class="paper-pattern" style={{ display: 'flex', justifyContent: 'center' }}>
      <BdsGrid
        direction="column"
        gap="1"
        justifyContent="center"
        alignItems="center"
        padding="y-4"
        md="7"
        lg="7"
        xg="7"
      >
        <BdsIllustration style={{ height: '10rem' }} type="empty-states" name="box" />
        <BdsTypo variant="fs-20" tag="h4" bold="bold" margin={false}>
          Ainda sem contatos
        </BdsTypo>
        <BdsTypo style={{ textAlign: 'center' }}>
          Se já conectou seu número ao Blip Go Coex, os contatos serão exibidos aqui assim que houver uma nova troca de
          mensagens.
        </BdsTypo>
      </BdsGrid>
    </BdsPaper>
  );
}

export default Contacts;
