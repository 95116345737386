import { BdsGrid, BdsRadio, BdsRadioGroup } from 'blip-ds/dist/blip-ds-react/components';
import FaqChatBubble from 'components/FaqWizard/FaqChatBubble';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react';

type FaqFaqInputOtherTopicsProps = {
  onNextClickCallback: (selectedOption: string) => void;
};

function FaqInputOtherTopics({ onNextClickCallback }: FaqFaqInputOtherTopicsProps) {
  const isMobileView = useMobileView();
  const { createToastWarning } = useToast();
  const [selectedOption, setSelectedOption] = useState('');
  const [isFaqValid, setIsFaqValid] = useState(false);

  const handleNextClick = () => {
    if (!selectedOption) {
      createToastWarning('Opção inválida', 'Por favor, selecione uma opção para continuar');
      return;
    }
    onNextClickCallback(selectedOption);
  };

  useEffect(() => {
    const radio = document.querySelector('#rbg-options') as HTMLBdsInputElement;
    radio?.addEventListener('bdsRadioGroupChange', (ev: any) => {
      const option = ev?.detail?.value;
      setSelectedOption(option);
      setIsFaqValid(true);
    });
  }, [selectedOption]);

  return (
    <>
      <FaqChatBubble
        contentHTML={
          'Gostaria de adicionar mais <b>algum assunto</b> que seus clientes <b>sempre perguntam</b> antes de comprarem com você?'
        }
      />
      <BdsGrid padding="l-1">
        <BdsRadioGroup id="rbg-options">
          <BdsGrid direction="column" gap="1">
            <BdsRadio value="yes" label="Sim" />
            <BdsRadio value="no" label="Não" />
          </BdsGrid>
        </BdsRadioGroup>
      </BdsGrid>
      <BdsGrid xxs="12" margin="t-4" gap="2" direction="column-reverse">
        <MaterialButton
          onClickCallback={handleNextClick}
          text="Confirmar e continuar"
          variant="primary"
          endIconBdsName="arrow-right"
          isMobileView={isMobileView}
          disabled={!isFaqValid}
        ></MaterialButton>
      </BdsGrid>
    </>
  );
}

export default FaqInputOtherTopics;
