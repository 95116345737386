// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  .content {
    margin: 0 !important;
  }
  .steps-content {
    min-height: calc(100vh + 6rem) !important;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 6rem;
  }
  .steps-body {
    flex: 1;
    display: flex;
  }
  .steps-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0rem;
    box-shadow: 0px -0.25rem 0.8125rem 0px rgba(0, 0, 0, 0.2);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramContainer/style.scss"],"names":[],"mappings":"AAEA;EACE;IACE,oBAAA;EADF;EAIA;IACE,yCAAA;IACA,YAAA;IACA,aAAA;IACA,sBAAA;IACA,WAAA;IACA,mBAAA;EAFF;EAKA;IACE,OAAA;IACA,aAAA;EAHF;EAMA;IACE,eAAA;IACA,SAAA;IACA,OAAA;IACA,QAAA;IACA,mBAAA;IACA,yDAAA;EAJF;AACF","sourcesContent":["@use '/src/styles/colors';\n\n@media (max-width: 768px) {\n  .content {\n    margin: 0 !important;\n  }\n\n  .steps-content {\n    min-height: calc(100vh + 6rem) !important;\n    height: auto;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin-bottom: 6rem;\n  }\n\n  .steps-body {\n    flex: 1;\n    display: flex;\n  }\n\n  .steps-footer {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    border-radius: 0rem;\n    box-shadow: 0px -0.25rem 0.8125rem 0px rgba(0, 0, 0, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
