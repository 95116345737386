import { BdsButton, BdsGrid, BdsIcon, BdsPaper, BdsRadio, BdsRadioGroup, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import ActiveMessagesCancelAlert from 'components/ActiveMessagesCancelAlert';
import useTrack from 'hooks/useTrack';
import {
  COEX_CAMPAIGN_BACK_BUTTON,
  COEX_CAMPAIGN_CANCEL_BUTTON,
  COEX_CAMPAIGN_CONFIRM_CANCELLING_ALERT,
  COEX_CAMPAIGN_DIRECTIONING,
  COEX_CAMPAIGN_NEXT_BUTTON,
} from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';

const SELECTED_CLASS = 'card-container selected';
const UNSELECTED_CLASS = 'card-container';

function MessageResponseDirection() {
  const [isHumanRedirect, setIsHumanRedirect] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useTrack();

  const checkRadioHandler = (isHumanRedirect: boolean) => {
    const target = isHumanRedirect ? 'attHuman' : 'startFlow';
    track(COEX_CAMPAIGN_DIRECTIONING, { targetIdentifier: target });
    const radioInput = document.querySelectorAll('bds-radio');
    if (radioInput.length === 2) {
      radioInput[isHumanRedirect ? 0 : 1].setAttribute('checked', 'true');
      setIsHumanRedirect(isHumanRedirect);
    }
  };

  const handleBackButton = () => {
    track(COEX_CAMPAIGN_BACK_BUTTON, { stage: 'targeting' });
    navigate('../configuration', { state: { ...location.state } });
  };

  const handleContinueButton = () => {
    track(COEX_CAMPAIGN_NEXT_BUTTON, { stage: 'targeting' });
    navigate('../confirm', { state: { ...location.state, isHumanRedirect } });
  };

  const handleOpenCancelAlert = () => {
    track(COEX_CAMPAIGN_CANCEL_BUTTON, { stage: 'targeting' });
    const alert = document.querySelector('bds-alert');
    alert?.toggle();
  };

  const handleConfirmCancelling = () => {
    track(COEX_CAMPAIGN_CONFIRM_CANCELLING_ALERT);
    const alert = document.querySelector('bds-alert');
    alert?.toggle();
    navigate('../template');
  };

  return (
    <>
      <BdsPaper style={{ backgroundColor: 'white' }}>
        <BdsGrid direction="column" padding="4" gap="2">
          <div>
            <BdsTypo tag="h4" variant="fs-20" bold="bold" margin={false}>
              Direcionamento de respostas
            </BdsTypo>
            <BdsTypo variant="fs-14">Defina para onde o contato será direcionado caso responda a mensagem.</BdsTypo>
          </div>

          <BdsRadioGroup>
            <BdsGrid gap="2">
              <BdsGrid
                onClick={() => checkRadioHandler(true)}
                className={isHumanRedirect ? SELECTED_CLASS : UNSELECTED_CLASS}
                gap="2"
                padding="2"
                alignItems="center"
                justifyContent="space-between"
                xg="2"
                lg="4"
                md="4"
              >
                <BdsIcon size="large" name="agent" />
                <BdsGrid alignItems="center">
                  <BdsGrid direction="column">
                    <BdsTypo bold="bold">Atendimento humano</BdsTypo>
                    <BdsTypo>Transferido diretamente para a fila de espera dos atendentes.</BdsTypo>
                  </BdsGrid>
                  <BdsRadio checked={isHumanRedirect} style={{ alignSelf: 'baseline' }} value="humanAttendance" />
                </BdsGrid>
              </BdsGrid>

              <BdsGrid
                onClick={() => checkRadioHandler(false)}
                className={!isHumanRedirect ? SELECTED_CLASS : UNSELECTED_CLASS}
                gap="2"
                padding="2"
                alignItems="center"
                justifyContent="space-between"
                xg="2"
                lg="4"
                md="4"
              >
                <BdsIcon size="large" name="robot" />
                <BdsGrid direction="column">
                  <BdsTypo bold="bold">Início do fluxo</BdsTypo>
                  <BdsTypo>A conversa inicia do zero, sendo atendido pelo seu chatbot.</BdsTypo>
                </BdsGrid>
                <BdsRadio checked={!isHumanRedirect} style={{ alignSelf: 'baseline' }} value="chatbotStart" />
              </BdsGrid>
            </BdsGrid>
          </BdsRadioGroup>
          <BdsGrid justifyContent="space-between" margin="t-2">
            <BdsGrid gap="2">
              <BdsButton variant="ghost" onBdsClick={handleBackButton}>
                Voltar
              </BdsButton>
              <BdsButton onBdsClick={handleContinueButton}>Continuar</BdsButton>
            </BdsGrid>
            <BdsButton variant="tertiary" onBdsClick={handleOpenCancelAlert}>
              Cancelar
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <ActiveMessagesCancelAlert confirmCallback={handleConfirmCancelling} />
    </>
  );
}

export default MessageResponseDirection;
