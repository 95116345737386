export type BotConfiguration = {
  botId: string;
  status: BotStatus;
  botName: string;
  whatsApp: string;
  companySite: string;
  marketplace: string;
  invalidAnswerMessage: string;
  welcomeMessage: string;
};

export enum BotStatus {
  None,
  Created,
  Tested,
  Active,
  Paused,
  PausedWithNoData,
}
