// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card-popup {
  margin-left: 1.75rem !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/NameInitials/style.scss"],"names":[],"mappings":"AAEA;EACE,+BAAA;EACA,2CAAA;EACA,mBAAA;AADF","sourcesContent":["@use '/src/styles/colors';\n\n.user-card-popup {\n  margin-left: 1.75rem !important;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);\n  border-radius: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
