import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsButtonIcon,
  BdsGrid,
  BdsInput,
  BdsPaper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import ActiveMessagesCancelAlert from 'components/ActiveMessagesCancelAlert';
import useBlipGoApi from 'hooks/useBlipGoApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import {
  COEX_CAMPAIGN_BACK_BUTTON,
  COEX_CAMPAIGN_CANCEL_BUTTON,
  COEX_CAMPAIGN_CONFIRM,
  COEX_CAMPAIGN_CONFIRM_CANCELLING_ALERT,
  COEX_CAMPAIGN_EDIT_BUTTON,
} from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AudienceAndVariables, Template } from 'types/ActiveMessage';
import './style.scss';
import { pluralSuffix } from 'utils/formaters';
import { Divider } from '@mui/material';
import { useMobileView } from 'contexts/MobileViewContext';
import MaterialButton from 'components/UI/MaterialButton';
import { AudienceSource } from 'types/Campaigns';

type AudienceLocationStateParams = {
  audience: AudienceAndVariables[];
  template: Template;
  audienceSource: string;
  audienceGroup: string;
};

function Confirmation() {
  const [loading, setLoading] = useState(false);
  const { dispatchWhatsAppCampaign } = useBlipGoApi();
  const location = useLocation();
  const navigate = useNavigate();
  const { createToastError, createToastSuccess } = useToast();
  const { track } = useTrack();
  const isMobileView = useMobileView();
  const state = location.state;
  const { audience, template, audienceSource, audienceGroup }: AudienceLocationStateParams = state;
  const audienceAmount = `${audience.length} contato${pluralSuffix(audience.length)}`;
  const audienceSummary =
    audienceSource === AudienceSource.Group ? `${audienceGroup} (${audienceAmount})` : audienceAmount;

  const handleBackButton = () => {
    track(COEX_CAMPAIGN_BACK_BUTTON, { stage: 'summary ' });
    navigate('../configuration', { state: state });
  };

  const handleDispatchButton = async () => {
    setLoading(true);
    const input = document.querySelector('bds-input');

    if (!input?.value) {
      createToastError('Nome da campanha não informado', 'Por favor, informe um nome para a campanha.');
      setLoading(false);
      return;
    }

    const campaignName = input.value;
    try {
      await dispatchWhatsAppCampaign(campaignName, audience, template);
      track(COEX_CAMPAIGN_CONFIRM, { status: 'success' });
      createToastSuccess('Campanha criada com sucesso', 'As mensagens estão sendo enviadas.');
      navigate('/audience');
    } catch (error) {
      const err = error as Error;
      track(COEX_CAMPAIGN_CONFIRM, { status: 'failed', error: err.message });
      createToastError('Erro ao enviar mensagens', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenCancelAlert = () => {
    track(COEX_CAMPAIGN_CANCEL_BUTTON, { stage: 'summary' });
    const alert = document.getElementById('cancel-alert') as HTMLBdsAlertElement;
    alert?.toggle();
  };

  const handleConfirmCancelling = () => {
    track(COEX_CAMPAIGN_CONFIRM_CANCELLING_ALERT, { stage: 'summary' });
    navigate('../template');
  };

  const handleEditButton = () => {
    track(COEX_CAMPAIGN_EDIT_BUTTON);
    const alert = document.getElementById('edit-alert') as HTMLBdsAlertElement;
    alert?.toggle();
  };

  return (
    <>
      <BdsPaper style={{ backgroundColor: 'white' }} className="fade-in">
        <BdsGrid justifyContent="stretch" direction="column" gap="2" padding="4">
          <BdsTypo variant="fs-20" bold="bold" tag="h4" margin={false}>
            Nome do disparo
          </BdsTypo>
          <BdsTypo variant="fs-14">Defina um nome que será usado para identificação do disparo.</BdsTypo>
          <BdsInput
            errorMessage="Este campo não pode ficar em branco!"
            placeholder="Nome para identificação (uso interno)"
          />

          <Divider />

          <BdsTypo variant="fs-20" bold="bold" tag="h4" margin={false}>
            Resumo do envio
          </BdsTypo>
          <BdsGrid
            className="summary-container"
            alignItems="center"
            justifyContent="space-between"
            padding="2"
            lg="12"
            xg="12"
            md="12"
          >
            <BdsGrid direction={isMobileView ? 'column' : 'row'} gap={isMobileView ? '2' : '6'} padding="x-1">
              <div>
                <BdsTypo variant="fs-14" bold="bold">
                  Modelo
                </BdsTypo>
                <BdsTypo variant="fs-14">{template.templateId}</BdsTypo>
              </div>
              <div>
                <BdsTypo variant="fs-14" bold="bold">
                  Audiência
                </BdsTypo>
                <BdsTypo variant="fs-14">{audienceSummary}</BdsTypo>
              </div>
              <div>
                <BdsTypo variant="fs-14" bold="bold">
                  Envio
                </BdsTypo>
                <BdsTypo variant="fs-14">Imediato</BdsTypo>
              </div>
            </BdsGrid>
            <BdsButtonIcon
              data-testid="edit-button-icon"
              variant="secondary"
              onBdsClick={handleEditButton}
              icon="edit"
            />
          </BdsGrid>

          <BdsGrid direction={isMobileView ? 'column' : 'row'} justifyContent="space-between" margin="t-2" gap="2">
            <BdsGrid gap="2" direction={isMobileView ? 'column' : 'row'}>
              <MaterialButton
                variant="secondary"
                text="Voltar"
                isMobileView={isMobileView}
                onClickCallback={handleBackButton}
              />
              <MaterialButton
                loading={loading}
                text="Enviar mensagens"
                isMobileView={isMobileView}
                onClickCallback={handleDispatchButton}
              />
            </BdsGrid>
            <MaterialButton
              variant="tertiary"
              text="Cancelar"
              isMobileView={isMobileView}
              onClickCallback={handleOpenCancelAlert}
            />
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <EditAlert />
      <ActiveMessagesCancelAlert confirmCallback={handleConfirmCancelling} />
    </>
  );
}

function EditAlert() {
  const navigate = useNavigate();
  const template = useLocation().state?.template;

  const handleBackButton = () => {
    const alert = document.getElementById('edit-alert') as HTMLBdsAlertElement;
    alert?.toggle();
  };

  const handleConfirm = () => {
    const alert = document.getElementById('edit-alert') as HTMLBdsAlertElement;
    alert?.toggle();
    navigate('../configuration', { state: { template } });
  };

  return (
    <BdsAlert data-testid="edit-alert" id="edit-alert">
      <BdsAlertHeader variant="warning" icon="warning">
        Deseja editar o envio?
      </BdsAlertHeader>
      <BdsAlertBody>
        <BdsTypo variant="fs-14">
          Ao <b>confirmar</b>, você retornará à etapa de configuração do disparo, e será preciso carregar novamente a
          planilha de audiência.
        </BdsTypo>
      </BdsAlertBody>
      <BdsAlertActions>
        <BdsButton variant="secondary" onBdsClick={handleBackButton}>
          Voltar
        </BdsButton>
        <BdsButton variant="secondary" onBdsClick={handleConfirm}>
          Confirmar
        </BdsButton>
      </BdsAlertActions>
    </BdsAlert>
  );
}

export default Confirmation;
