import React, { useState, useEffect } from 'react';
import { BdsGrid, BdsButton, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import SurveyBox from './SurveyBox';
import BlipSurveyLogo from '../../assets/blip-survey.svg';
import './style.scss';
import { SURVEY_BEST_STRATEGY_ANSWER } from 'libs/trackingEvents';
import useTrack from 'hooks/useTrack';
import { useNavigate } from 'react-router-dom';
import { QUESTION_OPTIONS } from 'libs/surveyQuestions';

interface SurveyData {
  question: string;
  options: { label: string; description: string }[];
}

const SurveyContainer: React.FC = () => {
  const { track } = useTrack();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<{ question: string; answer: string }[]>([]);
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);
  const surveyData: SurveyData[] = QUESTION_OPTIONS;

  const trackAndNavigate = () => {
    track(SURVEY_BEST_STRATEGY_ANSWER, { answer: answers[0].answer });
    navigate('/dashboard');
  };

  const handleSelectOption = (question: string, option: string) => {
    if (option) {
      const updatedAnswers = [...answers];
      const existingAnswerIndex = updatedAnswers.findIndex(answer => answer.question === question);
      setIsOptionSelected(true);

      if (existingAnswerIndex !== -1) {
        updatedAnswers[existingAnswerIndex].answer = option;
      } else {
        updatedAnswers.push({ question, answer: option });
      }
      setAnswers(updatedAnswers);
    }
  };

  const handleNextStep = () => {
    if (isOptionSelected) {
      setIsOptionSelected(false);
      if (step < surveyData.length - 1) {
        setStep(prevStep => prevStep + 1);
      } else {
        trackAndNavigate();
      }
    }
  };

  useEffect(() => {
    const handleRadioGroupChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const selectedOption = target.value;
      handleSelectOption(surveyData[step].question, selectedOption);
    };
    const radioGroup = document.getElementById(`radio-group-${surveyData[step].question}`);
    if (radioGroup) {
      radioGroup.addEventListener('bdsRadioGroupChange', handleRadioGroupChange);
      return () => {
        radioGroup.removeEventListener('bdsRadioGroupChange', handleRadioGroupChange);
      };
    }
  }, [step, answers]);

  return (
    <>
      <BdsGrid gap="2" justifyContent="center" direction="column" padding="2" alignItems="center">
        <BdsPaper border={false} elevation="static" className="paper-question">
          <BdsGrid direction="row" gap="2" justifyContent="center" alignItems="center" padding="2">
            <img src={BlipSurveyLogo} />
            <BdsGrid direction="column">
              <BdsTypo bold="bold" variant="fs-16">
                Para começar:
              </BdsTypo>
              <BdsTypo variant="fs-12"> Vamos entender a melhor estratégia para você configurar o BlipAssist!</BdsTypo>
            </BdsGrid>
          </BdsGrid>
        </BdsPaper>
        <BdsGrid gap="2" alignItems="center" direction="column" justifyContent="center" className="survey">
          {step < surveyData.length ? (
            <>
              <SurveyBox
                question={surveyData[step].question}
                options={surveyData[step].options}
                onSelectOption={handleSelectOption}
                selectedOption={answers[step]?.answer || undefined}
              />
              <BdsButton disabled={!isOptionSelected} onClick={handleNextStep} variant="primary">
                {step === surveyData.length - 1 ? 'Finalizar' : 'Continuar'}{' '}
              </BdsButton>
            </>
          ) : null}
        </BdsGrid>
      </BdsGrid>
    </>
  );
};

export default SurveyContainer;
