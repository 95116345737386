// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CampaignsAudience-module__refresh-button__AfBb3 {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 0.625rem;
  border-radius: 0.5rem;
}
.CampaignsAudience-module__refresh-button__AfBb3:hover {
  background-color: rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/ActiveMessages/CampaignsAudience/CampaignsAudience.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,6CAAA;EACA,iBAAA;EACA,qBAAA;AACF;AACE;EACE,oCAAA;AACJ","sourcesContent":[".refresh-button {\n  cursor: pointer;\n  transition: background-color 0.3s ease-in-out;\n  padding: 0.625rem;\n  border-radius: 0.5rem;\n\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refresh-button": `CampaignsAudience-module__refresh-button__AfBb3`
};
export default ___CSS_LOADER_EXPORT___;
