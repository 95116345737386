import { facebookAppInfo } from 'atoms/AppAtoms';
import { useAtom } from 'jotai';
import * as graphApiRoutes from 'libs/graphApiRoutes';
import { useCallback } from 'react';
import { Facebook as FB } from 'services/MetaSDK/FacebookSDK.js';
import { Business, FbConnectedData, FbConnectionResult, FbPagesResult, Product, User } from 'types/Facebook';
import usePacksApi from './usePacksApi';
import { FacebookCatalogGetBusinessErrors } from 'libs/facebookCatalogSteps';

const useGraphApi = () => {
  const instagram_connection_scopes =
    'read_insights, catalog_management, pages_show_list, ads_management, ads_read, business_management, pages_messaging, instagram_basic, instagram_manage_messages, pages_read_engagement, pages_manage_metadata, public_profile';
  const { getFacebookAppInfo } = usePacksApi();
  const [fbAppInfo, setFacebookAppInfo] = useAtom(facebookAppInfo);

  const initFacebookSdk = useCallback(
    async (botId: string) => {
      let facebookAppInformation = fbAppInfo;
      if (!facebookAppInformation) {
        facebookAppInformation = await getFacebookAppInfo(botId);
        setFacebookAppInfo(facebookAppInformation);
      }

      await FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: facebookAppInformation.graphApiVersion,
      });
    },
    [fbAppInfo, getFacebookAppInfo, setFacebookAppInfo],
  );

  const getLoginScopes = (scopeType: string): string => {
    switch (scopeType) {
      case 'instagram_connection':
        return instagram_connection_scopes;
      case 'whatsapp_connection':
        return 'whatsapp_business_management,whatsapp_business_messaging,business_management';
      default:
        throw new Error('Invalid scope');
    }
  };

  const launchFacebookSignup = async (
    scope: 'instagram_connection' | 'whatsapp_connection',
    callback: (arg0: FbConnectionResult) => any,
  ) => {
    await FB.login(
      async (response: FbConnectionResult) => {
        if (response) {
          if (response && response.status === 'connected') {
            await setFbConnectedData(response, scope);
          }
          callback && callback(response);
        } else {
          throw new Error('Error logging in to Facebook');
        }
      },
      {
        auth_type: 'reauthorize',
        scope: getLoginScopes(scope),
        extras: {
          feature: 'whatsapp_embedded_signup',
          featureType: 'coexistence',
          setup: {},
        },
      },
    );
  };

  const logoutFacebook = async () => {
    const fbConnectedData = getFacebookConnectedData();
    if (fbConnectedData) {
      await deletePermissions(fbConnectedData.userID, fbConnectedData.accessToken);
    }
    FB.logout().then(() => {
      setFbConnectedData(null, '');
    });
  };

  const getUser = async (token: string): Promise<User> => {
    const url = `${graphApiRoutes.GRAPH_API_BASE_URL}/me?access_token=${token}`;
    const res = await fetch(url);
    const user = await res.json();
    return user as User;
  };

  const getPages = async (userID: string, token: string): Promise<FbPagesResult[]> => {
    const accountParam = 'id,name,access_token,connected_instagram_account{username},instagram_business_account';
    const url = `${graphApiRoutes.GRAPH_API_BASE_URL}/${graphApiRoutes.GRAPH_API_VERSION}/${userID}/accounts?access_token=${token}&fields=${accountParam}`;
    const res = await fetch(url);
    const data = await res.json();
    return data.data as FbPagesResult[];
  };

  const deletePermissions = async (userID: string, token: string) => {
    const url = `${graphApiRoutes.GRAPH_API_BASE_URL}/${graphApiRoutes.GRAPH_API_VERSION}/${userID}/permissions?access_token=${token}`;
    const res = await fetch(url, { method: 'DELETE' });
    const data = await res.json();
  };

  const getFacebookConnectedData = () => {
    const datStr = localStorage.getItem('fbConnectedData');
    if (!datStr) {
      return null;
    }
    return JSON.parse(localStorage.getItem('fbConnectedData') || '{}') as FbConnectedData;
  };

  const setFbConnectedData = async (loginResponse: FbConnectionResult | null, scope: string) => {
    if (!loginResponse || !loginResponse.authResponse) {
      localStorage.removeItem('fbConnectedData');
      return;
    }

    const fbUserData = await getUser(loginResponse.authResponse.accessToken);
    const fbConnectedData = {
      accessToken: loginResponse.authResponse.accessToken,
      userID: loginResponse.authResponse.userID,
      name: fbUserData.name,
      scope: scope,
    } as FbConnectedData;

    localStorage.setItem('fbConnectedData', JSON.stringify(fbConnectedData));
  };

  const getUserBusinesses = useCallback(async (userId: string, token: string): Promise<Business[]> => {
    const url = `${graphApiRoutes.GRAPH_API_BASE_URL}/${userId}/businesses?access_token=${token}`;
    const res = await fetch(url);
    const data = await res.json();
    if (data.error) {
      const errorMessage = data.error.message;
      if (errorMessage.includes('The user has not authorized application'))
        return Promise.reject(FacebookCatalogGetBusinessErrors.InvalidToken);

      return Promise.reject(FacebookCatalogGetBusinessErrors.Unknown);
    }
    return data.data as Business[];
  }, []);

  return {
    initFacebookSdk,
    launchFacebookSignup,
    logoutFacebook,
    getFacebookConnectedData,
    getUser,
    getUserBusinesses,
    getPages,
  };
};

export default useGraphApi;
