import { selectedFacebookPage } from 'atoms/AppAtoms';
import { BdsAvatar, BdsGrid, BdsIcon, BdsLoadingPage, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import usePacksApi from 'hooks/usePacksApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { InstagramActivationErrors, InstagramSteps } from 'libs/instagramSteps';
import {
  CONNECT_INSTAGRAM_CHOOSE_OTHER_PAGE,
  CONNECT_INSTAGRAM_CONNECTION,
  CONNECT_INSTAGRAM_CONNECTION_ERROR,
  CONNECT_INSTAGRAM_CONNECTION_SUCCESSFUL,
} from 'libs/trackingEvents';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InstagramBusinessAccountDetails } from 'types/Instagram';
import InstagramChooseOtherPageModal from '../InstagramChooseOtherPageModal';
import './style.scss';
import { DataLayer } from 'services/Analytics/DatalayerEvents';

type InstagramActivationProps = {
  setCurrentStep: (step: InstagramSteps) => void;
};

function InstagramActivation({ setCurrentStep }: InstagramActivationProps) {
  const [selectedFbPage] = useAtom(selectedFacebookPage);
  const { getFacebookConnectedData } = useGraphApi();
  const fbConnectedData = getFacebookConnectedData();
  const [isLoading, setIsLoading] = useState(false);
  const { botConfiguration, setInstaUsername } = useAppContext();
  const { activateInstagram, updateUserInstagramStatusAndData, enableStoryReply } = usePacksApi();
  const { createToastSuccess, createToastError } = useToast();
  const { track } = useTrack();
  const navigate = useNavigate();
  const isMobileView = useMobileView();
  const modalRef = useRef<HTMLBdsModalElement>(null);

  const chooseOtherAccountHandler = () => {
    track(CONNECT_INSTAGRAM_CHOOSE_OTHER_PAGE);
    modalRef.current?.toggle?.();
  };

  const connectInstagram = async () => {
    track(CONNECT_INSTAGRAM_CONNECTION, { instagram_account: selectedFbPage?.connected_instagram_account?.username });
    try {
      if (
        !selectedFbPage ||
        !selectedFbPage.access_token ||
        !selectedFbPage.id ||
        !selectedFbPage.connected_instagram_account?.id
      ) {
        throw Error('Conta do Instagram inválida.');
      }

      const parameters = {
        instagramBusinessAccountId: selectedFbPage.connected_instagram_account.id,
        pageId: selectedFbPage.id,
        pageAccessToken: selectedFbPage.access_token,
        userName: selectedFbPage.connected_instagram_account.username,
      } as InstagramBusinessAccountDetails;

      setIsLoading(true);
      await activateInstagram(botConfiguration.botId, fbConnectedData?.userID!, parameters);
      await enableStoryReply(botConfiguration.botId);
      setInstaUsername(selectedFbPage?.connected_instagram_account?.username);
      await updateUserInstagramStatusAndData(botConfiguration, parameters);
      track(CONNECT_INSTAGRAM_CONNECTION_SUCCESSFUL, {
        instagramConnected: true,
        instagramUserName: selectedFbPage?.connected_instagram_account?.username,
      });
      DataLayer().TriggerInstagramConversion();
      createToastSuccess('Conta do Instagram conectada com sucesso!');
      setIsLoading(false);
      navigate('/dashboard');
    } catch (error: any) {
      let errorMessage;
      switch (error) {
        case InstagramActivationErrors.InvalidInstagramAccount:
          errorMessage = 'Conta do Instagram inválida';
          setCurrentStep(InstagramSteps.ErrorPage);
          break;
        case InstagramActivationErrors.NoMessagesAccess:
          errorMessage = 'O acesso às mensagens não está ativado';
          setCurrentStep(InstagramSteps.InstagramAllowMessagesAccess);
          break;
        case InstagramActivationErrors.NoBindedPagesFound:
          errorMessage = 'Perfil do Instagram não vinculado à pagina do Facebook';
          setCurrentStep(InstagramSteps.InstagramPageNotLinked);
          break;
        default:
          errorMessage = 'Erro ativando a página do Instagram';
          setCurrentStep(InstagramSteps.ErrorPage);
          break;
      }

      track(CONNECT_INSTAGRAM_CONNECTION_ERROR, {
        error: errorMessage,
        instagramAccount: selectedFbPage?.connected_instagram_account?.username,
      });
      createToastError(errorMessage, 'Por favor, tente novamente');
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <BdsLoadingPage />
  ) : (
    <>
      <BdsGrid gap="4" margin="b-5" alignItems="center" justifyContent="space-between">
        <BdsGrid gap="2" direction="column">
          <BdsGrid direction="row" gap="2">
            <BdsIcon type="logo" name="instagram" size="xxx-large" />
            <BdsTypo tag="span" variant={!isMobileView ? 'fs-24' : 'fs-20'} bold="bold" lineHeight="plus">
              Confirme a conta do Instagram para conectar
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column">
            <BdsTypo tag="p">
              Sua página já está vinculada a um perfil do Instagram. Agora basta conferir e conectar.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column">
            <BdsGrid
              class="instagram-page-item"
              direction={isMobileView ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems="center"
              gap={isMobileView ? '3' : undefined}
            >
              <BdsGrid gap="3">
                <BdsAvatar
                  id="avatar"
                  color="success"
                  name={selectedFbPage?.connected_instagram_account?.username}
                  size="standard"
                ></BdsAvatar>
                <BdsTypo variant="fs-16" bold="bold" class="p-t-1">
                  {selectedFbPage?.connected_instagram_account?.username}
                </BdsTypo>
              </BdsGrid>
              <BdsGrid direction={isMobileView ? 'column' : 'row'} gap="2" xxs={isMobileView ? '12' : undefined}>
                <MaterialButton
                  text="Conectar"
                  variant="primary"
                  isMobileView={isMobileView}
                  onClickCallback={connectInstagram}
                />
                <MaterialButton
                  text="Escolher outra conta"
                  variant="tertiary"
                  isMobileView={isMobileView}
                  onClickCallback={chooseOtherAccountHandler}
                />
              </BdsGrid>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
      <InstagramChooseOtherPageModal ref={modalRef} />
    </>
  );
}

export default InstagramActivation;
