import { BdsButton, BdsGrid } from 'blip-ds/dist/blip-ds-react';
import useBlipGoApi from 'hooks/useBlipGoApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { COEX_EXPORT_CONTACTS_BUTTON } from 'libs/trackingEvents';
import React from 'react';
import { exportToExcel } from '../../utils/downloadContacts';

const ExportContactsButton = ({ isEmptyContacts }: { isEmptyContacts: boolean }) => {
  const { getAllContacts } = useBlipGoApi();
  const { createToastError, createToastSuccess } = useToast();
  const { track } = useTrack();

  const handleButtonClick = async () => {
    track(COEX_EXPORT_CONTACTS_BUTTON);
    try {
      const allContacts = await getAllContacts();
      await exportToExcel(allContacts);
      createToastSuccess(
        'Seus contatos foram exportados em uma planilha',
        'Utilize esse arquivo para importar audiência ao fazer o envio de mensagens ativas.',
      );
    } catch {
      createToastError('Falha ao exportar contatos');
    }
  };

  return (
    <BdsGrid gap="2" direction="column" height="100%" justifyContent="space-between">
      <BdsButton disabled={isEmptyContacts} onBdsClick={handleButtonClick}>
        Exportar contatos
      </BdsButton>
    </BdsGrid>
  );
};

export default ExportContactsButton;
