import { BdsButton, BdsChipTag, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import WhatsappLogo from 'assets/images/whatsapp-icon.svg';
import CheckIllustration from 'assets/images/circular-check-illustration.svg';
import './styles.scss';
import { useMobileView } from 'contexts/MobileViewContext';

interface IWhatsappConnectionCardProps {
  description: string;
  onActionClick: () => void;
  statusName: 'Importante' | 'Ativo';
  statusType: 'success' | 'warning';
  title: string;
}

function WhatsappConnectionCard({
  description,
  onActionClick,
  statusName,
  statusType,
  title,
}: Readonly<IWhatsappConnectionCardProps>) {
  const isMobileView = useMobileView();

  const FinishConnectionButton = () => (
    <BdsButton icon="close" variant="text" color="content" onBdsClick={onActionClick}>
      Fechar
    </BdsButton>
  );

  const StartConnectionButton = () => <BdsButton onBdsClick={onActionClick}>Iniciar conexão</BdsButton>;

  return (
    <BdsGrid alignItems="center">
      <BdsPaper elevation="none" class={`whatsapp-connection-card ${statusType}-border`}>
        <BdsGrid direction={isMobileView ? 'column' : 'row'} justifyContent="space-between">
          <BdsGrid gap="3" alignItems="center">
            {!isMobileView && (
              <img
                className="connection-illustration"
                src={statusType === 'success' ? CheckIllustration : WhatsappLogo}
                alt="whatsapp activation illustration"
              />
            )}
            <BdsGrid direction="column" gap="1">
              <BdsGrid gap="1">
                <BdsTypo variant="fs-20" bold="bold" margin={false}>
                  {title}
                </BdsTypo>
                <BdsChipTag color={statusType} icon={statusType === 'warning' ? statusType : ''}>
                  {statusName}
                </BdsChipTag>
              </BdsGrid>

              <BdsTypo>{description}</BdsTypo>
            </BdsGrid>
          </BdsGrid>

          <BdsGrid justifyContent="center" lg="2" md="2" alignItems="center" padding="y-1">
            {statusType === 'success' ? <FinishConnectionButton /> : <StartConnectionButton />}
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
    </BdsGrid>
  );
}

export default WhatsappConnectionCard;
