import { Buffer } from 'buffer';
import { useAppContext } from 'contexts/AppContext';
import { DISPATCH_WHATSAPP_CAMPAIGN_ROUTE, GET_MESSAGE_TEMPLATES_ROUTE } from 'libs/goApiRoutes';
import { ILogArgs } from 'packs-template-baseweb';
import { AudienceAndVariables, MessageCampaignBody, Template } from 'types/ActiveMessage';
import { RequestErrorBoundary } from 'utils/request';

const BLIPGO_API_ADMIN_USER = process.env.REACT_APP_BLIPGO_ADMIN_USER;
const BLIPGO_API_ADMIN_PASSWORD = process.env.REACT_APP_BLIPGO_ADMIN_PASSWORD;

const LOGS_ARGS: ILogArgs = {
  className: 'useBlipGoApi',
};

// TODO: Improve this when HMG chatbot is ready
const BOT_FLOW_ID_PRD = '54640799-4890-416d-b3c2-4492cc2fc90b';
const BOT_STATE_ID_PRD_START = 'onboarding';
const BOT_STATE_ID_PRD_HUMAN = 'a0522fda-a3cf-4d1b-b3f5-e169e36963f5';

const useBlipGoApi = () => {
  const { botConfiguration } = useAppContext();
  const botId = botConfiguration.botId;
  const authCredentials = `${BLIPGO_API_ADMIN_USER}:${BLIPGO_API_ADMIN_PASSWORD}`;
  const basicAuth = `Basic ${Buffer.from(authCredentials).toString('base64')}`;
  const header = {
    'Content-Type': 'application/json',
    Authorization: basicAuth,
  };

  const getMessagesTemplates = async () => {
    LOGS_ARGS.methodName = 'getMessagesTemplates';
    try {
      const response = await fetch(GET_MESSAGE_TEMPLATES_ROUTE(botId), {
        headers: header,
      });
      const data = await RequestErrorBoundary(response, LOGS_ARGS);
      return data as Template[];
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  const dispatchWhatsAppCampaign = async (
    campaignName: string,
    audience: AudienceAndVariables[],
    template: Template,
    isHumanRedirect: boolean,
  ) => {
    LOGS_ARGS.methodName = 'dispatchWhatsAppCampaign';
    try {
      const stateId = isHumanRedirect ? BOT_STATE_ID_PRD_HUMAN : BOT_STATE_ID_PRD_START;
      const body: MessageCampaignBody = {
        name: campaignName,
        templateId: template.templateId,
        destinationBot: {
          botId: botId,
          flowId: BOT_FLOW_ID_PRD,
          stateId: stateId,
        },
        audiences: audience.map(audienceItem => {
          const { phone, ...parameters } = audienceItem;
          return {
            phoneNumber: phone,
            parameters: parameters,
          };
        }),
      };
      const res = await fetch(DISPATCH_WHATSAPP_CAMPAIGN_ROUTE(botId), {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      });
      await RequestErrorBoundary(res, LOGS_ARGS);
    } catch (error) {
      return Promise.reject(new Error(JSON.stringify(error)));
    }
  };

  return { getMessagesTemplates, dispatchWhatsAppCampaign };
};

export default useBlipGoApi;
