import { Buffer } from 'buffer';
import { useAppContext } from 'contexts/AppContext';
import {
  DELEGATE_BOT_ROUTE,
  DISPATCH_WHATSAPP_CAMPAIGN_ROUTE,
  GET_MESSAGE_TEMPLATES_ROUTE,
  GET_ROUTER_SKILLS_ROUTE,
} from 'libs/goApiRoutes';
import { ILogArgs } from 'packs-template-baseweb';
import { AudienceAndVariables, MessageCampaignBody, Template } from 'types/ActiveMessage';
import { RouterSkill } from 'types/Bot';
import { RequestErrorBoundary } from 'utils/request';
import usePacksApi from './usePacksApi';

const BLIPGO_API_ADMIN_USER = process.env.REACT_APP_BLIPGO_ADMIN_USER;
const BLIPGO_API_ADMIN_PASSWORD = process.env.REACT_APP_BLIPGO_ADMIN_PASSWORD;

const LOGS_ARGS: ILogArgs = {
  className: 'useBlipGoApi',
};

// TODO: Improve this when HMG chatbot is ready
const BOT_FLOW_ID_PRD = '16bcaecd-60e0-46eb-8069-ac6509839569';
const BOT_STATE_ID_PRD_HUMAN = 'a0522fda-a3cf-4d1b-b3f5-e169e36963f5';

const useBlipGoApi = () => {
  const { botConfiguration } = useAppContext();
  const { getPackInstallation } = usePacksApi();
  const botId = botConfiguration.botId;
  const authCredentials = `${BLIPGO_API_ADMIN_USER}:${BLIPGO_API_ADMIN_PASSWORD}`;
  const basicAuth = `Basic ${Buffer.from(authCredentials).toString('base64')}`;
  const header = {
    'Content-Type': 'application/json',
    Authorization: basicAuth,
  };

  const getMessagesTemplates = async () => {
    LOGS_ARGS.methodName = 'getMessagesTemplates';
    try {
      const response = await fetch(GET_MESSAGE_TEMPLATES_ROUTE(botId), {
        headers: header,
      });
      const data = await RequestErrorBoundary(response, LOGS_ARGS);
      return data as Template[];
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  const delegateBot = async () => {
    LOGS_ARGS.methodName = 'delegateBot';
    try {
      const pack = await getPackInstallation(botId);
      const { routerAccessKey } = pack;
      const response = await fetch(DELEGATE_BOT_ROUTE, {
        method: 'POST',
        headers: header,
        body: JSON.stringify({
          botId: botId,
          accessKey: routerAccessKey,
        }),
      });
      await RequestErrorBoundary(response, LOGS_ARGS);
    } catch (error) {
      return Promise.reject(new Error(JSON.stringify(error)));
    }
  };

  const dispatchWhatsAppCampaign = async (
    campaignName: string,
    audience: AudienceAndVariables[],
    template: Template,
  ) => {
    LOGS_ARGS.methodName = 'dispatchWhatsAppCampaign';
    try {
      const skills = await getRouterSkills();
      const mainSkill = skills.find(
        skill =>
          skill.shortName.toLocaleLowerCase().includes('main') || skill.name.toLocaleLowerCase().includes('main'),
      );
      if (!mainSkill) {
        throw new Error('Skill principal não encontradda');
      }
      const body: MessageCampaignBody = {
        name: campaignName,
        templateId: template.templateId,
        destinationBot: {
          botId: mainSkill.shortName,
          flowId: BOT_FLOW_ID_PRD,
          stateId: BOT_STATE_ID_PRD_HUMAN,
        },
        audiences: audience.map(audienceItem => {
          const { telefone, ...parameters } = audienceItem;
          return {
            phoneNumber: telefone,
            parameters: parameters,
          };
        }),
      };

      const res = await fetch(DISPATCH_WHATSAPP_CAMPAIGN_ROUTE(botId), {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      });
      await RequestErrorBoundary(res, LOGS_ARGS);
    } catch (error) {
      const err = error as Error;
      if (err.message.includes('This name already exists')) {
        return Promise.reject(new Error('Já existe uma campanha com esse nome. Escolha um nome diferente.'));
      }
      return Promise.reject(new Error(err.message));
    }
  };

  const getRouterSkills = async () => {
    LOGS_ARGS.methodName = 'getRouterSkills';
    try {
      const response = await fetch(GET_ROUTER_SKILLS_ROUTE(botId), {
        headers: header,
      });
      const data = await RequestErrorBoundary(response, LOGS_ARGS);
      return data as RouterSkill[];
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };

  return { getMessagesTemplates, dispatchWhatsAppCampaign, delegateBot };
};

export default useBlipGoApi;
