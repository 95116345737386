// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-color {
  color: #4D7CFF;
}

.text-legend {
  gap: 0.3125rem;
  flex-wrap: wrap;
}

.instagram-paper {
  width: 18.3125rem;
  padding: 1rem 1rem 1rem;
}

.text-description {
  width: 18.3125rem;
  padding: 0.5rem;
  display: grid;
}

.paper-interest {
  width: 635px;
  background-color: #B2DFFD;
  padding: 1rem;
}

@media (max-width: 768px) {
  .paper-interest {
    width: 18.3125rem;
    background-color: #B2DFFD;
    padding: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PrivateReply/PrivateReplyTest/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,yBAAA;EACA,aAAA;AACF;;AAEA;EACE;IACA,iBAAA;IACA,yBAAA;IACA,aAAA;EACA;AACF","sourcesContent":[".custom-color{\n  color:#4D7CFF;\n}\n\n.text-legend{\n  gap: .3125rem;\n  flex-wrap: wrap;\n}\n\n.instagram-paper{\n  width: 18.3125rem;\n  padding: 1rem 1rem 1rem;\n}\n\n.text-description{\n  width: 18.3125rem;\n  padding: .5rem;\n  display: grid;\n}\n\n.paper-interest{\n  width:635px ;\n  background-color: #B2DFFD;\n  padding: 1rem;\n}\n\n@media (max-width: 768px) {\n  .paper-interest{\n  width: 18.3125rem;\n  background-color: #B2DFFD;\n  padding: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
