import { BdsButton, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import FaqHeader from 'components/FAQ/FaqHeader';
import InputFaq from 'components/FAQ/FaqInput';
import ViewFaq from 'components/FAQ/FaqItem';
import MainContainer from 'components/MainContainer';
import MainHeader from 'components/MainHeader';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import usePlgApi from 'hooks/usePlgApi';
import useTrack from 'hooks/useTrack';
import { MAX_FAQ_QUESTIONS } from 'libs/app';
import { FAQ_TEXT_MAP } from 'libs/faqPageTexts';
import {
  COEX_FAQ_ADD_TOPIC,
  DELETE_ICON_QUESTION_FAQ,
  EDIT_QUESTION_FAQ,
  FAQ_NEWFAQ_CANCEL_MODAL_CONFIRMED,
  SAVE_QUESTION_FAQ,
} from 'libs/trackingEvents';
import React, { useEffect, useState } from 'react';
import { Faq, FaqState } from 'types/Faq';
import { isEmpty } from 'utils/assertions';
import './style.scss';

function FaqContainer() {
  const [activeFaq, setActiveFaq] = useState({} as Faq);
  const { faqs, setFaqs } = useAppContext();
  const [showInputFaq, setShowInputFaq] = useState(faqs.length === 0);
  const { patchFaq } = usePlgApi();
  const { track } = useTrack();
  const isMaxQuestionsReached = faqs.length === MAX_FAQ_QUESTIONS;
  const areChangesAllowed = isMaxQuestionsReached || !showInputFaq;
  const isMobileView = useMobileView();
  const [faqState, setFaqState] = useState(FaqState.FaqList);
  const [pageTexts, setPageTexts] = useState(FAQ_TEXT_MAP[faqState]);

  const addFaq = () => {
    track(COEX_FAQ_ADD_TOPIC);
    setActiveFaq({} as Faq);
    setFaqState(FaqState.AddingFaq);
    setShowInputFaq(true);
  };

  const saveFaq = async (faq: Faq) => {
    const faqsToPatch = faqs.filter(currentFaq => currentFaq.id !== faq.id);
    faqsToPatch.unshift(faq);
    try {
      await patchFaq(faqsToPatch);
      if (faqsToPatch.length === MAX_FAQ_QUESTIONS) {
        setShowInputFaq(false);
      }
      setFaqs(faqsToPatch);
      setActiveFaq({} as Faq);
      setShowInputFaq(false);
      setFaqState(FaqState.FaqList);
      track(SAVE_QUESTION_FAQ, {
        questonId: faq.id,
        status: 'Success',
        amountQuestions: faqsToPatch.length,
        questionSource: isEmpty(activeFaq) ? 'Nova pergunta' : 'Edição',
        typeQuestion: 'ADD_PELO_USER',
      });
    } catch (error) {
      const err = error as Error;
      track(SAVE_QUESTION_FAQ, {
        questonId: faq.id,
        status: 'Error',
        error: err.message,
        amountQuestions: faqsToPatch.length,
        questionSource: isEmpty(activeFaq) ? 'Nova pergunta' : 'Edição',
        typeQuestion: 'ADD_PELO_USER',
      });
    }
  };

  const deleteFaq = async (faq: Faq) => {
    track(DELETE_ICON_QUESTION_FAQ, { questionId: faq.id });
    const faqsToPatch = faqs.filter(currentFaq => currentFaq.id !== faq.id);
    await patchFaq(faqsToPatch);
    setFaqs(faqsToPatch);
  };

  const editFaq = (faq: Faq) => {
    track(EDIT_QUESTION_FAQ, { questionId: faq.id });
    setActiveFaq(faq);
    setFaqState(FaqState.EditingFaq);
    setShowInputFaq(true);
  };

  const cancelInputFaq = () => {
    track(FAQ_NEWFAQ_CANCEL_MODAL_CONFIRMED);
    setActiveFaq({} as Faq);
    setFaqState(FaqState.FaqList);
    setShowInputFaq(false);
  };

  useEffect(() => {
    setPageTexts(FAQ_TEXT_MAP[faqState]);
  }, [faqState]);

  return (
    <MainContainer>
      {isMobileView ? (
        <FaqHeader pageTexts={pageTexts} showInputFaq={showInputFaq} cancelInputCallback={cancelInputFaq} />
      ) : (
        <BdsGrid justifyContent="space-between">
          <MainHeader
            title="Central de assuntos"
            description="Gerencie informações que irão ajudar o assistente a responder melhor."
            hideHelpButton={true}
          />
          <BdsButton onBdsClick={() => addFaq()} variant="primary" icon="plus" disabled={isMaxQuestionsReached}>
            Adicionar assunto
          </BdsButton>
        </BdsGrid>
      )}
      {!showInputFaq && (
        <BdsGrid direction="column">
          {isMaxQuestionsReached && (
            <BdsGrid padding="b-4" xxs="auto">
              <BdsPaper className="paper-faq-limit">
                <BdsGrid padding="2" alignItems="center">
                  <BdsIcon name="checkball" theme="solid" />
                  <BdsGrid padding="l-2" gap="half" direction="column">
                    <BdsTypo variant="fs-14" bold="bold">
                      Central de assuntos completa
                    </BdsTypo>
                    <BdsTypo variant="fs-10" lineHeight="double">
                      Você atingiu o total de assuntos. Para adicionar um assunto novo, basta excluir um assunto salvo.
                    </BdsTypo>
                  </BdsGrid>
                </BdsGrid>
              </BdsPaper>
            </BdsGrid>
          )}
          <BdsGrid padding="b-2">
            <BdsTypo bold="semi-bold">{pageTexts.pageTitle}</BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column">
            {faqs?.map(faq => {
              return (
                activeFaq.id !== faq.id && (
                  <ViewFaq
                    key={faq.id}
                    areChangesAllowed={areChangesAllowed}
                    faq={faq}
                    editCallback={editFaq}
                    deleteCallback={deleteFaq}
                  />
                )
              );
            })}
          </BdsGrid>
        </BdsGrid>
      )}
      {showInputFaq && (
        <BdsGrid justifyContent="flex-start" direction="column" padding="y-4">
          {!isMobileView && <BdsTypo bold="semi-bold">{pageTexts.headerTitle}:</BdsTypo>}
          <InputFaq faq={activeFaq} saveCallback={saveFaq} cancelCallback={cancelInputFaq} pageTexts={pageTexts} />
        </BdsGrid>
      )}
    </MainContainer>
  );
}

export default FaqContainer;
