import { BdsButton, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import FacebookCatalogContainer from 'components/FacebookCatalog/FacebookCatalogContainer';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function FacebookCatalog() {
  const navigate = useNavigate();
  const isMobileView = useMobileView();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <div className="content">
        <BdsGrid alignItems="center" margin="b-2" justifyContent="space-between" xxs="auto">
          <BdsGrid class="menu-wrapper" gap="1" alignItems="center">
            <BdsIcon class="cursor-pointer" onClick={navigateToDashboard} name="home" />
            {!isMobileView && (
              <BdsTypo class="cursor-pointer" onClick={navigateToDashboard} variant="fs-14" bold="bold">
                Painel de Controle
              </BdsTypo>
            )}
            <BdsIcon name="arrow-right" />
            <BdsTypo variant="fs-14" bold="bold">
              Conectar Catálogo
            </BdsTypo>
          </BdsGrid>
          <BdsButton onClick={navigateToDashboard} variant="secondary">
            Voltar
          </BdsButton>
        </BdsGrid>
        <FacebookCatalogContainer />
      </div>
    </>
  );
}

export default FacebookCatalog;
