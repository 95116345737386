export enum FacebookCatalogSteps {
  FacebookCatalogLogin,
  FacebookCatalogSwitchProfile,
  FacebookCatalogConnection,
  FacebookCatalogNoOrganizationFound,
}

export enum FacebookCatalogGetBusinessErrors {
  Unknown,
  InvalidToken,
}
