import AllowMessages from 'assets/images/facebook-activate-allow-message-access.svg';
import { selectedFacebookPage } from 'atoms/AppAtoms';
import { BdsGrid, BdsIcon, BdsIllustration, BdsLoadingPage, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import usePacksApi from 'hooks/usePacksApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { InstagramActivationErrors, InstagramSteps } from 'libs/instagramSteps';
import {
  CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR,
  CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR_ACCESS_FB,
  CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR_TRYAGAIN,
} from 'libs/trackingEvents';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FbConnectedData } from 'types/Facebook';
import { InstagramBusinessAccountDetails } from 'types/Instagram';
import InstagramFailedBindPagesModal from '../InstagramFailedBindPagesModal';
import './style.scss';

function InstagramAllowMessagesAccess({ setCurrentStep }: { setCurrentStep: (step: InstagramSteps) => void }) {
  const isMobileView = useMobileView();
  const [facebookPage] = useAtom(selectedFacebookPage);
  const { getFacebookConnectedData, getPages } = useGraphApi();
  const connectionData = getFacebookConnectedData();
  const { botConfiguration, setInstaUsername } = useAppContext();
  const { track } = useTrack();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { createToastSuccess, createToastError } = useToast();
  const { activateInstagram, updateUserInstagramStatusAndData, enableStoryReply } = usePacksApi();
  const modalRef = useRef<HTMLBdsModalElement>(null);

  const handleRedirectToFacebookClick = async () => {
    track(CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR_ACCESS_FB);
    const url = facebookPage?.id ? `https://www.facebook.com/${facebookPage?.id}` : 'https://www.facebook.com';
    window.open(url, '_blank');
  };

  const validateInstagramAccountAndReturnDetails = async (connectionData: FbConnectedData) => {
    const accounts = await getPages(connectionData.userID, connectionData.accessToken);
    const selectedAccount = accounts.find(account => account.id === facebookPage?.id);
    if (!selectedAccount) {
      return Promise.reject(InstagramActivationErrors.InvalidInstagramAccount);
    }

    if (!selectedAccount.connected_instagram_account || !selectedAccount.instagram_business_account) {
      return Promise.reject(InstagramActivationErrors.NoBindedPagesFound);
    }

    const instagramDetails = {
      instagramBusinessAccountId: selectedAccount.instagram_business_account.id,
      userName: selectedAccount.connected_instagram_account.username,
      pageAccessToken: selectedAccount.access_token,
      pageId: selectedAccount.id,
    } as InstagramBusinessAccountDetails;

    return instagramDetails;
  };

  useEffect(() => {
    track(CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR);
  }, []);

  const handleRetryClick = async () => {
    if (!connectionData) {
      return setCurrentStep(InstagramSteps.ErrorPage);
    }

    try {
      setIsLoading(true);
      const accountDetails = await validateInstagramAccountAndReturnDetails(connectionData);
      await activateInstagram(botConfiguration.botId, connectionData.userID, accountDetails);
      await enableStoryReply(botConfiguration.botId);
      setInstaUsername(facebookPage?.connected_instagram_account?.username!);
      track(CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR_TRYAGAIN, {
        status: 'success',
        instagramAccount: facebookPage?.connected_instagram_account?.username,
      });
      await updateUserInstagramStatusAndData(botConfiguration, accountDetails);
      createToastSuccess('Instagram ativado com sucesso!');
      navigate('/dashboard');
    } catch (error) {
      let errorMessage;
      switch (error) {
        case InstagramActivationErrors.InvalidInstagramAccount:
          errorMessage = 'Conta do Instagram inválida';
          setCurrentStep(InstagramSteps.ErrorPage);
          break;
        case InstagramActivationErrors.NoBindedPagesFound:
          errorMessage = 'Perfil do Instagram não vinculado à pagina do Facebook';
          setCurrentStep(InstagramSteps.InstagramPageNotLinked);
          break;
        case InstagramActivationErrors.NoMessagesAccess:
          errorMessage = 'O acesso às mensagens não está ativado';
          createToastError(errorMessage, 'Por favor, tente novamente');
          break;
        default:
          errorMessage = 'Erro ativando a página do Instagram';
          createToastError(errorMessage, 'Por favor, tente novamente');
          break;
      }

      track(CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR_TRYAGAIN, {
        status: 'error',
        error: errorMessage,
        instagramAccount: facebookPage?.connected_instagram_account?.username,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <BdsLoadingPage />}
      <BdsGrid alignItems="center" justifyContent="space-between">
        {!isMobileView && (
          <BdsGrid class="spotIconContainer" margin="r-5">
            <BdsIllustration type="spots" name="letter-cancel-error" />
          </BdsGrid>
        )}
        <BdsGrid direction="column">
          <BdsGrid margin="t-1">
            {isMobileView && (
              <BdsGrid>
                <BdsIcon theme="solid" class="iconContainer" name="error" type="icon" />
              </BdsGrid>
            )}
            <BdsTypo tag="span" variant={!isMobileView ? 'fs-24' : 'fs-20'} bold="bold">
              O acesso às mensagens não está ativado.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column" gap="2" margin={!isMobileView ? 't-4' : 't-3'}>
            <BdsGrid direction="row">
              <BdsIcon type="icon" name="blip-ideas" />
              <BdsTypo tag="p" variant="fs-16" bold="bold" lineHeight="plus">
                Como solucionar o problema:
              </BdsTypo>
            </BdsGrid>
            <BdsGrid>
              <ul className="bullet-list">
                <li>
                  <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                    Acesse a sua página do facebook.
                  </BdsTypo>
                </li>
              </ul>
            </BdsGrid>
            <BdsGrid>
              <MaterialButton
                text="Acessar página do facebook"
                onClickCallback={handleRedirectToFacebookClick}
                variant="secondary"
                endIconBdsName="arrow-right"
                isMobileView={isMobileView}
              ></MaterialButton>
            </BdsGrid>
            <BdsGrid>
              <ul className="bullet-list">
                <li>
                  <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                    No menu esquerdo acesse: <b>Configurações &gt; Contas vinculadas.</b>
                  </BdsTypo>
                </li>
                <li>
                  <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                    Ative o campo “Permitir acesso a mensagens do Instagram na caixa de entrada” e tente a conexão
                    novamente.
                  </BdsTypo>
                </li>
              </ul>
            </BdsGrid>
            <BdsGrid>
              <img className="w-100" src={AllowMessages} alt="Facebook Allow Messages" />
            </BdsGrid>
          </BdsGrid>
          <BdsGrid justifyContent="flex-end" margin="t-4">
            <MaterialButton
              text="Tentar novamente"
              onClickCallback={handleRetryClick}
              variant="primary"
              startIconBdsName="refresh"
              isMobileView={isMobileView}
            ></MaterialButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
      <InstagramFailedBindPagesModal ref={modalRef} />
    </>
  );
}

export default InstagramAllowMessagesAccess;
