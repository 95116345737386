import AllowMessages from 'assets/images/facebook-allow-messages-access.svg';
import BindedAccounts from 'assets/images/facebook-binded-accounts.svg';
import FbConfigDisabled from 'assets/images/facebook-config-disabled.svg';
import FbConfigEnabled from 'assets/images/facebook-config-enabled.svg';
import ConfirmBind from 'assets/images/facebook-confirm-bind.svg';
import ConnectAccount from 'assets/images/facebook-connect-account.svg';
import SwitchAccounts from 'assets/images/facebook-switch-accounts.svg';
import { selectedFacebookPage } from 'atoms/AppAtoms';
import { BdsButton, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import usePacksApi from 'hooks/usePacksApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { InstagramActivationErrors, InstagramSteps } from 'libs/instagramSteps';
import {
  CONNECT_INSTAGRAM_ACCESS_FACEBOOK_PAGE,
  CONNECT_INSTAGRAM_CONNECTION_SUCCESSFUL,
  CONNECT_INSTAGRAM_LINK_ACCOUNTS,
  CONNECT_INSTAGRAM_LINK_ACCOUNTS_CONFIRMED,
  CONNECT_INSTAGRAM_NO_LINKED_ACCOUNTS_PAGE,
  CONNECT_INSTAGRAM_PAGE_ERROR,
} from 'libs/trackingEvents';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FbConnectedData } from 'types/Facebook';
import { InstagramBusinessAccountDetails } from 'types/Instagram';
import InstagramFailedBindPagesModal from '../InstagramFailedBindPagesModal';
import './style.scss';
import { DataLayer } from 'services/Analytics/DatalayerEvents';

function InstagramBindPages({ setCurrentStep }: { setCurrentStep: (step: InstagramSteps) => void }) {
  const [preview, setPreview] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [facebookPage] = useAtom(selectedFacebookPage);
  const { getFacebookConnectedData } = useGraphApi();
  const connectionData = getFacebookConnectedData();
  const { botConfiguration, setInstaUsername } = useAppContext();

  const { track } = useTrack();
  const isMobile = useMobileView();
  const { getPages } = useGraphApi();
  const navigate = useNavigate();
  const { createToastSuccess } = useToast();
  const { activateInstagram, updateUserInstagramStatusAndData, enableStoryReply } = usePacksApi();
  const modalRef = useRef<HTMLBdsModalElement>(null);

  const trackAndShowTutorial = () => {
    track(CONNECT_INSTAGRAM_LINK_ACCOUNTS);
    setPreview(false);
  };

  const accessFacebookPage = () => {
    track(CONNECT_INSTAGRAM_ACCESS_FACEBOOK_PAGE);
    const url = facebookPage?.id ? `https://www.facebook.com/${facebookPage?.id}` : 'https://www.facebook.com';
    window.open(url, '_blank');
  };

  const validateInstagramPageAndReturnDetails = async (connectionData: FbConnectedData) => {
    const pageList = await getPages(connectionData.userID, connectionData.accessToken);
    const selectedPage = pageList.find(page => page.id === facebookPage?.id);
    if (!selectedPage) {
      return Promise.reject(InstagramActivationErrors.InvalidInstagramAccount);
    }

    if (!selectedPage.connected_instagram_account || !selectedPage.instagram_business_account) {
      return Promise.reject(InstagramActivationErrors.NoBindedPagesFound);
    }

    const instagramDetails = {
      instagramBusinessAccountId: selectedPage.instagram_business_account.id,
      userName: selectedPage.connected_instagram_account.username,
      pageAccessToken: selectedPage.access_token,
      pageId: selectedPage.id,
    } as InstagramBusinessAccountDetails;

    return instagramDetails;
  };

  const handleActivate = async () => {
    track(CONNECT_INSTAGRAM_LINK_ACCOUNTS_CONFIRMED);

    if (!connectionData) {
      return setCurrentStep(InstagramSteps.ErrorPage);
    }

    try {
      setIsLoading(true);
      const accountDetails = await validateInstagramPageAndReturnDetails(connectionData);
      await activateInstagram(botConfiguration.botId, connectionData.userID, accountDetails);
      await enableStoryReply(botConfiguration.botId);
      setInstaUsername(facebookPage?.connected_instagram_account?.username!);
      await updateUserInstagramStatusAndData(botConfiguration, accountDetails);
      track(CONNECT_INSTAGRAM_CONNECTION_SUCCESSFUL, {
        instagramConnected: true,
        instagramUserName: facebookPage?.connected_instagram_account?.username,
      });
      createToastSuccess('Instagram ativado com sucesso!');
      DataLayer().TriggerInstagramConversion();
      navigate('/dashboard');
    } catch (error) {
      if (error === InstagramActivationErrors.NoBindedPagesFound) {
        if (modalRef.current) {
          track(CONNECT_INSTAGRAM_PAGE_ERROR, { error: 'No binded pages found' });
          modalRef.current.toggle?.();
        }
        return;
      }

      if (error === InstagramActivationErrors.InvalidInstagramAccount) {
        return setCurrentStep(InstagramSteps.ErrorPage);
      }
      if (error === InstagramActivationErrors.NoMessagesAccess) {
        return setCurrentStep(InstagramSteps.InstagramAllowMessagesAccess);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return preview ? (
    <Preview pageName={facebookPage?.name} buttonCallback={trackAndShowTutorial} />
  ) : (
    <>
      <BdsGrid class="step-cotainer" gap="2" direction="column">
        <BdsTypo variant="fs-24" tag="h3" bold="bold" lineHeight="plus">
          Vincule sua página do Facebook a um Instagram em 7 passos.
        </BdsTypo>
        <BdsTypo tag="p">
          1 - Acesse sua página no <b>Facebook</b>:
        </BdsTypo>
        <BdsButton variant="tertiary" onClick={accessFacebookPage} arrow>
          Acessar página do Facebook
        </BdsButton>
        <BdsTypo tag="p">
          2 - Clique em “Configurações” no canto esquerdo da tela, mesmo que esse item esteja desabilitado (cinza
          claro).
        </BdsTypo>
        <BdsGrid gap="2" direction={isMobile ? 'column' : 'row'}>
          <img src={FbConfigDisabled} alt="Facebook Configuração Desabilitada" />
          <img src={FbConfigEnabled} alt="Facebook Configuração Habilitada" />
        </BdsGrid>
        <BdsTypo tag="p">
          3 - Caso o item “Configurações” esteja desabilitado, clique em “Switch” ou “Alterar” no modal que irá
          aparecer.
        </BdsTypo>
        <div>
          <img src={SwitchAccounts} alt="Facebook Switch Accounts" />
        </div>
        <BdsTypo tag="p">4 - Clique em “Instagram” no menu lateral esquerdo.</BdsTypo>
        <div>
          <img src={BindedAccounts} alt="Facebook Binded Accounts" />
        </div>
        <BdsTypo tag="p">5 - Clique em “Conectar Conta”</BdsTypo>
        <div>
          <img src={ConnectAccount} alt="Facebook Connect Account" />
        </div>
        <BdsTypo tag="p">
          6 - Siga todos os passos e faça login com a conta do Instagram em que você deseja ativar seu assistente.
        </BdsTypo>
        <BdsGrid gap="2" direction={isMobile ? 'column' : 'row'}>
          <img src={ConfirmBind} alt="Facebook Confirm Bind" />
          <img src={AllowMessages} alt="Facebook Allow Messages" />
        </BdsGrid>
        <BdsTypo tag="p">
          7 - Quando finalizar, é só voltar aqui e confirmar que terminou de vincular as contas.
        </BdsTypo>
        <BdsButton bdsLoading={isLoading} onClick={handleActivate} arrow>
          Finalizar
        </BdsButton>
        <div>
          <BdsTypo variant="fs-12" margin={false} italic>
            Finalize quando as páginas
          </BdsTypo>
          <BdsTypo variant="fs-12" margin={false} italic>
            já estiverem vinculadas.
          </BdsTypo>
        </div>
      </BdsGrid>
      <InstagramFailedBindPagesModal ref={modalRef} />
    </>
  );
}

function Preview({ pageName, buttonCallback }: { pageName: string | undefined; buttonCallback: () => void }) {
  const { track } = useTrack();

  useEffect(() => {
    track(CONNECT_INSTAGRAM_NO_LINKED_ACCOUNTS_PAGE);
  }, []);

  return (
    <BdsGrid gap="2" direction="column">
      <BdsTypo variant="fs-24" tag="h3" lineHeight="plus" bold="bold">
        Agora é preciso vincular a página {pageName ?? ''} a um perfil de Instagram.
      </BdsTypo>
      <BdsGrid gap="2">
        <BdsIcon name="facebook" />
        <BdsTypo>
          Esse processo precisa ser feito <b>direto no Facebook.</b>
        </BdsTypo>
      </BdsGrid>
      <BdsGrid gap="2">
        <BdsIcon name="eye-open" />
        <BdsTypo>
          Para fazer o processo, <b>leia com cuidado o passo a passo</b> a seguir.
        </BdsTypo>
      </BdsGrid>
      <BdsButton onClick={buttonCallback} arrow>
        Como vincular com o Instagram
      </BdsButton>
    </BdsGrid>
  );
}

export default InstagramBindPages;
