import { currentActivationStep } from 'atoms/AppAtoms';
import { BdsGrid, BdsPaper } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { FAQ_NEWFAQ_STARTED } from 'libs/trackingEvents';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Step from '../Step';
import StepDone from '../StepDone';
import { BotStatus } from 'types/Bot';

function FaqStep() {
  const [, setCurrentStep] = useAtom(currentActivationStep);
  const { botConfiguration, faqs } = useAppContext();
  const navigate = useNavigate();
  const { track } = useTrack();
  const isDone = faqs.length > 0;
  const stepTwoDone = botConfiguration.status === BotStatus.Tested;
  const stepThreeDone = botConfiguration.whatsApp?.length > 0;

  const trackAndNavigate = () => {
    track(FAQ_NEWFAQ_STARTED);
    navigate('/faq-wizard');
  };

  const description =
    'Para começar vamos criar uma <b>base de conhecimento</b> com o que vou saber responder sobre sua empresa';

  useEffect(() => {
    if (faqs.length > 0 && !stepTwoDone) {
      setCurrentStep(2);
    } else if (faqs.length > 0 && stepTwoDone) {
      setCurrentStep(3);
    }
  }, [faqs, setCurrentStep]);

  return isDone ? (
    <>
      <BdsPaper elevation="none" class="paper-pattern">
        <BdsGrid gap="2" direction="column" padding="2">
          <StepDone step={1} text="Assuntos salvos com sucesso!" />
          {stepTwoDone && <StepDone step={2} text="Teste feito com sucesso!" />}
          {stepThreeDone && <StepDone step={3} text="WhatsApp salvo com sucesso!" />}
        </BdsGrid>
      </BdsPaper>
    </>
  ) : (
    <>
      <Step
        number={1}
        buttonArrow={true}
        title="O que preciso saber sobre sua loja?"
        description={description}
        buttonLabel="Iniciar"
        onClickCallback={trackAndNavigate}
      />
    </>
  );
}

export default FaqStep;
