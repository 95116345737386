type PluginProps = {
  language: string;
  owneridentity: string;
  token: string;
  botname: string;
  channelsactive: string;
  cluster: string;
  plan: string;
  template: string;
  blipdomainurl: string;
  blipwebsockethostname: string;
  blipwebsockethostnametenant: string;
  portaldomain: string;
  authemail: string;
  featureflags: any;
  settings: string;
};

function handleUrl(routeMFE: string) {
  const state = { page: window.location.pathname.split('/').pop(), data: { parameters: window.location.search } };
  const newURL = `${state.page}/${routeMFE}${state.data.parameters}`;
  history.pushState(state, '', newURL);
}

export function InjectMicrofrontEnd(
  idContainer: string,
  urlScript: string,
  urlLink: string,
  tagMfe: string,
  pluginProps: PluginProps,
  routePM: string,
  routeMFE: string,
) {
  const idScriptImport = `${idContainer}-script-mfe`;

  const injectMfe = () => {
    const container = window.document.getElementById(idContainer);
    if (container) {
      const idMfe = `${container.id}-fragment`;

      let mfe = container.ownerDocument.getElementById(idMfe);

      if (mfe) {
        mfe.remove();
      }

      mfe = document.createElement(tagMfe);
      mfe.id = idMfe;
      mfe.setAttributeNS(null, 'owneridentity', pluginProps.owneridentity);
      mfe.setAttributeNS(null, 'language', pluginProps.language);
      mfe.setAttributeNS(null, 'settings', pluginProps.settings);
      mfe.setAttributeNS(null, 'token', `${pluginProps.token}`);
      mfe.setAttributeNS(null, 'botname', pluginProps.botname);
      mfe.setAttributeNS(null, 'channelsactive', pluginProps.channelsactive);
      mfe.setAttributeNS(null, 'cluster', pluginProps.cluster);
      mfe.setAttributeNS(null, 'plan', pluginProps.plan);
      mfe.setAttributeNS(null, 'template', pluginProps.template);
      mfe.setAttributeNS(null, 'blipdomainurl', pluginProps.blipdomainurl);
      mfe.setAttributeNS(null, 'blipwebsockethostname', pluginProps.blipwebsockethostname);
      mfe.setAttributeNS(null, 'blipwebsockethostnametenant', pluginProps.blipwebsockethostnametenant);
      mfe.setAttributeNS(null, 'portaldomain', pluginProps.portaldomain);
      mfe.setAttributeNS(null, 'authemail', pluginProps.authemail);
      mfe.setAttributeNS(null, 'featureflags', JSON.stringify(pluginProps.featureflags));
      mfe.setAttributeNS(null, 'basepath', routePM);
      container.appendChild(mfe);

      // observer para alterar o título do MFE
      const observer = new MutationObserver(() => {
        const titleElement = container?.querySelector('bds-typo');
        if (titleElement && titleElement.textContent?.includes('Click Tracker')) {
          titleElement.textContent = 'Gerenciador de anúncios';
        }
      });

      observer.observe(container, { childList: true, subtree: true });

      handleUrl(routeMFE);
    }
  };

  if (!window.document.getElementById(idScriptImport)) {
    const script = document.createElement('script');
    script.id = idScriptImport;
    script.src = urlScript;
    script.onload = () => {
      injectMfe();
    };
    document.body.appendChild(script);
  } else {
    injectMfe();
  }

  const idLinkImport = `${idContainer}-link-mfe`;
  if (!window.document.getElementById(idLinkImport) && urlLink) {
    const link = document.createElement('link');
    link.id = idLinkImport;
    link.href = urlLink;
    link.rel = 'stylesheet';
    document.body.appendChild(link);
  }
}
