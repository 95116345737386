// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h-3 {
  height: 3rem;
}

.modal-button {
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramFailedBindPagesModal/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".h-3 {\n  height: 3rem;\n}\n\n.modal-button {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
