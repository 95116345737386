import { PageTitles } from 'types/FaqPage';

export const FAQ_TEXT_MAP: { [key: number]: PageTitles } = {
  0: {
    headerTitle: 'Base de conhecimento',
    pageTitle: 'Assuntos salvos:',
    text: 'Aqui você pode criar, editar ou excluir assuntos que preciso saber sobre sua loja:',
    cancelModalTitle: '',
    cancelModalSubtitle: '',
  },
  1: {
    headerTitle: 'Adicionar assunto',
    pageTitle: '',
    text: '',
    cancelModalTitle: 'Deseja cancelar o novo assunto?',
    cancelModalSubtitle: 'Se algum conteúdo foi adicionado, ele não será salvo.',
  },
  2: {
    headerTitle: 'Editar assunto',
    pageTitle: '',
    text: '',
    cancelModalTitle: 'Deseja cancelar alterações?',
    cancelModalSubtitle: 'Qualquer alteração feita será perdida.',
  },
};
