import DashboardHeader from 'components/DashboardHeader';
import MainContainer from 'components/MainContainer';
import MainHeader from 'components/MainHeader';
import Sidebar from 'components/Sidebar';
import React from 'react';

function Config() {
  return (
    <MainContainer>
      <Sidebar />
      <MainHeader
        title="Configuração do assistente"
        description="Gerencie as informações que ajudam seu assistente a responder melhor."
      />
      <DashboardHeader />
    </MainContainer>
  );
}

export default Config;
