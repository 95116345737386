import { BdsGrid, BdsLoadingSpinner, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import useBlipGoApi from 'hooks/useBlipGoApi';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { ContactStatus, HUBSPOT_ASSIST_PARAMETER } from 'libs/hubspotParameters';
import { INSTALLATION, START_INSTALLATION } from 'libs/trackingEvents';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function AsyncPreparation() {
  const navigate = useNavigate();
  const [installed, setInstalled] = useState(false);
  const { createBotConfigurations, initialBotConfiguration } = usePlgApi();
  const { installPack, pollInstallationStatusWithRetries, updateHubspotContact } = usePacksApi();
  const { createToastError } = useToast();
  const { botConfiguration, setBotConfiguration } = useAppContext();
  const { track } = useTrack();
  const { delegateBot } = useBlipGoApi();

  const createUserBotData = async () => {
    try {
      track(START_INSTALLATION);
      const pack = await installPack();
      await createBotConfigurations(initialBotConfiguration(pack.routerShortName));
      await pollInstallationStatusWithRetries(pack.routerShortName, pack.installationId);
      await delegateBot();
      setBotConfiguration(initialBotConfiguration(pack.routerShortName));
      await updateHubspotContact(HUBSPOT_ASSIST_PARAMETER, ContactStatus.Installed, pack.routerShortName);
      return navigate('/dashboard');
    } catch (error) {
      track(INSTALLATION, { status: 'Error', error: JSON.stringify(error) });
      createToastError('Erro ao criar o assistente', 'Por favor, atualize a página para tentar novamente');
    }
  };

  useEffect(() => {
    if (botConfiguration?.botId) {
      delegateBot();
      return navigate('/dashboard');
    } else if (!installed) {
      setInstalled(true);
      createUserBotData();
    }
  }, []);

  return (
    <div className="prep-container prep-label">
      <BdsGrid
        gap="2"
        direction="column"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        xxs="12"
        justifyContent="center"
        alignItems="center"
      >
        <BdsLoadingSpinner />
        <BdsTypo variant="fs-20" tag="h1">
          Quase lá! Estamos deixando tudo pronto com seu assistente.
        </BdsTypo>
      </BdsGrid>
    </div>
  );
}

export default AsyncPreparation;
