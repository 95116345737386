import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import React from 'react';

type StepDoneProps = {
  step: number;
  text: string;
};

function StepDone({ step, text }: StepDoneProps) {
  return (
    <BdsGrid gap="3">
      <BdsIcon name="checkball" theme="solid" style={{ color: '#84EBBC' }} />
      <div>
        <BdsTypo variant="fs-14" bold="bold">
          Passo {step} concluído
        </BdsTypo>
        <BdsTypo variant="fs-14">{text}</BdsTypo>
      </div>
    </BdsGrid>
  );
}

export default StepDone;
