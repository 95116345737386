// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templates-table {
  background-color: white;
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/ActiveMessages/MessageTemplates/style.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;AACF","sourcesContent":[".templates-table {\n  background-color: white;\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
