import {
  BdsButton,
  BdsChipTag,
  BdsGrid,
  BdsIcon,
  BdsModal,
  BdsSelect,
  BdsSelectOption,
  BdsStep,
  BdsStepper,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import useGraphApi from 'hooks/useGraphApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import * as trackingEvents from 'libs/trackingEvents';
// import { MODAL_FACEBOOK_CONNECT_CATALOG } from 'libs/trackingEvents';
import React, { Ref, forwardRef, useEffect, useState } from 'react';
import { Business, Catalog, UserData } from 'types/Facebook';
import { isEmpty } from 'utils/assertions';
import './style.scss';

function BuildSelects({ list = [] }: { list: Business[] | Catalog[] }) {
  return list.map(item => (
    <BdsSelectOption key={item.id} value={JSON.stringify(item)}>
      {item.name}
    </BdsSelectOption>
  ));
}

const FacebookCatalogModal = forwardRef(function FacebookCatalogModal(
  props: { modalToggleCallback: () => Promise<void> | undefined },
  ref: Ref<HTMLBdsModalElement>,
) {
  const { botConfiguration } = useAppContext();
  const {
    initFacebookSdk,
    launchFacebookSignup,
    getUser,
    getUserBusinesses,
    getProductCatalogsFromBusiness,
    getProductsFromCatalog,
  } = useGraphApi();
  const [step, setStep] = useState(1);
  const { track } = useTrack();
  const { patchProducts, disableCatalog } = usePlgApi();
  const { setFacebookUserData, facebookUserData } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [catalogs, setCatalogs] = useState([] as Catalog[]);
  const [businesses, setBusinesses] = useState([] as Business[]);
  const { createToastError, createToastSuccess } = useToast();

  const handleConnectCatalog = async () => {
    // track(MODAL_FACEBOOK_CONNECT_CATALOG);
    await initFacebookSdk(botConfiguration.botId);
    await launchFacebookSignup('catalog_connection', result => getUserData(result?.authResponse?.accessToken));
  };

  const getUserData = async (accessToken: string) => {
    if (!accessToken) {
      createToastError('Login interrompido', 'Por favor, prossiga com o login para conectar seu catálogo');
      return;
    }
    const user = await getUser(accessToken);
    const businesses = await getUserBusinesses(user.id, accessToken);
    if (businesses.length === 0) {
      createToastError(
        'Nenhuma organização encontrada',
        'Por favor, utilize uma conta com acesso ao Gerenciador de Negócios do Facebook',
      );
      return;
    }
    setBusinesses(businesses);
    setAccessToken(accessToken);
    setFacebookUserData(userData => ({ ...userData, user: user }));
    setStep(2);
  };

  const saveCatalogsFromBusiness = async (business: Business) => {
    const catalogs = await getProductCatalogsFromBusiness(business.id, accessToken);
    setCatalogs(catalogs);
    setFacebookUserData(userData => ({ ...userData, business: business }));
  };

  const saveProductsFromCatalog = async () => {
    setLoading(true);
    try {
      const products = await getProductsFromCatalog(facebookUserData.catalog.id, accessToken);
      await patchProducts(facebookUserData.catalog.id, products, {
        business: facebookUserData.business,
        catalog: facebookUserData.catalog,
        user: facebookUserData.user,
      });
      setFacebookUserData(facebookUserData => ({ ...facebookUserData, isCatalogSubmitted: true }));
      createToastSuccess('Produtos salvos com sucesso!');
      track(trackingEvents.MODAL_FACEBOOK_CONFIRM_CATALOG, { status: 'Success' });
      props.modalToggleCallback();
    } catch (error: any) {
      const err = error as Error;
      track(trackingEvents.MODAL_FACEBOOK_CONFIRM_CATALOG, { status: 'Error', error: err.message });
      createToastError('Erro ao salvar os produtos', 'Tente novamente em alguns instantes');
    } finally {
      setLoading(false);
    }
  };

  const disconnectUser = async () => {
    setLoading(true);
    try {
      await disableCatalog();
      setStep(1);
      setFacebookUserData({} as UserData);
      setCatalogs([]);
      createToastSuccess('Usuário desconectado com sucesso!');
      track(trackingEvents.MODAL_FACEBOOK_DISCONNECT_CATALOG, { status: 'Success' });
      const modalRef = document.getElementById('fb-modal') as HTMLBdsModalElement;
      modalRef?.toggle();
    } catch (error) {
      createToastError('Não foi possível desconectar no momento', 'Tente novamente em alguns instantes');
      track(trackingEvents.MODAL_FACEBOOK_DISCONNECT_CATALOG, { status: 'Error', error: error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const businessSelectListener = document.getElementById('business-select');
    businessSelectListener?.addEventListener('optionSelected', (event: any) => {
      saveCatalogsFromBusiness(JSON.parse(event.detail.value));
    });

    const catalogSelectListener = document.getElementById('catalog-select');
    catalogSelectListener?.addEventListener('optionSelected', (event: any) => {
      setFacebookUserData(userData => ({ ...userData, catalog: JSON.parse(event.detail.value) }));
    });

    return () => {
      businessSelectListener?.removeEventListener('optionSelected', (event: any) => {
        saveCatalogsFromBusiness(JSON.parse(event.detail.value));
      });
      catalogSelectListener?.removeEventListener('optionSelected', (event: any) => {
        setFacebookUserData(userData => ({ ...userData, catalogSelected: JSON.parse(event.detail.value) }));
      });
    };
  }, [step, catalogs?.length]);

  return (
    <BdsModal id="fb-modal" ref={ref} size="dynamic">
      <BdsTypo variant="fs-24" bold="bold">
        Último passo: conectar seu catálogo com seu assistente!
      </BdsTypo>

      {step === 1 && (
        <>
          <BdsStepper>
            <BdsStep active={true}>Autenticar pessoa usuária</BdsStep>
            <BdsStep last={true}>Organização e Catálogo</BdsStep>
          </BdsStepper>
          <br />
          <BdsTypo variant="fs-20" bold="bold">
            Primeiro, faça o login no Facebook
          </BdsTypo>
          <BdsTypo>
            Para fazer a conexão do catálogo, entre em sua conta no Facebook para liberar o acesso do sistema ao
            gerenciador de negócios do Facebook.
          </BdsTypo>
          <br />
          <BdsButton type-icon="logo" icon="facebook" onClick={() => handleConnectCatalog()}>
            Conectar-se ao Facebook
          </BdsButton>
        </>
      )}

      {step === 2 && (
        <>
          <BdsStepper>
            <BdsStep>Autenticar pessoa usuária</BdsStep>
            <BdsStep active={true} last={true}>
              Organização e Catálogo
            </BdsStep>
          </BdsStepper>
          <br />
          <BdsTypo variant="fs-20" tag="h4" bold="bold">
            Agora é só selecionar sua organização e catálogo
          </BdsTypo>
          <br />
          <BdsTypo variant="fs-14">
            Confira as organizações que sua conta do Facebook tem acesso e escolha o catálogo que vai ser conectado ao
            assistente virtual.
          </BdsTypo>

          <BdsGrid xs="12" justifyContent="space-around" margin="t-4">
            <BdsGrid margin="t-2" xs="4" direction="column" gap="3" justifyContent="center" alignItems="center">
              <BdsTypo bold="semi-bold">{facebookUserData.user?.name}</BdsTypo>
              <BdsChipTag color="success">Conectado</BdsChipTag>
              <br />
              <BdsGrid xs="4" margin="t-1" justifyContent="center" alignItems="center">
                <BdsButton bdsLoading={loading} onClick={() => disconnectUser()} variant="tertiary">
                  Desconectar
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
            <div className="vertical-line" />
            <BdsGrid xs="8" direction="column" gap="2" justifyContent="center" alignItems="center">
              <div className="selects">
                <BdsSelect id="business-select" label="Organização">
                  <BuildSelects list={businesses} />
                </BdsSelect>
                <BdsTooltip
                  position="right-bottom"
                  tooltipText="Verifique suas permissões no Gerenciador de Negócios ou conecte-se a outra conta."
                >
                  <BdsGrid gap="1" alignItems="center">
                    <BdsTypo variant="fs-12">Não encontrei a organização</BdsTypo>
                    <BdsIcon name="info" size="x-small" />
                  </BdsGrid>
                </BdsTooltip>
              </div>
              <div className="selects">
                {!isEmpty(catalogs) && (
                  <>
                    <BdsSelect id="catalog-select" label="Catálogo">
                      <BuildSelects list={catalogs} />
                    </BdsSelect>
                    <BdsTooltip
                      position="right-bottom"
                      tooltipText="Verifique suas permissões no Gerenciador de Negócios ou conecte-se a outra conta."
                    >
                      <BdsGrid gap="1" alignItems="center">
                        <BdsTypo variant="fs-12">Não encontrei o catálogo</BdsTypo>
                        <BdsIcon name="info" size="x-small" />
                      </BdsGrid>
                    </BdsTooltip>
                  </>
                )}
              </div>
              <BdsGrid margin="l-2" xs="8" justifyContent="flex-end">
                <BdsButton
                  disabled={isEmpty(facebookUserData.catalog)}
                  bdsLoading={loading}
                  onClick={() => {
                    !isEmpty(facebookUserData.catalog) && saveProductsFromCatalog();
                  }}
                >
                  Conectar Catálogo
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
          </BdsGrid>
        </>
      )}
    </BdsModal>
  );
});

export default FacebookCatalogModal;
