import Button from '@mui/material/Button';
import { BdsIcon } from 'blip-ds/dist/blip-ds-react/components';
import React, { ReactNode } from 'react';
import './style.scss';

type MaterialButtonProps = {
  children?: ReactNode;
  text: string;
  variant?: string;
  endIconBdsName?: string;
  startIconBdsName?: string;
  disabled?: boolean;
  isMobileView?: boolean;
  cssVariant?: string;
  loading?: boolean;
  onClickCallback: () => void;
};

function MaterialButton({
  text,
  variant,
  endIconBdsName,
  startIconBdsName,
  disabled,
  isMobileView,
  cssVariant,
  loading,
  onClickCallback,
}: MaterialButtonProps) {
  const cssClass = `material-button ${variant} ${isMobileView ? 'w-100' : cssVariant}`;
  const color = variant === 'primary' ? 'white' : '';
  const endIcon = endIconBdsName ? <BdsIcon color={color} name={endIconBdsName} type="icon" /> : null;
  const startIcon = startIconBdsName ? <BdsIcon color={color} name={startIconBdsName} type="icon" /> : null;

  return (
    <Button
      onClick={() => onClickCallback()}
      className={cssClass}
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      loading={loading}
    >
      {text}
    </Button>
  );
}

export default MaterialButton;
