import { Divider, Drawer } from '@mui/material';
import { BdsButtonIcon, BdsGrid, BdsIcon, BdsSelect, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import './style.scss';
import ContactInfo from './ContactInfo/ContactInfo';
import MaterialButton from 'components/UI/MaterialButton';
import useTrack from 'hooks/useTrack';
import {
  COEX_OPEN_CONTACT_GROUP_DROWPDOWN,
  COEX_REQUEST_NEW_GROUP_CLICK,
  COEX_SELECT_CONTACT_GROUP,
  COEX_SEND_MESSAGE_CONTACT,
} from 'libs/trackingEvents';
import { DEFAULT_CONTACT_GROUPS } from 'libs/contacts';
import useBlipGoApi from 'hooks/useBlipGoApi';
import { UpdateContact } from 'types/Contact';

interface IContactDrawerProps {
  contactIdentity: string;
  isDrawerOpen: boolean;
  onClose: () => void;
  contactName: string;
  contactPhone: string;
  lastUpdateDate: string | Date;
  group: string;
  onGroupChange: (contact: UpdateContact) => void;
}

function ContactDrawer({
  contactIdentity,
  isDrawerOpen,
  onClose,
  contactName,
  contactPhone,
  lastUpdateDate,
  group,
  onGroupChange,
}: Readonly<IContactDrawerProps>) {
  const { track } = useTrack();
  const { updateContact } = useBlipGoApi();

  const handleOptionChange = (ev: CustomEvent) => {
    const updatedContact = { identity: contactIdentity, extras: { coexGroup: ev.detail.value } };
    onGroupChange(updatedContact);
    updateContact(updatedContact);
    track(COEX_SELECT_CONTACT_GROUP);
  };

  const handleSupportRequest = () => {
    track(COEX_REQUEST_NEW_GROUP_CLICK);
  };

  const openWhatsApp = () => {
    track(COEX_SEND_MESSAGE_CONTACT);
    const url = `https://wa.me/${contactPhone}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={onClose}>
      <BdsGrid padding="3" className="contact-drawer-container">
        <BdsGrid direction="column" padding="x-2" gap="3">
          <BdsGrid justifyContent="space-between" alignItems="center">
            <BdsTypo variant="fs-20" bold="bold" margin={false}>
              Contato
            </BdsTypo>
            <BdsButtonIcon size="short" variant="secondary" icon="close" onBdsClick={onClose} />
          </BdsGrid>

          <ContactInfo contactName={contactName} contactPhone={contactPhone} lastUpdateDate={lastUpdateDate} />
          <Divider />
          <BdsTypo variant="fs-16" bold="bold">
            Segmentação
          </BdsTypo>

          <BdsSelect
            data-testid="group-selector"
            label="Grupo"
            value={group}
            placeholder="Selecione um grupo"
            onClick={() => track(COEX_OPEN_CONTACT_GROUP_DROWPDOWN)}
            onBdsChange={handleOptionChange}
            options={DEFAULT_CONTACT_GROUPS}
          ></BdsSelect>
          <BdsGrid gap="1" alignItems="center" className="contact-drawer-tooltip">
            <BdsIcon name="info" size="small" />
            <BdsTypo variant="fs-12">
              Criamos esses grupos como padrão. Se precisar editar ou criar novos,{' '}
              <a
                onClick={handleSupportRequest}
                target="_blank"
                href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_COEX_SUPPORT_PHONE}&text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20o%20Blip%20Go!%20COEX%20Beta`}
              >
                informe nosso suporte
              </a>
              .
            </BdsTypo>
          </BdsGrid>

          <Divider />
          <MaterialButton
            isMobileView
            variant="secondary"
            startIconBdsName="message-sent"
            text="Enviar mensagem"
            onClickCallback={openWhatsApp}
          />
        </BdsGrid>
      </BdsGrid>
    </Drawer>
  );
}

export default ContactDrawer;
