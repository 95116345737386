import { BdsGrid, BdsInput, BdsRadio, BdsRadioGroup } from 'blip-ds/dist/blip-ds-react/components';
import FaqChatBubble from 'components/FaqWizard/FaqChatBubble';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useToast from 'hooks/useToast';
import React, { useEffect, useRef, useState } from 'react';
import { Faq, WizardTempFaq } from 'types/Faq';

type FaqInputCatalogProps = {
  tempFaq: WizardTempFaq | null;
  onNextClickCallback: (faq: WizardTempFaq | null) => void;
};

function FaqInputCatalog({ tempFaq, onNextClickCallback }: FaqInputCatalogProps) {
  const isMobileView = useMobileView();
  const { createToastWarning } = useToast();
  const [showInputText, setShowInputText] = useState(false);
  const [selectedOption, setSelectedOption] = useState(tempFaq?.selectedOption);
  const [isFaqValid, setIsFaqValid] = useState(false);
  const inputAnswerRef = useRef<HTMLBdsInputElement>(null);

  const defaultQuestion = 'Onde posso ver uma lista dos seus produtos?';

  const handleNextClick = () => {
    let tempFaq = { selectedOption } as WizardTempFaq;
    const randomId = crypto.randomUUID();
    switch (selectedOption) {
      case 'yes':
        const link = inputAnswerRef.current?.value as string;
        tempFaq.faq = {
          id: randomId,
          question: defaultQuestion,
          answer: link,
        } as Faq;
        onNextClickCallback(tempFaq);
        break;
      case 'no':
        tempFaq.faq = {
          id: randomId,
          question: defaultQuestion,
          answer: 'Não temos uma lista de produtos',
        } as Faq;
        onNextClickCallback(tempFaq);
        break;
      default:
        createToastWarning('Opção inválida', 'Por favor, selecione uma opção para continuar');
        break;
    }
  };

  const validateInputs = async () => {
    const isValid = selectedOption === 'no' || (await inputAnswerRef.current?.isValid());
    setIsFaqValid(isValid || false);
  };

  useEffect(() => {
    const clearForm = () => {
      tempFaq?.faq && (tempFaq.faq.answer = '');
      inputAnswerRef?.current?.clear();
    };

    const radio = document.querySelector('#rbg-options') as HTMLBdsInputElement;
    radio.addEventListener('bdsRadioGroupChange', (ev: any) => {
      clearForm();
      const option = ev?.detail?.value;
      setSelectedOption(option);
      if (option === 'yes') {
        setShowInputText(true);
        setIsFaqValid(false);
      } else {
        setShowInputText(false);
        setIsFaqValid(true);
      }
    });
  }, [selectedOption, tempFaq?.faq]);

  useEffect(() => {
    setShowInputText(selectedOption === 'yes');
    setIsFaqValid(selectedOption === 'no' || !!tempFaq?.faq?.answer);
  }, [selectedOption, tempFaq]);

  return (
    <>
      <FaqChatBubble
        contentHTML={'Você tem algum <b>catálogo ou site</b> com informações sobre seus <b>produtos ou serviços</b> ?'}
      />
      <BdsGrid padding="l-1">
        <BdsRadioGroup id="rbg-options">
          <BdsGrid direction="column" gap="1">
            <BdsRadio value="yes" label="Sim" onBdsClickChange={() => tempFaq?.selectedOption === 'yes'} />
            <BdsRadio value="no" label="Não" onBdsClickChange={() => tempFaq?.selectedOption === 'no'} />
          </BdsGrid>
        </BdsRadioGroup>
      </BdsGrid>
      {showInputText && (
        <>
          <FaqChatBubble contentHTML={'Qual é o link?'} />
          <BdsGrid padding="l-1" xxs="12">
            <BdsInput
              id="answer-input"
              ref={inputAnswerRef}
              onBdsChange={validateInputs}
              isTextarea
              required
              counterLength
              rows={3}
              maxlength={300}
              placeholder="Coloque aqui o link do seu catálogo ou site"
              requiredErrorMessage="Este campo é obrigatório"
            />
          </BdsGrid>
        </>
      )}
      <BdsGrid xxs="12" margin="t-4" gap="2" direction="column-reverse">
        <MaterialButton
          onClickCallback={handleNextClick}
          text="Confirmar e continuar"
          variant="primary"
          endIconBdsName="arrow-right"
          isMobileView={isMobileView}
          disabled={!isFaqValid}
        ></MaterialButton>
      </BdsGrid>
    </>
  );
}

export default FaqInputCatalog;
