import { MOBILE_BREAKPOINT } from 'libs/breakpoints';
import React, { createContext, useContext, useEffect, useState } from 'react';

type MobileViewProvider = {
  children: React.ReactNode;
};

const MobileViewContext = createContext<boolean>(false);

export const MobileViewProvider: React.FC<MobileViewProvider> = ({ children }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <MobileViewContext.Provider value={isMobileView}>{children}</MobileViewContext.Provider>;
};

export const useMobileView = () => useContext(MobileViewContext);
