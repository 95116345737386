import { useAppContext } from 'contexts/AppContext';
import * as plgApiRoutes from 'libs/plgApiRoutes';
import { useAuth } from 'oidc-react';
import { ILogArgs } from 'packs-template-baseweb';
import { useCallback, useMemo } from 'react';
import { BotConfiguration, BotStatus } from 'types/Bot';
import { Channel, ChannelConfiguration, ChannelStatus } from 'types/Channels';
import { Faq } from 'types/Faq';
import { InstagramBusinessAccountDetails } from 'types/Instagram';
import { isEmpty } from 'utils/assertions';
import { RequestErrorBoundary } from 'utils/request';
import useTrack from './useTrack';
import { HubspotAssociation, HubspotContact, HubspotDeal, HubspotDealSearch } from 'types/Hubspot';
import { HUBSPOT_DEAL_SEARCH_PARAMS, HUBSPOT_DEAL_SEARCH_PIPELINE } from 'libs/hubspotParameters';

const logArgs: ILogArgs = {
  className: 'usePlgApi',
};

const usePlgApi = () => {
  const { botConfiguration, setBotConfiguration } = useAppContext();
  const { userData } = useAuth();

  const header = useMemo(() => {
    return {
      Authorization: `bearer ${userData?.access_token}`,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    };
  }, [userData?.access_token]);

  const initialBotConfiguration = useCallback(
    (botId: string, whatsapp?: string): BotConfiguration => {
      return {
        botId: botId,
        status: BotStatus.Created,
        whatsApp: whatsapp || '',
        companySite: (userData?.profile.CompanySite as string) ?? '',
        welcomeMessage: '',
        businessName: '',
        agentVoiceTom: 'Amigável e Profissional',
        stopMessage: 'Vou chamar um atendente para auxiliar com esse tema. Aguarde um momento, por favor',
        marketplace: 'customerService',
        maxInterations: 4,
        maxMessages: 10,
      };
    },
    [userData?.profile],
  );

  const getFaq = useCallback(
    async (botId: string): Promise<{ botId: string; questionAnswers: Faq[]; enabled: boolean } | undefined> => {
      logArgs.methodName = 'getFaq';
      try {
        const url = plgApiRoutes.GET_FAQ(botId);
        const res = await fetch(url, { headers: header, cache: 'no-cache' });
        return await RequestErrorBoundary(res, logArgs);
      } catch {
        return undefined;
      }
    },
    [header],
  );

  const patchFaq = useCallback(
    async (faqs: Faq[]): Promise<void> => {
      const url = plgApiRoutes.PATCH_FAQ;
      logArgs.methodName = 'patchFaq';
      const body = {
        botId: botConfiguration.botId,
        questionAnswers: faqs,
        enabled: !isEmpty(faqs),
      };

      try {
        const res = await fetch(url, { method: 'PATCH', body: JSON.stringify(body), headers: header });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const disableFaq = useCallback(async () => {
    logArgs.methodName = 'disableFaq';
    const url = plgApiRoutes.PATCH_FAQ;
    const body = {
      botId: botConfiguration.botId,
      enabled: false,
    };
    try {
      const res = await fetch(url, { method: 'PATCH', body: JSON.stringify(body), headers: header });
      return await RequestErrorBoundary(res, logArgs);
    } catch (error) {
      return Promise.reject();
    }
  }, [botConfiguration?.botId, header]);

  const createBotConfigurations = useCallback(
    async (botConfiguration: BotConfiguration) => {
      logArgs.methodName = 'createBotConfigurations';
      try {
        const res = await fetch(plgApiRoutes.BOT_CONFIGURATIONS, {
          method: 'POST',
          body: JSON.stringify(botConfiguration),
          headers: header,
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [header],
  );

  const getBotConfiguration = useCallback(
    async (botId: string): Promise<BotConfiguration | undefined> => {
      logArgs.methodName = 'getBotConfiguration';
      try {
        const url = plgApiRoutes.GET_BOT_CONFIGURATIONS(botId);
        const res = await fetch(url, { headers: header, cache: 'no-cache' });
        return await RequestErrorBoundary(res, logArgs);
      } catch {
        return undefined;
      }
    },
    [header],
  );

  const setBotStatus = useCallback(
    async (botStatus: BotStatus) => {
      logArgs.methodName = 'setBotStatus';
      try {
        const res = await fetch(plgApiRoutes.SET_BOT_STATUS(botConfiguration.botId, botStatus), {
          method: 'PATCH',
          headers: header,
        });
        setBotConfiguration(botConfiguration => ({ ...botConfiguration, status: botStatus }));
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header, setBotConfiguration],
  );

  const patchBotConfigurations = useCallback(
    async (botConfiguration: BotConfiguration) => {
      logArgs.methodName = 'patchBotConfigurations';
      try {
        const url = plgApiRoutes.BOT_CONFIGURATIONS;
        const res = await fetch(url, { method: 'PATCH', headers: header, body: JSON.stringify(botConfiguration) });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [header],
  );

  const getPromptByBotId = useCallback(
    async (botId: string): Promise<Prompt> => {
      logArgs.methodName = 'getPromptByBotId';
      const url = plgApiRoutes.GET_PROMPT_BY_BOT_ID(botId);
      try {
        const res = await fetch(url, { method: 'GET', headers: header });

        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [header],
  );

  const saveWhatsAppNumber = useCallback(
    async (number: string) => {
      logArgs.methodName = 'saveWhatsAppNumber';
      try {
        const url = plgApiRoutes.BOT_CONFIGURATIONS;
        const body: BotConfiguration = { ...botConfiguration, whatsApp: number };
        const res = await fetch(url, { method: 'PATCH', headers: header, body: JSON.stringify(body) });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration, header],
  );

  const getChannelData = useCallback(
    async (botId: string): Promise<ChannelConfiguration[] | undefined> => {
      logArgs.methodName = 'saveWhatsAppNumber';
      try {
        const url = plgApiRoutes.GET_CHANNEL(botId);
        const res = await fetch(url, { headers: header, cache: 'no-cache' });
        return (await RequestErrorBoundary(res, logArgs)) as ChannelConfiguration[];
      } catch (error) {
        const err = error as Error;
        return undefined;
      }
    },
    [botConfiguration?.botId, header],
  );

  const saveInstagramData = useCallback(
    async (instagramData: InstagramBusinessAccountDetails) => {
      logArgs.methodName = 'saveInstagramData';
      const url = plgApiRoutes.SET_CHANNEL;
      const body: ChannelConfiguration = {
        botId: botConfiguration.botId,
        channel: Channel.Instagram,
        status: ChannelStatus.Active,
        externalSettings: JSON.stringify(instagramData),
      };

      try {
        const res = await fetch(url, { method: 'POST', headers: header, body: JSON.stringify(body) });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const chatGpt = useCallback(
    async (botId: string, message: OpenAiChatMessage[]): Promise<OpenaiResponse | undefined> => {
      logArgs.methodName = 'chatGpt';
      const url = plgApiRoutes.OPENAI_CHAT;
      const body = {
        user: botId,
        messages: message,
        temperature: 0.3,
        top_p: 0.2,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 200,
      };

      try {
        const res = await fetch(url, { method: 'POST', body: JSON.stringify(body), headers: header });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        return undefined;
      }
    },
    [header],
  );

  const setSubscription = useCallback(
    async (signerInfo: SignerInfo) => {
      logArgs.methodName = 'setSubscription';
      try {
        const res = await fetch(plgApiRoutes.SET_SUBSCRIPTION, {
          method: 'POST',
          headers: header,
          body: JSON.stringify(signerInfo),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const updateSubscription = useCallback(
    async (subscriptionId: string, signerInfo: SignerInfo) => {
      logArgs.methodName = 'updateSubscription';
      try {
        const res = await fetch(plgApiRoutes.PATCH_SUBSCRIPTION(subscriptionId), {
          method: 'PATCH',
          headers: header,
          body: JSON.stringify(signerInfo),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const getSubscriptionByEmail = useCallback(
    async (email: string): Promise<SignerInfo> => {
      logArgs.methodName = 'getSubscriptionByEmail';
      try {
        const url = plgApiRoutes.GET_SUBSCRIPTION_BY_EMAIL(email);
        const res = await fetch(url, { headers: header, cache: 'no-cache' });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err);
      }
    },
    [botConfiguration?.botId, header],
  );

  const setHubspotContact = useCallback(
    async (contactProperties: HubspotContact) => {
      logArgs.methodName = 'setHubspotContact';
      try {
        const res = await fetch(plgApiRoutes.SET_HUBSPOT_CONTACT, {
          method: 'POST',
          headers: header,
          body: JSON.stringify(contactProperties),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const setHubspotDeal = useCallback(
    async (hubspotDeal: HubspotDeal) => {
      logArgs.methodName = 'setHubspotDeal';
      try {
        const res = await fetch(plgApiRoutes.SET_HUBSPOT_DEAL, {
          method: 'POST',
          headers: header,
          body: JSON.stringify(hubspotDeal),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const updateHubspotDeal = useCallback(
    async (dealId: string, hubspotDeal: HubspotDeal) => {
      logArgs.methodName = 'updateHubspotDeal';
      try {
        const res = await fetch(plgApiRoutes.UPDATE_HUBSPOT_DEAL(dealId), {
          method: 'PUT',
          headers: header,
          body: JSON.stringify(hubspotDeal),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const associateHubspotDeal = useCallback(
    async (dealId: string, hubspotAssociation: HubspotAssociation) => {
      logArgs.methodName = 'associateHubspotDeal';
      try {
        const res = await fetch(plgApiRoutes.ASSOCIATE_HUBSPOT_CONTACT(dealId), {
          method: 'PUT',
          headers: header,
          body: JSON.stringify(hubspotAssociation),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const getHubspotContactByProperty = useCallback(
    async (propertyName: string, propertyValue: string) => {
      logArgs.methodName = 'getHubspotContactByProperty';
      try {
        const url = plgApiRoutes.GET_HUBSPOT_CONTACT_BY_PROPERTY(propertyName, propertyValue);
        const res = await fetch(url, { headers: header, cache: 'no-cache' });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err);
      }
    },
    [botConfiguration?.botId, header],
  );

  const searchHubspotDeal = useCallback(
    async (contactId: string) => {
      logArgs.methodName = 'searchHubspotDeal';

      const filter: HubspotDealSearch = {
        filterGroups: [
          {
            filters: [
              {
                propertyName: HUBSPOT_DEAL_SEARCH_PARAMS.contactAssociation,
                operator: HUBSPOT_DEAL_SEARCH_PARAMS.defaultOperator,
                value: contactId,
              },
              HUBSPOT_DEAL_SEARCH_PIPELINE,
            ],
          },
        ],
      };

      try {
        const res = await fetch(plgApiRoutes.SEARCH_HUBSPOT_DEAL, {
          method: 'POST',
          headers: header,
          body: JSON.stringify(filter),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  const updateHubspotContact = useCallback(
    async (contactId: string, contactProperties: HubspotContact) => {
      logArgs.methodName = 'updateHubspotContact';
      try {
        const res = await fetch(plgApiRoutes.UPDATE_HUBSPOT_CONTACT(contactId), {
          method: 'PATCH',
          headers: header,
          body: JSON.stringify(contactProperties),
        });
        return await RequestErrorBoundary(res, logArgs);
      } catch (error) {
        const err = error as Error;
        return Promise.reject(err.message);
      }
    },
    [botConfiguration?.botId, header],
  );

  return {
    getFaq,
    patchFaq,
    disableFaq,
    createBotConfigurations,
    initialBotConfiguration,
    getBotConfiguration,
    patchBotConfigurations,
    setBotStatus,
    getPromptByBotId,
    saveWhatsAppNumber,
    getChannelData,
    saveInstagramData,
    chatGpt,
    setSubscription,
    getSubscriptionByEmail,
    setHubspotContact,
    setHubspotDeal,
    updateHubspotDeal,
    associateHubspotDeal,
    updateSubscription,
    searchHubspotDeal,
    getHubspotContactByProperty,
    updateHubspotContact,
  };
};

export default usePlgApi;
