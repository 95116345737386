import { BdsGrid, BdsInput, BdsModal, BdsRadio, BdsRadioGroup, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { ContactStatus, HUBSPOT_ASSIST_PARAMETER } from 'libs/hubspotParameters';
import { PAUSE_ASSISTANT_CLOSED, PAUSE_ASSISTANT_CONFIRMED } from 'libs/trackingEvents';
import React, { Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { BotStatus } from 'types/Bot';

const PauseAssistantModal = forwardRef(function InsertDataModal(props: {}, ref: Ref<HTMLBdsModalElement>) {
  const [selectedReason, setSelectedReason] = useState('');
  const [showInputReason, setShowInputReason] = useState(false);
  const { botConfiguration } = useAppContext();
  const { setBotStatus } = usePlgApi();
  const { updateHubspotContact } = usePacksApi();
  const { createToastError, createToastSuccess, createToastWarning } = useToast();
  const { track } = useTrack();
  const modalRef = document.querySelector('#pause-modal') as HTMLBdsModalElement;
  const inputReasonRef = useRef<HTMLBdsInputElement>(null);

  const handlePauseAssistantClick = async () => {
    if (!selectedReason) {
      createToastWarning('Por favor, informe o motivo');
      return;
    }
    try {
      const reasonText = inputReasonRef.current?.value ? inputReasonRef.current.value : undefined;
      await setBotStatus(BotStatus.Paused);
      track(PAUSE_ASSISTANT_CONFIRMED, { status: 'Success', reasonPaused: selectedReason, others: reasonText });
      updateHubspotContact(HUBSPOT_ASSIST_PARAMETER, ContactStatus.Paused, botConfiguration.botId);
      createToastSuccess('Assistente pausado com sucesso');
    } catch (error) {
      track(PAUSE_ASSISTANT_CONFIRMED, {
        status: 'Error',
        error: JSON.stringify(error),
      });
      createToastError('Não foi possível realizar esta ação no momento', 'Tente novamente mais tarde');
    } finally {
      modalRef?.toggle();
    }
  };

  const handleCancelClick = () => {
    track(PAUSE_ASSISTANT_CLOSED);
    modalRef?.toggle();
  };

  useEffect(() => {
    const radio = document.querySelector('#rbg-reasons') as HTMLBdsInputElement;
    radio.addEventListener('bdsRadioGroupChange', (ev: any) => {
      const reason = ev?.detail?.value;
      setSelectedReason(reason);
      if (reason === 'Outro motivo') {
        setShowInputReason(true);
      } else {
        setShowInputReason(false);
      }
    });
  }, [selectedReason]);

  return (
    <BdsModal id="pause-modal" close-button={true} ref={ref} size="dynamic">
      <BdsGrid direction="column" gap="none">
        <BdsTypo variant="fs-20" bold="bold">
          Porque você está pausando seu assistente?
        </BdsTypo>
        <BdsTypo variant="fs-16">Isso vai nos ajudar a melhorar o produto.</BdsTypo>
        <BdsRadioGroup id="rbg-reasons">
          <BdsGrid direction="column" gap="1" margin="t-3">
            <BdsRadio
              value="Não tenho segurança com o assistente no meu WhatsApp"
              label="Não tenho segurança com o assistente no meu WhatsApp."
            />
            <BdsRadio value="Tive um problema com o produto" label="Tive um problema com o produto." />
            <BdsRadio
              value="Só estou testando, não sei se quero usar"
              label="Só estou testando, não sei se quero usar."
            />
            <BdsRadio value="Outro motivo" label="Outro motivo." />
          </BdsGrid>
        </BdsRadioGroup>
        {showInputReason && (
          <BdsGrid margin="t-3">
            <BdsInput
              id="reason-input"
              ref={inputReasonRef}
              isTextarea
              counterLength
              rows={3}
              maxlength={300}
              placeholder="Conte aqui seu motivo:"
            />
          </BdsGrid>
        )}
        <BdsGrid margin="t-3" gap="1" direction="column">
          <BdsGrid md="12">
            <MaterialButton
              onClickCallback={() => handlePauseAssistantClick()}
              text="Pausar assistente"
              variant="primary"
              isMobileView={true}
            ></MaterialButton>
          </BdsGrid>
          <BdsGrid md="12">
            <MaterialButton
              onClickCallback={() => handleCancelClick()}
              text="Cancelar"
              variant="tertiary"
              isMobileView={true}
            ></MaterialButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsModal>
  );
});

export default PauseAssistantModal;
