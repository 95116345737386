// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  max-width: 81.25rem;
}

@media (max-width: 768px) {
  .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .menu-wrapper {
    padding: 0 0.75rem 0px 0.75rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/FacebookCatalog/style.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;;AAIA;EACE;IACE,0BAAA;IACA,2BAAA;EADF;EAGA;IACE,8BAAA;EADF;AACF","sourcesContent":["@use '/src/styles/colors';\n\n.content {\n  max-width: 81.25rem;\n}\n\n@media (max-width: 768px) {\n  .content {\n    padding-left: 0 !important;\n    padding-right: 0 !important;\n  }\n  .menu-wrapper {\n    padding: 0 0.75rem 0px 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
