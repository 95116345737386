import { BdsChipClickable, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import './style.scss';

interface ITemplateCreatorInputProps {
  textOutputEvent: (text: string) => void;
}

function TemplateCreatorInput({ textOutputEvent }: ITemplateCreatorInputProps) {
  const [content, setContent] = useState<string>('');
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    textOutputEvent(content);
  }, [content]);

  const toggleFormat = (format: string) => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const currentFormat = quill.getFormat();
      quill.format(format, !currentFormat[format]);
    }
  };

  const handleAddVariable = () => {
    const quillEditor = quillRef.current?.getEditor();
    if (!quillEditor) return;

    const getExistingVariableNumbers = (textContent: string): number[] => {
      const variablePattern = /\{\{(\d+)\}\}/g;
      return Array.from(textContent.matchAll(variablePattern)).map(match => parseInt(match[1], 10));
    };

    const getNextVariableNumber = (existingNumbers: number[]): number => {
      for (let candidateNumber = 1; ; candidateNumber++) {
        if (!existingNumbers.includes(candidateNumber)) return candidateNumber;
      }
    };

    const existingVariableNumbers = getExistingVariableNumbers(content);
    const nextVariableNumber = getNextVariableNumber(existingVariableNumbers);

    quillEditor.focus();
    const insertionIndex = quillEditor.getSelection()?.index ?? quillEditor.getLength();
    quillEditor.insertText(insertionIndex, `{{${nextVariableNumber}}}`);
  };

  return (
    <BdsGrid direction="column" gap="2" style={{ width: '100%' }}>
      <BdsTypo variant="fs-14">Digite o texto da mensagem</BdsTypo>
      <ReactQuill
        ref={quillRef}
        value={content || ''}
        onChange={setContent}
        modules={{
          toolbar: false,
        }}
        placeholder="Ex.: Olá {{1}}, podemos retomar nosso contato?"
        className="custom-quill"
        id="template-input-quill-editor"
      />
      <BdsGrid gap="1">
        <BdsChipClickable
          id="bold-format-btn"
          color="outline"
          clickable
          onChipClickableClick={() => toggleFormat('bold')}
        >
          <BdsIcon size="x-small" name="text-style-bold" />
        </BdsChipClickable>
        <BdsChipClickable
          id="italic-format-btn"
          color="outline"
          clickable
          onChipClickableClick={() => toggleFormat('italic')}
        >
          <BdsIcon size="x-small" name="text-style-italic" />
        </BdsChipClickable>
        <BdsChipClickable
          id="strike-format-btn"
          color="outline"
          clickable
          onChipClickableClick={() => toggleFormat('strike')}
        >
          <BdsIcon size="x-small" name="text-style-strikethrough" />
        </BdsChipClickable>
        <BdsChipClickable color="outline" clickable onChipClickableClick={handleAddVariable}>
          +variável
        </BdsChipClickable>
      </BdsGrid>
    </BdsGrid>
  );
}

export default TemplateCreatorInput;
