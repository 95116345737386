import React from 'react';
import botAvatar from '../../../assets/images/bot-avatar.svg';
import './style.scss';

const BotAvatar = () => {
  return (
    <div className="avatar">
      <img src={botAvatar} />
    </div>
  );
};
export default BotAvatar;
