import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import parse from 'html-react-parser';
import { COEX_HELP_CLICKED } from 'libs/trackingEvents';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './style.scss';

interface IMainHeaderProps {
  title: string;
  description: string;
  secondaryTitle?: string;
  hideHelpButton?: boolean;
}

function MainHeader({ title, description, secondaryTitle, hideHelpButton }: Readonly<IMainHeaderProps>) {
  const isMobile = useMobileView();
  const { track } = useTrack();
  const location = useLocation();
  const fontSize = isMobile ? 'fs-20' : 'fs-24';

  const handleLinkClick = () => {
    track(COEX_HELP_CLICKED, { screenName: location.pathname });
    const coexLink = `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_COEX_SUPPORT_PHONE}&text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20o%20Blip%20Go!%20COEX%20Beta`;
    window.open(coexLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <BdsGrid justifyContent="space-between" alignItems="center">
      <BdsGrid direction="column" gap="1">
        <BdsGrid gap="1">
          {secondaryTitle && <BdsTypo margin={false} variant={fontSize}>{`${secondaryTitle} /`}</BdsTypo>}
          <BdsTypo variant={fontSize} bold="bold" margin={false}>
            {title}
          </BdsTypo>
        </BdsGrid>
        <BdsTypo variant="fs-14" className="main-header-description">
          {parse(description)}
        </BdsTypo>
      </BdsGrid>
      {!isMobile && !hideHelpButton && (
        <MaterialButton
          onClickCallback={handleLinkClick}
          text="Preciso de ajuda"
          startIconBdsName="agent"
          variant="secondary"
        />
      )}
    </BdsGrid>
  );
}

export default MainHeader;
