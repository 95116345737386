export const QUESTION_OPTIONS = [
  {
    question: 'O que você mais precisa resolver?',
    options: [
      {
        label: 'Responder a comentários em posts',
        description: 'Não deixar nenhum comentário no meu feed sem resposta para garantir público engajado.',
      },
      {
        label: 'Entender meu público',
        description: 'Descobrir dados sobre meu público para saber como vender e engajar melhor.',
      },
      {
        label: 'Quero saber como aumentar o público e alcance do meu Insta',
        description: 'Dicas sobre o que fazer para crescer o engajamento e público do Instagram.',
      },
      {
        label: 'Levar tráfego do meu Instagram para o WhatsApp',
        description: 'Levar meu público para o meu WhatsApp para realizar vendas com quem está interessado.',
      },
    ],
  },
];
