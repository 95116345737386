import { ILogArgs, logger } from 'packs-template-baseweb';

export async function RequestErrorBoundary(response: Response, logArgs: ILogArgs): Promise<any> {
  if (!response.ok) {
    const errorText = await response.text();
    const errorMessage = `Request to ${response.url} failed with status ${response.status}: ${errorText}`;

    if (response.status === 404) {
      logger.warn(errorMessage, logArgs);
      return null;
    }

    if (response.status === 500) {
      logger.error(errorMessage, logArgs);
    } else {
      logger.warn(errorMessage, logArgs);
    }
    return Promise.reject(new Error(errorMessage));
  }

  const contentType = response.headers.get('content-type');
  if (contentType?.includes('application/json')) {
    return response.json();
  } else {
    return response.text();
  }
}
