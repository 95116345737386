export const ESQUENTATOR_ACCESSED = 'blippacks-esquentator-plg-linkopened';
export const START_INSTALLATION = 'blippacks-esquentator-plg-installation-started';
export const INSTALLATION = 'blippacks-esquentator-plg-installation-status';
export const OPENED = 'blippacks-esquentator-plg-opened';
export const USER_LOGO = 'blippacks-esquentator-plg-user';
export const LOGOUT = 'blippacks-esquentator-plg-logout';
export const ADD_QUESTION_FAQ = 'blippacks-esquentator-plg-addquestion';
export const SAVE_QUESTION_FAQ = 'blippacks-esquentator-plg-savedquestion';
export const EDIT_QUESTION_FAQ = 'blippacks-esquentator-plg-editquestion';
export const DELETE_ICON_QUESTION_FAQ = 'blippacks-esquentator-plg-deletequestion';
export const DELETE_CONFIRM_QUESTION_FAQ = 'blippacks-esquentator-plg-deletequestion-confirmed';
export const FACEBOOK_POPUP_ABANDON = 'blippacks-esquentator-plg-instagram-connection-closed';
export const FACEBOOK_POPUP_ABANDON_CONFIRM = 'blippacks-esquentator-plg-instagram-connection-close-confirmed';
export const CONNECT_INSTAGRAM = 'blippacks-esquentator-plg-instagramconnect';
export const CONNECT_INSTAGRAM_NO_LINKED_ACCOUNTS_PAGE = 'blippacks-channel-connection-instagram-linked-accounts';
export const CONNECT_INSTAGRAM_LINK_ACCOUNTS = 'blippacks-channel-connection-instagram-how-link-accounts';
export const CONNECT_INSTAGRAM_LINK_ACCOUNTS_CONFIRMED =
  'blippacks-channel-connection-instagram-how-link-accounts-finished';
export const CONNECT_INSTAGRAM_ACCESS_FACEBOOK_PAGE = 'blippacks-channel-connection-instagram-acessed-page';
export const CONNECT_INSTAGRAM_CLICK_CONNECT = 'blippacks-channel-connection-instagram-click-connect';
export const CONNECT_INSTAGRAM_VIEWPAGE_CONNECT = 'blippacks-channel-connection-instagram-viewpage-connect';
export const CONNECT_INSTAGRAM_CONNECTION = 'blippacks-channel-connection-instagram-connection';
export const CONNECT_INSTAGRAM_CONNECTION_SUCCESSFUL = 'blippacks-channel-connection-instagram-connection-successful';
export const CONNECT_INSTAGRAM_PAGE_NOTFOUND_CLICK = 'blippacks-esquentator-plg-instagram-page-notfound-clicked';
export const CONNECT_INSTAGRAM_PAGE_NOTFOUND_CHANGE_USER =
  'blippacks-esquentator-plg-instagram-page-not-found-modal-change-user';
export const CONNECT_INSTAGRAM_PAGE_NOTFOUND_CREATE_PAGE =
  'blippacks-esquentator-plg-instagram-page-not-found-modal-create-new-page';
export const CONNECT_INSTAGRAM_PAGE_ERROR = 'blippacks-esquentator-plg-instagram-page-error';
export const CONNECT_INSTAGRAM_PAGE_ERROR_BACK = 'blippacks-esquentator-plg-instagram-page-error-back';
export const CONNECT_INSTAGRAM_CREATE_FB_PAGE = 'blippacks-esquentator-plg-instagram-page-error-createFB';
export const CONNECT_INSTAGRAM_CONNECTION_ERROR = 'blippacks-channel-connection-instagram-connection-error';
export const CONNECT_INSTAGRAM_CHANGED_ACCOUNT = 'blippacks-channel-connection-instagram-changed-account';
export const CONNECT_INSTAGRAM_CHANGED_ACCOUNT_CONNECT_FB =
  'blippacks-channel-connection-instagram-changed-account-connect-fb';
export const CONNECT_INSTAGRAM_CHANGED_ACCOUNT_FB_LINK =
  'blippacks-channel-connection-instagram-changed-account-fb-link';
export const CONNECT_INSTAGRAM_CHOOSE_OTHER_PAGE = 'blippacks-channel-connection-instagram-chose-anotherpage';
export const CONNECT_INSTAGRAM_CHOOSE_OTHER_PAGE_DOCUMENTATION = 'blippacks-channel-connection-instagram-documentation';
export const CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR = 'blippacks-channel-connection-instagram-error';
export const CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR_ACCESS_FB =
  'blippacks-channel-connection-instagram-error-access-fb';
export const CONNECT_INSTAGRAM_CONNECTION_INSTAGRAM_ERROR_TRYAGAIN =
  'blippacks-channel-connection-instagram-error-try-again';
export const CONNECT_CATALOG = 'blippacks-esquentator-plg-connected-catalog';
export const CONNECT_CATALOG_FACEBOOK = 'blippacks-esquentator-plg-connectcatalog-fb';
export const CONNECt_CATALOG_CHANGED_ACCOUNT_FB_LINK =
  'blippacks-esquentator-plg-connectcatalog-catalogfb-change-acc-fblink';
export const CONNECt_CATALOG_CHANGED_ACCOUNT_CHANGE_CLICK =
  'blippacks-esquentator-plg-connectcatalog-catalogfb-change-acc-connect';
export const CONNECT_CATALOG_ERROR = 'blippacks-esquentator-plg-connect-catalog-error';
export const CONNECT_CATALOG_ERROR_TRY_AGAIN = 'blippacks-esquentator-plg-connect-catalog-error-try-again';
export const CONNECT_CATALOG_CHANGE_ACCOUNT = 'blippacks-esquentator-plg-connect-catalog-error-change-account';
export const CONNECT_CATALOG_CONFIRM_CATALOG = 'blippacks-esquentator-plg-connectcatalog-catalog-fb';
export const MODAL_FACEBOOK_CONNECT_CATALOG = 'blippacks-esquentator-plg-connectcatalog-fb';
export const MODAL_FACEBOOK_CONFIRM_CATALOG = 'blippacks-esquentator-plg-connectcatalog-catalogfb';
export const MODAL_FACEBOOK_DISCONNECT_CATALOG = 'blippacks-esquentator-plg-connectcatalog-disconnectfb';
export const UPDATE_CATALOG = 'blippacks-esquentator-plg-connectcatalog-catalogfb-updated';
export const DISCONNECT_CATALOG = 'blippacks-esquentator-plg-disconnectcatalog';
export const EDIT_WPP = 'blippacks-esquentator-plg-edit-wpp-number';
export const CANCEL_WPP_INSERTON = 'blippacks-esquentator-plg-whatsappregister-canceled';
export const CONFIRM_WPP_INSERTION = 'blippacks-esquentator-plg-whatsappregister-confirmed';
export const PAUSE_ASSISTANT = 'blippacks-esquentator-plg-assistantpaused';
export const PAUSE_ASSISTANT_CONFIRMED = 'blippacks-esquentator-plg-assistantpause-confirmed';
export const PAUSE_ASSISTANT_CLOSED = 'blippacks-esquentator-plg-assistantpause-closed';
export const RESUME_ASSISTANT = 'blippacks-esquentator-plg-assistantresumed';
export const ASSISTANT_ACTIVATED = 'blippacks-esquentator-plg-assistant-activated';
export const BLIPCHAT = 'blippacks-esquentator-plg-blipchat';
export const REACTIVATE_ADD_FAQ = 'blippacks-esquentator-plg-modal-activeassist-addtopic';
export const REACTIVATE_ADD_CATALOG = 'blippacks-esquentator-plg-modal-activeassist-catalogconnected';
export const FAQ_HELP_MODAL_BUTTON = 'blippacks-esquentator-plg-faqvideo-watched';
export const FAQ_HELP_MODAL_CLOSE = 'blippacks-esquentator-plg-faqvideo-closed';
export const FAQ_NEWFAQ_STARTED = 'blippacks-esquentator-plg-newfaq-started';
export const FAQ_NEWFAQ_NEXTQUESTIONS = 'blippacks-esquentator-plg-newfaq-nextquestions';
export const FAQ_NEWFAQ_CLOSED = 'blippacks-esquentator-plg-newfaq-closed';
export const FAQ_NEWFAQ_CLOSE_CONFIRMED = 'blippacks-esquentator-plg-newfaq-close-confirmed';
export const FAQ_NEWFAQ_CONTINUE = 'blippacks-esquentator-plg-newfaq-continue';
export const FAQ_NEWFAQ_CONTINUE_ANSWER = 'blippacks-esquentator-plg-newfaq-continue-answer';
export const FAQ_NEWFAQ_CANCEL_MODAL_CONFIRMED = 'blippacks-esquentator-plg-newfaq-cancel-confirmed';
export const FAQ_NEWFAQ_CANCEL_CLICKED = 'blippacks-esquentator-plg-newfaq-canceled';
export const FAQ_NEWFAQ_CANCEL = 'blippacks-esquentator-plg-newfaq-cancel';
export const CONSULTING_MODAL_SHOWED = 'blippacks-esquentator-plg-consulting-modal-showed';
export const CONSULTING_MODAL_SENT = 'blippacks-esquentator-plg-consulting-modal-sent';
export const CONSULTING_MODAL_CANCELLED = 'blippacks-esquentator-plg-consulting-modal-cancelled';
export const ACTIVE_MESSAGE = 'blippacks-esquentator-plg-active-notification';
export const TEST_ASSISTANT_CLICKED = 'blippacks-esquentator-plg-assistant-tested';
export const TEST_ASSISTANT_END_TEST_CLICKED = 'blippacks-esquentator-plg-exited-assistant-test';
export const TEST_ASSISTANT_OPENED_POPUP = 'blippacks-esquentator-plg-assistant-pop-up-showed';
export const TEST_ASSISTANT_ANSWERED_POPUP = 'blippacks-esquentator-plg-assistant-answered-pop-up';
export const SURVEY_BEST_STRATEGY_ANSWER = 'blippacks-esquentator-plg-survey-best-strategy';
export const SURVEY_AUTOMATIC_MESSAGES_INFO = 'blippacks-esquentator-plg-automatic-messages-info';
export const SURVEY_AUTOMATIC_MESSAGES = 'blippacks-esquentator-plg-automatic-messages';

export const COEX_QUICK_ACCESS_MODULE = 'blippacks-esquentator-plg-quick-access-module-accessed';
export const COEX_QUICK_ACCESS_MODULE_CREATE = 'blippacks-esquentator-plg-active-message-create';
export const COEX_QUICK_ACCESS_MODULE_SEND = 'blippacks-esquentator-plg-active-message-send';
export const COEX_MODULE_ADS = 'blippacks-esquentator-plg-click-tracker';

export const COEX_CAMPAIGN_SELECT_TEMPLATE = 'blippacks-management-general-active-message-selected-model';
export const COEX_CAMPAIGN_NEXT_BUTTON = 'blippacks-management-general-active-message-next';
export const COEX_CAMPAIGN_BACK_BUTTON = 'blippacks-management-general-active-message-back';
export const COEX_CAMPAIGN_SUBMIT_AUDIENCE = 'blippacks-management-general-active-message-upload-ctt';
export const COEX_CAMPAIGN_DOWNLOAD_TEMPLATE_LINK = 'blippacks-management-general-active-message-download-xlsx';
export const COEX_CAMPAIGN_CONFIRM = 'blippacks-management-general-active-message-send';
export const COEX_CAMPAIGN_CANCEL_BUTTON = 'blippacks-management-general-active-message-cancel';
export const COEX_CAMPAIGN_DIRECTIONING = 'blippacks-management-general-active-message-response-targeting';
export const COEX_CAMPAIGN_EDIT_BUTTON = 'blippacks-management-general-active-message-edit';
export const COEX_CAMPAIGN_CONFIRM_CANCELLING_ALERT = 'blippacks-management-general-active-message-cancel-modal';
export const COEX_CONNECTION_START = 'blippacks-coex-connection-start-connection';
export const COEX_HELP_CLICKED = 'blippacks-coex-general-help';
export const COEX_BUSINESS_ACTIVE = 'blippacks-coex-connection-active-number';
export const COEX_CANCEL_CONNECTION = 'blippacks-coex-connection-cancel-connection';
export const COEX_META_MANAGER_ACCESS = 'blippacks-coex-connection-access-manager';
export const COEX_META_CONNECTION_COMPLETED_CLOSE = 'blippacks-coex-connection-close-banner-connection';
