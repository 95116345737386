import { BdsChipTag, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { useMobileView } from 'contexts/MobileViewContext';
import dayjs from 'dayjs';
import style from './CampaignAudienceItem.module.scss';

import React from 'react';

interface CampaignAudienceItemProps {
  campaign: {
    name: string;
    created: Date | string;
    scheduled: Date | string;
    campaignStatus: string;
    campaignType: string;
    total: number;
    processed: number;
    received: number;
    read: number;
    failed: number;
  };
}

const getCampaignStatusText = (status: string) => {
  switch (status) {
    case 'executed':
      return 'Enviada';
    case 'scheduling':
      return 'Agendada';
    case 'failed':
      return 'Falha';
    case 'processing':
      return 'Processando';
    default:
      return status;
  }
};

function CampaignAudienceItem({ campaign }: CampaignAudienceItemProps) {
  const isMobileView = useMobileView();
  const formattedDate = dayjs(campaign.scheduled ?? campaign.created).format('DD/MM/YYYY HH:mm');

  return (
    <BdsPaper className={style['campaign-item-container']} elevation="none">
      <BdsGrid
        padding="y-2"
        justifyContent="space-between"
        alignItems={!isMobileView ? 'center' : 'baseline'}
        flexWrap="wrap"
        direction={!isMobileView ? 'row' : 'column'}
        gap="1"
      >
        <BdsGrid direction="column" md="3">
          <BdsTypo variant="fs-12" className={style['data-content']}>
            Nome do disparo
          </BdsTypo>
          <BdsTypo variant="fs-16" bold="bold" lineHeight="double">
            {campaign.name}
          </BdsTypo>
        </BdsGrid>

        <BdsGrid md="4" sm="12" xs="12" direction="row" gap="2">
          <BdsGrid direction="column" md="auto">
            <BdsTypo variant="fs-12">Audiência</BdsTypo>
            <BdsTypo variant="fs-14" bold="semi-bold" lineHeight="double">
              {campaign.total} {campaign.total === 1 ? 'contato' : 'contatos'}
            </BdsTypo>
          </BdsGrid>

          <BdsGrid direction="column" xs="auto" md="auto" justifyContent="space-between">
            <BdsTypo variant="fs-12">Falhas</BdsTypo>
            <BdsTypo variant="fs-14" bold="semi-bold" lineHeight="double">
              {campaign.failed}
            </BdsTypo>
          </BdsGrid>

          <BdsGrid direction="column" sm="auto" xs="auto" md="auto">
            <BdsTypo variant="fs-12">Recebidas</BdsTypo>
            <BdsGrid gap="half">
              <BdsTypo variant="fs-14" bold="semi-bold" lineHeight="double">
                {campaign.received}
              </BdsTypo>
              <BdsIcon name="double-check"></BdsIcon>
            </BdsGrid>
          </BdsGrid>

          <BdsGrid direction="column" sm="auto" xs="auto" md="auto">
            <BdsTypo variant="fs-12">Lidas</BdsTypo>
            <BdsGrid gap="half">
              <BdsTypo variant="fs-14" bold="semi-bold" lineHeight="double">
                {campaign.read}
              </BdsTypo>
              <BdsIcon className={style['icon-color']} name="double-check"></BdsIcon>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>

        <BdsGrid direction="column" md="2" margin={!isMobileView ? 'l-2' : 'none'}>
          <BdsTypo variant="fs-12">Data do envio</BdsTypo>
          <BdsTypo variant="fs-14" bold="semi-bold" lineHeight="double">
            {formattedDate}
          </BdsTypo>
        </BdsGrid>

        <BdsGrid md="2" justifyContent={!isMobileView ? 'flex-end' : 'flex-start'}>
          <BdsChipTag color="outline" icon="checkball">
            {getCampaignStatusText(campaign.campaignStatus)}
          </BdsChipTag>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
}

export default CampaignAudienceItem;
