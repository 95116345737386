import { signerInfo } from 'atoms/AppAtoms';
import { BdsGrid, BdsProgressBar, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { useAppContext } from 'contexts/AppContext';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { COEX_INSTALLATION_STATUS } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlipGoCoexLogo from '../../../assets/images/blipgo-coex-logo.svg';
import Hourglass from '../../../assets/images/hourglass.svg';
import './style.scss';
import { HUBSPOT_CUSTOM_PARAMS_INSTALL, WhatsappConnectionStatus } from 'libs/hubspotParameters';

function PackInstallProgress() {
  const navigate = useNavigate();
  const { createToastError } = useToast();
  const { track } = useTrack();
  const { installPack, poll } = usePacksApi();
  const {
    initialBotConfiguration,
    createBotConfigurations,
    updateSubscription,
    getSubscriptionByEmail,
    updateHubspotDeal,
  } = usePlgApi();
  const { setBotConfiguration } = useAppContext();
  const [progressPercent, setProgressPercent] = useState(0);
  const userEmail = useAuth().userData?.profile.email as string;

  useEffect(() => {
    const createUserData = async () => {
      await createUserBotData();
    };

    createUserData();
  }, []);

  useEffect(() => {
    if (progressPercent === 99) return;
    const timer = setInterval(() => {
      setProgressPercent(prevPercent => prevPercent + 1);
    }, 350);
    return () => clearInterval(timer);
  }, [progressPercent]);

  const createUserBotData = async () => {
    try {
      const pack = await installPack();
      const botConfigurations = initialBotConfiguration(pack.routerShortName);
      setBotConfiguration(botConfigurations);
      await createBotConfigurations(botConfigurations);
      await poll(pack.installationId);
      const res = await getSubscriptionByEmail(userEmail);
      await updateSubscription(res.subscriptionId as string, {
        botId: pack.routerShortName,
      });
      // TODO: Remove comments when the HubSpot integration goes live
      //await updateHubspotDeal(res.dealId as string, HUBSPOT_CUSTOM_PARAMS_INSTALL);
      setProgressPercent(100);
      track(COEX_INSTALLATION_STATUS, { status: 'success' });
      return navigate('/dashboard');
    } catch (error) {
      createToastError('Erro ao criar o assistente', 'Por favor, tente novamente.');
      track(COEX_INSTALLATION_STATUS, { status: 'Error', error: JSON.stringify(error) });
      navigate('/onboarding/fail');
    }
  };

  return (
    <BdsGrid justifyContent="center">
      <BdsGrid direction="column" className="main-container onboarding">
        <BdsGrid justifyContent="center" alignItems="center">
          <img src={BlipGoCoexLogo} alt="BlipGO CoEX logo" />
        </BdsGrid>
        <BdsGrid direction="column" justifyContent="center" alignItems="center" gap="4" padding="t-8">
          <img src={Hourglass} />
          <BdsGrid direction="column" justifyContent="center" alignItems="center" gap="3">
            <BdsTypo variant="fs-32" bold="bold" margin={false} className="install-info-title">
              Quase lá! Estamos finalizando a configuração da sua conta Blip Coex
            </BdsTypo>
            <BdsTypo variant="fs-16">Este processo pode levar alguns minutos.</BdsTypo>
          </BdsGrid>

          <BdsGrid direction="column" gap="1" className="install-progress-bar">
            <BdsProgressBar size="default" percent={progressPercent} className="install-progress-bar"></BdsProgressBar>
            <BdsGrid>
              <BdsTypo bold="bold" variant="fs-12">
                {progressPercent}% concluído, configurando sua experiência...
              </BdsTypo>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
}

export default PackInstallProgress;
