import { BdsChipTag, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import useTrack from 'hooks/useTrack';
import { MAX_FAQ_QUESTIONS } from 'libs/app';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function FaqCard() {
  const { faqs } = useAppContext();
  const { track } = useTrack();
  const navigate = useNavigate();
  const remainingQuestions = MAX_FAQ_QUESTIONS - faqs.length;
  const hasSubmittedFaq = faqs.length > 0;

  const navigateToFaqRoute = () => {
    if (faqs.length > 0) {
      navigate('/faq');
    } else {
      navigate('/faq-wizard');
    }
  };

  return (
    <BdsPaper class="paper-pattern" elevation="none">
      <BdsGrid id="faq-papercard" padding="3">
        <BdsGrid direction="column" gap="2">
          <BdsTypo variant="fs-24" tag="h3" bold="bold" margin={false}>
            Base de conhecimento
          </BdsTypo>
          {hasSubmittedFaq ? (
            <BdsChipTag icon="info">
              você pode adicionar mais {remainingQuestions} {remainingQuestions > 1 ? 'assuntos' : 'assunto'}
            </BdsChipTag>
          ) : (
            <BdsChipTag icon="info" color="outline">
              sem assuntos adicionados
            </BdsChipTag>
          )}
          <BdsTypo variant="fs-14" tag="p" id="description" margin={false}>
            Confira e altere os assuntos que vou responder no seu inbox.
          </BdsTypo>
          <MaterialButton
            onClickCallback={navigateToFaqRoute}
            text="Ver assuntos"
            endIconBdsName="arrow-right"
            variant="secondary"
            isMobileView
          />
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
}

export default FaqCard;
