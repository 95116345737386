import { BdsAvatar, BdsButton, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import './style.scss';
import useTrack from 'hooks/useTrack';
import { COEX_SEND_MESSAGE_CONTACT } from 'libs/trackingEvents';
import { useMobileView } from 'contexts/MobileViewContext';
import { formatDate, formatPhoneNumberBrazil } from 'utils/formaters';
import ContactDataSection from './ContactDataSection';

interface ContactItemProps {
  name: string;
  phoneNumber: string;
  lastMessage: Date | string;
  group: string;
  onContactClick: () => void;
}

function ContactItem({ name, phoneNumber, lastMessage, onContactClick, group }: Readonly<ContactItemProps>) {
  const isMobileView = useMobileView();
  const { track } = useTrack();

  const openWhatsApp = () => {
    track(COEX_SEND_MESSAGE_CONTACT);
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleContactClick = (event: any) => {
    const isSendMessageButton = event.target.closest('.send-message-btn');
    if (isSendMessageButton) {
      return;
    }
    onContactClick();
  };

  const avatarProps = name && { name };

  return (
    <BdsPaper className="contact-item-container" elevation="none" onClick={handleContactClick} id={phoneNumber}>
      <BdsGrid padding="y-2" justifyContent="space-between" alignItems="center">
        <BdsGrid gap="2" className="contact-info-container" alignItems="center">
          <BdsAvatar size="small" {...avatarProps} />
          <BdsGrid direction="column" justifyContent="center">
            <span className="contact-name">{name || phoneNumber}</span>
            <BdsTypo className="data-content">{formatPhoneNumberBrazil(phoneNumber, true)}</BdsTypo>
          </BdsGrid>
        </BdsGrid>
        <ContactDataSection title="Última atualização" subtitle={formatDate(lastMessage)} bold="semi-bold" />
        {group ? (
          <ContactDataSection title="Grupo" subtitle={group} bold="bold" />
        ) : (
          !isMobileView && (
            <BdsButton icon="add" variant="text" color="content">
              Adicionar grupo
            </BdsButton>
          )
        )}
        <BdsButton
          disabled={phoneNumber === 'Número indisponível'}
          icon="message-sent"
          variant="text"
          color="content"
          onBdsClick={openWhatsApp}
          className="send-message-btn"
        >
          {!isMobileView && 'Enviar mensagem'}
        </BdsButton>
      </BdsGrid>
    </BdsPaper>
  );
}

export default ContactItem;
