import { BdsGrid, BdsPaper } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { ILogArgs, logger } from 'packs-template-baseweb';
import React, { useEffect, useState } from 'react';
import ChatComponent from '../Chat/ChatComponent';
import GptSimulatorHeader from '../GptSimulatorHeader';
import ModalTesterAssistant from '../ModalTesterAssistant';
import './style.scss';
import { convertToFaqMarkdown } from '/utils/convertToMarkdown';
import { prepareSimulatorPrompt } from '/utils/prepareSimulatorPrompt';

const logArgs: ILogArgs = {
  className: 'GptSimulatorContainer',
};

function GptSimulatorContainer() {
  const { getPromptByBotId } = usePlgApi();
  const [preparedPrompt, setPreparedPrompt] = useState<string>();
  const { botConfiguration, faqs } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const { createToastError } = useToast();

  useEffect(() => {
    fetchPromptAndPrepare();
  }, []);

  const fetchPromptAndPrepare = async () => {
    logArgs.methodName = 'fetchPromptAndPrepare';
    try {
      const prompFromBotId = await getPromptByBotId(botConfiguration.botId);
      const preparedFaq = await convertToFaqMarkdown(faqs);
      const formatedPrompt = await prepareSimulatorPrompt(botConfiguration, prompFromBotId, preparedFaq);
      setPreparedPrompt(formatedPrompt);
    } catch (error) {
      const errorMessage = 'Falha ao acessar informações, do prompt, tente mais tarde';
      const errorPayload = `${errorMessage}, ${error}`;
      logger.error(errorPayload, logArgs);
      createToastError(errorMessage);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <BdsGrid className="tester-container" direction="column" gap="3">
        <BdsPaper className="tester-paper">
          {showModal && <ModalTesterAssistant />}
          <GptSimulatorHeader openModal={openModal} />
          <BdsGrid className="chat-content" direction="column" gap="2">
            <ChatComponent prompt={preparedPrompt} openModalCallback={openModal} />
          </BdsGrid>
        </BdsPaper>
      </BdsGrid>
    </>
  );
}

export default GptSimulatorContainer;
