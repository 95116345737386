// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .quick-access-card {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/QuickAccessCard/styles.scss"],"names":[],"mappings":"AACE;EADF;IAEI,sBAAA;EACF;AACF","sourcesContent":[".quick-access-card {\n  @media (min-width: 768px) {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
