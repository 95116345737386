import { BdsChipTag, BdsGrid, BdsIcon, BdsTypo, BdsUpload } from 'blip-ds/dist/blip-ds-react';
import { BdsUploadCustomEvent } from 'blip-ds/dist/types/components';
import useTrack from 'hooks/useTrack';
import { COEX_CAMPAIGN_DOWNLOAD_TEMPLATE_LINK, COEX_CAMPAIGN_SUBMIT_AUDIENCE } from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AudienceAndVariables, Template } from 'types/ActiveMessage';
import * as XLSX from 'xlsx';
import './style.scss';

type IAudienceFileUpload = {
  onAudienceUpload: (audience: AudienceAndVariables[]) => void;
};

export const TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vS5RA2wczQXVQfMhiy31CiNJy1FAmmvvEGcwBHLryhRO3H445psP_yG7isjhjeIuExaSxwK6ZAA1WbI/pub?output=xlsx';
const FILE_TYPES = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

function AudienceFileUpload({ onAudienceUpload }: IAudienceFileUpload) {
  const [error, setError] = useState('');
  const location = useLocation();
  const { track } = useTrack();
  const selectedTemplate: Template = location.state.template;
  const hasVariables = !!selectedTemplate.body.variablesExamples.length;

  const downloadTemplate = () => {
    track(COEX_CAMPAIGN_DOWNLOAD_TEMPLATE_LINK);
    window.open(TEMPLATE_LINK);
  };

  const formatDataAndAudienceKeys = (parsedDdata: AudienceAndVariables[]) => {
    return parsedDdata.map(oldDataAndAudience => {
      const formattedDataAndAudience: { [key: string]: string } = {};

      for (let oldKey in oldDataAndAudience) {
        const formattedKey = oldKey.replace(/{{|}}/g, '');
        formattedDataAndAudience[formattedKey] = oldDataAndAudience[oldKey];
      }

      return formattedDataAndAudience;
    });
  };

  const uploadEventHandler = (event: BdsUploadCustomEvent<{ value: File[] }>) => {
    track(COEX_CAMPAIGN_SUBMIT_AUDIENCE);
    const file = event.detail.value[0];
    setError('');

    if (!file || !FILE_TYPES.includes(file.type)) {
      setError('Por favor, selecione um arquivo Excel em formato .xlsx ou .xls');
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = e => {
      const arrayBuffer = e.target?.result;

      if (!arrayBuffer) {
        setError('Arquivo em branco ou inválido');
        return;
      }

      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json<AudienceAndVariables>(sheet);

      if (!jsonData.length) {
        setError('Arquivo em branco ou inválido');
        return;
      }

      validateTelefoneRow(jsonData);
      let parsedData = parsePhoneNumbers(jsonData);
      parsedData = formatDataAndAudienceKeys(parsedData);
      const isAudienceValid = validateAudience(parsedData);
      if (isAudienceValid) {
        onAudienceUpload(parsedData);
      }
    };

    fileReader.onerror = () => {
      setError('Erro ao carregar o arquivo');
    };

    fileReader.readAsArrayBuffer(file);
  };

  const validateTelefoneRow = (data: AudienceAndVariables[]) => {
    const hasPhoneKey = data.every(item => 'telefone' in item);
    if (!hasPhoneKey) {
      return setError('O arquivo deve conter uma coluna "telefone".');
    }
  };

  const parsePhoneNumbers = (data: AudienceAndVariables[]) => {
    try {
      data.forEach(item => {
        item.telefone = item.telefone.toString();
        if (!item.telefone.startsWith('+')) {
          item.telefone = `+${item.telefone}`;
        }
      });
      return data;
    } catch (error) {
      setError('Erro ao processar os números de telefone');
      throw new Error(JSON.stringify(error));
    }
  };

  const validateAudience = (data: AudienceAndVariables[]) => {
    const isValid = data.every(item => item.telefone?.startsWith('+'));
    if (!isValid) {
      setError('Todos os números de telefone devem existir e começar com o símbolo "+"');
    }
    const audienceVariableSizesExcludingPhone: number[] = data.map(item => Object.keys(item).length - 1);
    const templateVariableSize = selectedTemplate.body.variablesExamples[0]?.length ?? 0;
    const hasCompatibleVariables = audienceVariableSizesExcludingPhone.every(size => size === templateVariableSize);
    if (!hasCompatibleVariables) {
      setError('As variáveis do arquivo carregado não correspondem ao modelo de mensagem selecionado.');
    }
    return isValid && hasCompatibleVariables;
  };

  const variableChips = selectedTemplate.body.variablesExamples[0]?.map((_, index) => (
    <BdsChipTag color="outline" key={index + 1}>
      {`{{${index + 1}}}`}
    </BdsChipTag>
  ));

  return (
    <BdsGrid direction="column" gap="1" lg="8">
      <BdsUpload
        data-testid="upload-component"
        onBdsUploadChange={uploadEventHandler}
        onBdsUploadDelete={() => onAudienceUpload([])}
        accept=".xlsx, .xls"
        error={error}
        titleName="Carregar contatos (arquivo excel)"
      />
      <BdsGrid alignItems="center" color="blue" className="template-link" onClick={downloadTemplate}>
        <BdsIcon className="template-link" theme="outline" name="file-name-xls" />
        <BdsTypo className="template-link" variant="fs-14">
          Faça download do modelo
        </BdsTypo>
      </BdsGrid>
      {hasVariables && (
        <>
          <BdsGrid margin="t-3" alignItems="center" gap="1">
            <BdsIcon size="x-small" name="info" />
            <BdsTypo variant="fs-14" tag="p">
              Variáveis que precisam estar contidas no arquivo de audiência (.xlsx, .xls):
            </BdsTypo>
          </BdsGrid>
          <BdsGrid gap="1">
            <BdsChipTag color="outline">telefone</BdsChipTag>
            {variableChips}
          </BdsGrid>
        </>
      )}
    </BdsGrid>
  );
}

export default AudienceFileUpload;
