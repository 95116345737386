import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import RobotBroken from '../../../assets/images/robot-broken.svg';
import BlipGoCoexLogo from '../../../assets/images/blipgo-coex-logo.svg';
import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_INSTALLATION_TRY_AGAIN } from 'libs/trackingEvents';

function PackInstallFail() {
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleTryAgain = () => {
    track(COEX_INSTALLATION_TRY_AGAIN);
    navigate('/onboarding/installing');
  };

  return (
    <BdsGrid justifyContent="center">
      <BdsGrid direction="column" className="main-container onboarding">
        <BdsGrid justifyContent="center" alignItems="center">
          <img src={BlipGoCoexLogo} alt="BlipGO CoEX logo" />
        </BdsGrid>
        <BdsGrid direction="column" justifyContent="center" alignItems="center" gap="4" padding="t-8">
          <img src={RobotBroken} />
          <BdsGrid direction="column" justifyContent="center" alignItems="center" gap="3">
            <BdsTypo variant="fs-32" bold="bold" margin={false} className="install-info-title">
              Desculpe! Houve um erro durante a configuração de Blip Coex
            </BdsTypo>
            <BdsTypo variant="fs-16">Tente novamente, se o problema persistir, entre em contato.</BdsTypo>
          </BdsGrid>
          <BdsButton icon="refresh" variant="outline" color="content" onBdsClick={handleTryAgain}>
            Tentar novamente
          </BdsButton>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
}

export default PackInstallFail;
