import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type RouteGuardProps = {
  children: ReactNode;
  requiredStateKey: string;
};

const RouteGuard = ({ children, requiredStateKey }: RouteGuardProps) => {
  const location = useLocation();
  const { state } = location;

  if (!state || !Object.keys(state).includes(requiredStateKey)) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default RouteGuard;
