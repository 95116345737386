export type Faq = {
  id?: string;
  question: string;
  answer: string;
  keywords?: string[];
};

export type WizardTempFaq = {
  selectedOption: string;
  faq: Faq;
  doNotApply?: boolean;
};

export enum FaqState {
  FaqList,
  AddingFaq,
  EditingFaq,
}
