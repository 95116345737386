import { BdsButton, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import usePacksApi from 'hooks/usePacksApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { INSTALLATION } from 'libs/trackingEvents';
import React, { useState } from 'react';
import './style.scss';

type InstallationErrorHeaderProps = {
  hideError: () => void;
};

function InstallationErrorHeader({ hideError }: InstallationErrorHeaderProps) {
  const { pollInstallationStatusWithRetries } = usePacksApi();
  const { botConfiguration } = useAppContext();
  const { createToastError, createToastSuccess } = useToast();
  const { track } = useTrack();
  const [isRetrying, setIsRetrying] = useState(false);

  const retryInstallation = async () => {
    setIsRetrying(true);
    try {
      await pollInstallationStatusWithRetries(botConfiguration.botId);
      hideError();
      createToastSuccess('Assistente criado com sucesso');
    } catch (error) {
      setIsRetrying(false);
      track(INSTALLATION, { status: 'Error', error: error });
      createToastError('Erro ao criar assistente neste momento', 'Tente novamente mais tarde');
    }
  };

  return (
    <div id="header-container">
      <BdsGrid justifyContent="space-between" alignItems="center">
        <BdsGrid gap="2">
          <BdsIcon name="error" />
          <BdsTypo variant="fs-14" bold="bold">
            Houve um problema com a criação do sistema do seu assistente. Tente novamente mais tarde.
          </BdsTypo>
        </BdsGrid>
        <BdsButton
          bdsLoading={isRetrying}
          onClick={() => {
            !isRetrying && retryInstallation();
          }}
          variant="tertiary"
        >
          Tentar novamente
        </BdsButton>
      </BdsGrid>
    </div>
  );
}

export default InstallationErrorHeader;
