import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react/components';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { INSTALLATION } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React, { FC, createContext, useContext, useEffect, useState } from 'react';
import { IdentifyAppcues } from 'services/Appcues/Appcues.js';
import { BotConfiguration } from 'types/Bot';
import { UserData } from 'types/Facebook';
import { Faq } from 'types/Faq';
import { mountInstagramUserName } from '../utils/parseData';
import { isInstagramChannelActive, isWhatsAppChannelActive } from '/utils/assertions';

type AppProvider = {
  children: React.ReactNode;
  utmParams: Record<string, string> | null;
};

type AppContextValue = {
  whatsAppActivated: boolean;
  instagramActivated: boolean;
  setInstagramActivated: React.Dispatch<React.SetStateAction<boolean>>;
  facebookUserData: UserData;
  setFacebookUserData: React.Dispatch<React.SetStateAction<UserData>>;
  botConfiguration: BotConfiguration;
  setBotConfiguration: React.Dispatch<React.SetStateAction<BotConfiguration>>;
  faqs: Faq[];
  setFaqs: React.Dispatch<React.SetStateAction<Faq[]>>;
  utmParams: Record<string, string> | null;
  instaUsername: string;
  setInstaUsername: React.Dispatch<React.SetStateAction<string>>;
};

export const AppContext = createContext<AppContextValue>({} as AppContextValue);

export const AppProvider: FC<AppProvider> = ({ children, utmParams }) => {
  const [botConfiguration, setBotConfiguration] = useState({} as BotConfiguration);
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [instagramActivated, setInstagramActivated] = useState(false);
  const [whatsAppActivated, setWhatsAppActivated] = useState(false);
  const [facebookUserData, setFacebookUserData] = useState({} as UserData);
  const [isContextReady, setIsContextReady] = useState(false);
  const [instaUsername, setInstaUsername] = useState('');

  const { getBotIdIfInstalled, getInstallationChannelsStatus } = usePacksApi();
  const { getFaq, getCatalog, getBotConfiguration, createBotConfigurations, initialBotConfiguration, getChannelData } =
    usePlgApi();
  const { createToastError } = useToast();
  const { identifyAnalytics, track } = useTrack();
  const { isLoading, userData } = useAuth();
  const ldClient = useLDClient();

  const fetchUserInfo = async () => {
    const botId = await getBotIdIfInstalled();
    if (botId) {
      try {
        const [botChannelsStatus, botConfiguration, faqInfo, catalogInfo, channelData] = await Promise.all([
          getInstallationChannelsStatus(botId),
          getBotConfiguration(botId),
          getFaq(botId),
          getCatalog(botId),
          getChannelData(botId),
        ]);

        setInstagramActivated(isInstagramChannelActive(botChannelsStatus));
        setWhatsAppActivated(isWhatsAppChannelActive(botChannelsStatus));

        if (!botConfiguration) {
          await createBotConfigurations(initialBotConfiguration(botId));
          setBotConfiguration(initialBotConfiguration(botId));
        } else {
          setBotConfiguration(botConfiguration);
          if (channelData) {
            setInstaUsername(mountInstagramUserName(channelData));
          }
        }

        catalogInfo?.enabled &&
          setFacebookUserData({
            user: catalogInfo.facebookSettings?.user,
            business: catalogInfo.facebookSettings?.business,
            catalog: catalogInfo.facebookSettings?.catalog,
            isCatalogSubmitted: true,
          } as UserData);
        if (faqInfo?.enabled) {
          setFaqs(faqInfo.questionAnswers);
        }
      } catch (error) {
        track(INSTALLATION, { status: 'Error', error: error });
        createToastError('Erro ao buscar informações do assistente', 'Por favor, tente novamente mais tarde');
      }
    }
    setIsContextReady(true);
  };

  useEffect(() => {
    if (!isLoading && !isContextReady && userData?.profile.email) {
      identifyAnalytics(userData.profile.email);
      IdentifyAppcues(userData.profile.email);
      ldClient?.identify({
        kind: 'user',
        key: userData.profile.email,
        name: userData.profile.FullName as string,
        email: userData.profile.email,
      });
      fetchUserInfo();
    }
  }, [isLoading, isContextReady]);

  return isContextReady ? (
    <AppContext.Provider
      value={{
        whatsAppActivated,
        instagramActivated,
        setInstagramActivated,
        facebookUserData,
        setFacebookUserData,
        botConfiguration,
        setBotConfiguration,
        faqs,
        setFaqs,
        utmParams,
        instaUsername,
        setInstaUsername,
      }}
    >
      {children}
    </AppContext.Provider>
  ) : (
    <BdsLoadingPage data-testid="loading-page" />
  );
};

export function useAppContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
