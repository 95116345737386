import { BdsGrid, BdsLoadingSpinner, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import useTrack from 'hooks/useTrack';
import {
  CONNECT_INSTAGRAM_PAGE_NOTFOUND_CHANGE_USER,
  CONNECT_INSTAGRAM_PAGE_NOTFOUND_CREATE_PAGE,
} from 'libs/trackingEvents';
import React, { Ref, forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const InstagramFacebookPageNotListedModal = forwardRef(function InstagramFacebookPageNotListedModal(
  props: {},
  ref: Ref<HTMLBdsModalElement>,
) {
  const isMobileView = useMobileView();
  const { track } = useTrack();
  const { logoutFacebook } = useGraphApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const createNewPageHandler = () => {
    track(CONNECT_INSTAGRAM_PAGE_NOTFOUND_CREATE_PAGE);
    window.open('https://www.facebook.com/pages/creation/', '_blank');
  };

  const changeAccountHandler = async () => {
    setLoading(true);
    await logoutFacebook();
    track(CONNECT_INSTAGRAM_PAGE_NOTFOUND_CHANGE_USER);
    setLoading(false);
    return navigate('/dashboard');
  };

  return (
    <BdsModal id="page-not-listed-modal" ref={ref} role="dialog" size="dynamic">
      <div className="page-not-listed-modal">
        <BdsTypo variant="fs-20" bold="bold" lineHeight="plus">
          Se você não encontrou a página que queria usar:
        </BdsTypo>
        <BdsTypo variant="fs-14">
          1 - A página do usuário conectado não tem acesso a essa página. Nesse caso você pode:
        </BdsTypo>
        <BdsGrid direction="column" padding="l-1" margin="b-1">
          <ul className="item-list">
            <li>
              <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                Pedir para quem alguma pessoa administradora te adicione à página
              </BdsTypo>
            </li>
            <li>
              <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                Conectar com um usuário que tem acesso a essa conta
              </BdsTypo>
            </li>
          </ul>
        </BdsGrid>
        {loading ? (
          <BdsLoadingSpinner size="small" />
        ) : (
          <MaterialButton
            text="Trocar usuário"
            onClickCallback={changeAccountHandler}
            variant="tertiary"
            startIconBdsName="arrow-left"
            isMobileView={isMobileView}
            cssVariant="w-fit"
          />
        )}
        <BdsGrid direction="column" gap="1" margin="t-4">
          <BdsTypo variant="fs-14">
            2 - A página ainda não foi criada. Nesse caso, você pode criar uma nova página diretamente no Facebook.
          </BdsTypo>
          <MaterialButton
            text="Crie uma nova página"
            variant="tertiary"
            startIconBdsName="facebook"
            isMobileView={isMobileView}
            onClickCallback={createNewPageHandler}
            cssVariant="w-fit"
          />
        </BdsGrid>
      </div>
    </BdsModal>
  );
});

export default InstagramFacebookPageNotListedModal;
