import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BdsBanner } from 'blip-ds/dist/blip-ds-react';
import WhatsappConnectionCard from 'components/WhatsapppConectionCard';

const WhatsAppDisconnected: React.FC = () => {
  const navigate = useNavigate();

  const handleStartConnectionClick = () => {
    navigate('/whatsapp/number-check');
  };

  return (
    <>
      <BdsBanner variant="warning" content="internal">
        Para fazer o disparo de mensagens ativas, é necessário estar com o WhatsApp Business conectado. Conecte agora e
        comece a usar.
      </BdsBanner>
      <WhatsappConnectionCard
        description="Com a conexão ativa, você poderá usar nosso assistente com IA, e iremos fornecer dados para te ajudar a atender melhor e aumentar as vendas."
        onActionClick={handleStartConnectionClick}
        statusType="warning"
        statusName="Importante"
        title="Conecte ao WhatsApp Business"
      />
    </>
  );
};

export default WhatsAppDisconnected;
