import { useAppContext } from 'contexts/AppContext';
import { useCallback, useMemo } from 'react';
import * as deviceUtils from 'utils/device';

const useTrack = () => {
  const { botConfiguration, instagramActivated, facebookUserData, utmParams, instaUsername } = useAppContext();

  const DEFAULT_PROPERTIES = useMemo(() => {
    const storageSource = localStorage.getItem('source');

    return {
      botIdentifier: botConfiguration?.botId,
      packId: process.env.REACT_APP_PACK_ID,
      instagramConnected: instagramActivated,
      catalogConnected: facebookUserData?.isCatalogSubmitted ?? 'No information',
      plan: 'Enterprise',
      tenant: process.env.REACT_APP_PACK_TENANT,
      deviceType: deviceUtils.detectDevice(),
      operationalSystem: deviceUtils.getOperatingSystem(),
      screenResolution: deviceUtils.getScreenResolution(),
      source: storageSource ? JSON.parse(storageSource) : utmParams,
      instagramUserName: instagramActivated ? instaUsername : undefined,
    };
  }, [botConfiguration?.botId, facebookUserData?.isCatalogSubmitted, instagramActivated, utmParams, instaUsername]);

  const startExperiment = useCallback((experimentName: string, variantName: string) => {
    analytics.track('$experiment_started', {
      'Experiment name': experimentName,
      'Variant name': variantName,
    });
  }, []);

  const identifyAnalytics = useCallback((email?: string) => {
    analytics.identify(email);
  }, []);

  const track = useCallback(
    (event: string, payload?: any, callback?: () => void) => {
      const fullPayload = { ...DEFAULT_PROPERTIES, ...payload };
      analytics.track(event, fullPayload, {}, callback);
    },
    [DEFAULT_PROPERTIES],
  );

  return { identifyAnalytics, track, startExperiment };
};

export default useTrack;
