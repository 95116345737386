import { BdsButton, BdsChipTag, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import InsertDataModal from 'components/InsertDataModal';
import PauseAssistantModal from 'components/PauseAssistantModal';
import { useAppContext } from 'contexts/AppContext';
import useAssistantManager from 'hooks/useAssistantManager';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { ContactStatus, HUBSPOT_ASSIST_PARAMETER } from 'libs/hubspotParameters';
import { COEX_TEST_ASSISTANT, PAUSE_ASSISTANT, RESUME_ASSISTANT } from 'libs/trackingEvents';
import React, { useRef } from 'react';
import { BotStatus } from 'types/Bot';
import './style.scss';

function DashboardHeader() {
  const { botConfiguration, installation } = useAppContext();
  const { setBotStatus } = usePlgApi();
  const { updateHubspotContact } = usePacksApi();
  const { track } = useTrack();
  const { createToastError } = useToast();
  const insertDataModalRef = useRef<HTMLBdsModalElement | null>(null);
  const pauseAssistantModalRef = useRef<HTMLBdsModalElement | null>(null);
  const { isAssistActive, isAssistPaused } = useAssistantManager({ statusControlEnabled: false });
  const robotColor = isAssistActive
    ? 'success-background'
    : isAssistPaused
    ? 'warning-background'
    : 'system-background';
  const tagText = isAssistActive ? 'Ativado' : isAssistPaused ? 'Pausado' : 'Desativado';

  const openTestPopUp = () => {
    track(COEX_TEST_ASSISTANT);
    const blipChatKey = installation.routerBlipChatAppKey;
    const url = `https://${process.env.REACT_APP_PACK_TENANT}.${process.env.REACT_APP_BLIPCHAT_SUFFIX}.blip.ai?appKey=${blipChatKey}`;
    open(url, '_blank');
  };

  const pauseAssistant = async () => {
    track(PAUSE_ASSISTANT);
    pauseAssistantModalRef.current?.toggle();
  };

  const reactivateAssistant = async () => {
    if (botConfiguration.status === BotStatus.PausedWithNoData) {
      insertDataModalRef.current?.toggle();
      return;
    }
    try {
      await setBotStatus(BotStatus.Active);
      track(RESUME_ASSISTANT, { status: 'Success' });
      updateHubspotContact(HUBSPOT_ASSIST_PARAMETER, ContactStatus.Active, botConfiguration.botId);
    } catch (error) {
      const err = error as Error;
      track(RESUME_ASSISTANT, { status: 'Error', error: err.message });
      createToastError('Não foi possível realizar esta ação no momento', 'Tente novamente mais tarde');
    }
  };

  return (
    <>
      <BdsPaper className="paper-pattern dashboard-header-background" elevation="none">
        <BdsGrid padding="2" gap="1" alignItems="center" justifyContent="space-between">
          <BdsGrid gap="2" margin="none" padding="1" alignItems="center">
            <div className={'rounded-square ' + robotColor}>
              <BdsIcon name="robot" size="xxx-large" />
            </div>
            <BdsGrid gap="2" alignItems="center">
              <BdsTypo variant="fs-20" bold="bold" tag="h4" margin={false}>
                Assistente Virtual!
              </BdsTypo>
              <BdsChipTag
                icon={isAssistPaused ? 'error' : 'checkball'}
                color={isAssistPaused ? 'warning' : isAssistActive ? 'success' : 'outline'}
              >
                {tagText}
              </BdsChipTag>
            </BdsGrid>
          </BdsGrid>
          <BdsGrid md="3" lg="3" xg="3">
            <BdsTypo variant="fs-14" style={{ textAlign: 'end' }}>
              O assistente inicia atendimentos e responde dúvidas dos clientes.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid gap="1">
            <BdsButton variant="outline" color="content" icon="builder-test-bot" onBdsClick={openTestPopUp}>
              Testar
            </BdsButton>
            <BdsButton
              onBdsClick={() => (isAssistActive ? pauseAssistant() : reactivateAssistant())}
              icon={isAssistActive ? 'pause' : 'play'}
              color={isAssistActive ? 'content' : 'primary'}
            >
              {isAssistActive ? 'Pausar assistente' : 'Ativar assistente'}
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <InsertDataModal ref={insertDataModalRef} />
      <PauseAssistantModal ref={pauseAssistantModalRef} />
    </>
  );
}

export default DashboardHeader;
