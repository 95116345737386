import { CreateToastType } from 'blip-ds/dist/types/components/toast/toast-interface';
import { useCallback } from 'react';

function useToast() {
  const appendToast = async (toast: CreateToastType) => {
    const newToast = document.createElement('bds-toast');
    document.body.appendChild(newToast);
    await newToast.create(toast);
  };

  const createToastSystem = useCallback(async (text: string, title?: string, duration?: number) => {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'message-ballon',
      variant: 'system',
      toastTitle: title ?? '',
      toastText: text,
      position: 'bottom-left',
      duration: duration ?? 3,
    };
    appendToast(toast);
  }, []);

  const createToastSuccess = useCallback(async (title: string, text?: string, duration?: number) => {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'like',
      variant: 'success',
      toastTitle: title,
      toastText: text ?? '',
      position: 'bottom-left',
      duration: duration ?? 3,
    };
    appendToast(toast);
  }, []);

  const createToastWarning = async (title: string, text?: string, duration?: number) => {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'attention',
      variant: 'warning',
      toastTitle: title,
      toastText: text ?? '',
      position: 'bottom-left',
      duration: duration ?? 6,
    };
    appendToast(toast);
  };

  const createToastError = async (title: string, text?: string, duration?: number) => {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'error',
      variant: 'error',
      toastTitle: title,
      toastText: text ?? '',
      position: 'bottom-left',
      duration: duration ?? 6,
    };
    appendToast(toast);
  };

  return { createToastSuccess, createToastWarning, createToastError, createToastSystem };
}

export default useToast;
