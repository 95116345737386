// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-list {
  margin: 0;
  padding-left: 1rem;
}

.item-list li:not(:last-child) {
  margin-bottom: 0rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramFacebookPageNotListedModal/style.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".item-list {\n  margin: 0;\n  padding-left: 1rem;\n}\n\n.item-list li:not(:last-child) {\n  margin-bottom: 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
