import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react/components';
import RouteGuard from 'components/RouteGuard';
import { AppProvider } from 'contexts/AppContext';
import { MobileViewProvider } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { VERSION } from 'libs/app';
import { OIDC } from 'libs/oidc';
import { ESQUENTATOR_ACCESSED } from 'libs/trackingEvents';
import { AuthProvider } from 'oidc-react';
import { environment, logger } from 'packs-template-baseweb';
import ActiveMessages from 'pages/ActiveMessages/ActiveMessages';
import Confirmation from 'pages/ActiveMessages/Confirmation';
import MessageConfiguration from 'pages/ActiveMessages/MessageConfiguration';
import MessageResponseDirection from 'pages/ActiveMessages/MessageResponseDirection';
import MessageTemplates from 'pages/ActiveMessages/MessageTemplates';
import AssistantTester from 'pages/AssistantTester';
import AsyncPreparation from 'pages/AsyncPreparation/AsyncPreparation';
import ClickTrackerIntegration from 'pages/ClickTrackerIntegration';
import Dashboard from 'pages/Dashboard';
import FacebookCatalog from 'pages/FacebookCatalog';
import Faq from 'pages/Faq';
import FaqWizard from 'pages/FaqWizard';
import Instagram from 'pages/Instagram';
import PrivateReply from 'pages/PrivateReply';
import QuickAccess from 'pages/QuickAccess';
import Survey from 'pages/Survey';
import WhatsappBusinessCheck from 'pages/WhatsappBusinessCheck';
import WhatsappConnectionFinished from 'pages/WhatsappConnectionFinished';
import WhatsappNumberCheck from 'pages/WhatsappNumberCheck';
import React, { useEffect, useState } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MSClarity } from 'services/Clarity/Clarity';
import 'styles.scss';
import { getUTMParametersFromLocation } from 'utils/queryParams';

logger.configure();
logger.info(`Current enviromenment: ${environment.currentEnvironment}`);
logger.info(`Current log level: ${logger.currentLevel}`);
const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-NQGWZ373',
};

const initializeGTM = () => {
  TagManager.initialize(tagManagerArgs);
};

function App() {
  const { track } = useTrack();
  const [isAppReady, setIsAppReady] = useState(false);
  const [utmParams, setUtmParams] = useState<Record<string, string> | null>(null);
  const INITIALIZATION_TIMEOUT = 2500;

  useEffect(() => {
    console.log(`COEX v${VERSION}`);
    initializeGTM();
    const sourceUtm = getUTMParametersFromLocation();
    if (sourceUtm && !localStorage.getItem('source')) {
      setUtmParams(sourceUtm);
      localStorage.setItem('source', JSON.stringify(sourceUtm));
    }
    trackLinkOpened();
    setTimeout(() => {
      setIsAppReady(true);
    }, INITIALIZATION_TIMEOUT);
  }, []);

  const trackLinkOpened = () => {
    const utmParams = getUTMParametersFromLocation();
    if (utmParams) {
      track(ESQUENTATOR_ACCESSED, { source: utmParams }, () => {
        setIsAppReady(true);
      });
    } else {
      setIsAppReady(true);
    }
  };

  return isAppReady ? (
    <AuthProvider {...OIDC}>
      <MobileViewProvider>
        <AppProvider utmParams={utmParams}>
          <MSClarity />
          <BrowserRouter>
            <Routes>
              <Route index element={<AsyncPreparation />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/faq-wizard" element={<FaqWizard />} />
              <Route path="/catalog" element={<FacebookCatalog />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/ads" element={<ClickTrackerIntegration />} />
              <Route path="/quick-access" element={<QuickAccess />} />
              <Route path="/survey" element={<Survey />} />
              <Route path="/instagram" element={<Instagram />} />
              <Route path="/assistant-tester" element={<AssistantTester />} />
              <Route path="/private-reply" element={<PrivateReply />} />
              <Route path="/whatsapp">
                <Route index element={<Navigate to="number-check" replace />} />
                <Route index path="number-check" element={<WhatsappNumberCheck />} />
                <Route path="business-check" element={<WhatsappBusinessCheck />} />
                <Route path="connection-finished" element={<WhatsappConnectionFinished />} />
              </Route>
              <Route path="/campaign" element={<ActiveMessages />}>
                <Route index path="template" element={<MessageTemplates />} />
                <Route
                  path="configuration"
                  element={
                    <RouteGuard requiredStateKey="template">
                      <MessageConfiguration />
                    </RouteGuard>
                  }
                />
                <Route
                  path="direction"
                  element={
                    <RouteGuard requiredStateKey="audience">
                      <MessageResponseDirection />
                    </RouteGuard>
                  }
                />
                <Route
                  path="confirm"
                  element={
                    <RouteGuard requiredStateKey="isHumanRedirect">
                      <Confirmation />
                    </RouteGuard>
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </AppProvider>
      </MobileViewProvider>
    </AuthProvider>
  ) : (
    <BdsLoadingPage />
  );
}

export default App;
