export type BotConfiguration = {
  botId: string;
  status: BotStatus;
  whatsApp: string;
  companySite: string;
  marketplace: string;
  welcomeMessage?: string;
  stopMessage: string;
  businessName?: string;
  agentVoiceTom: string;
  maxInterations: number;
  maxMessages: number;
};

export enum BotStatus {
  None,
  Created,
  Tested,
  Active,
  Paused,
  PausedWithNoData,
}

export type RouterSkill = {
  name: string;
  description: string;
  shortName: string;
  emailOwner: string;
  hasPermission: boolean;
  tenantId: string;
  created: Date;
  updated: Date;
};
