import { BdsGrid, BdsIllustration, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { CONNECT_INSTAGRAM_PAGE_ERROR_BACK } from 'libs/trackingEvents';
import React, { Ref, forwardRef } from 'react';
import './style.scss';

const InstagramFailedBindPagesModal = forwardRef(function InstagramFailedBindPagesModal(
  props: {},
  ref: Ref<HTMLBdsModalElement>,
) {
  const { track } = useTrack();
  const isMobile = useMobileView();

  const closeModal = () => {
    const modal = document.getElementById('bind-pages-modal') as HTMLBdsModalElement;
    if (modal) {
      track(CONNECT_INSTAGRAM_PAGE_ERROR_BACK);
      modal.toggle();
    }
  };

  return (
    <BdsModal id="bind-pages-modal" ref={ref} closeButton={false} size="dynamic">
      <BdsGrid padding="2" direction="column" gap="3">
        <BdsGrid alignItems="center" gap="2">
          <BdsIllustration class="h-3" type="spots" name="letter-cancel-error" />
          <BdsTypo bold="bold" lineHeight="plus">
            As contas ainda não estão vinculadas.
          </BdsTypo>
        </BdsGrid>
        <BdsTypo>
          <b>Siga o passo a passo</b> para vincular contas.
        </BdsTypo>
        <MaterialButton
          variant="primary"
          onClickCallback={closeModal}
          text="Voltar"
          startIconBdsName="arrow-left"
          isMobileView={isMobile}
          cssVariant="modal-button w-50"
        />
      </BdsGrid>
    </BdsModal>
  );
});

export default InstagramFailedBindPagesModal;
