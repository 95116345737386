import { Divider } from '@mui/material';
import {
  BdsAvatar,
  BdsBanner,
  BdsChipTag,
  BdsGrid,
  BdsIcon,
  BdsLoadingPage,
  BdsSelect,
  BdsSelectOption,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { FacebookCatalogGetBusinessErrors, FacebookCatalogSteps } from 'libs/facebookCatalogSteps';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Business, Catalog } from 'types/Facebook';
import './style.scss';
import { isEmpty } from '/utils/assertions';
import {
  CONNECT_CATALOG_CHANGE_ACCOUNT,
  CONNECT_CATALOG_CONFIRM_CATALOG,
  CONNECT_CATALOG_ERROR,
  CONNECT_CATALOG_ERROR_TRY_AGAIN,
} from 'libs/trackingEvents';

function BuildSelects({ list = [] }: { list: Business[] | Catalog[] }) {
  return list.map(item => (
    <BdsSelectOption key={item.id} value={JSON.stringify(item)}>
      {item.name}
    </BdsSelectOption>
  ));
}

function FacebookCatalogConnection({ setCurrentStep }: { setCurrentStep: (step: FacebookCatalogSteps) => void }) {
  const {
    getFacebookConnectedData,
    getUser,
    getUserBusinesses,
    getProductCatalogsFromBusiness,
    getProductsFromCatalog,
  } = useGraphApi();
  const fbConnectedData = getFacebookConnectedData();
  const isMobileView = useMobileView();
  const { setFacebookUserData, facebookUserData } = useAppContext();
  const [catalogs, setCatalogs] = useState([] as Catalog[]);
  const [businesses, setBusinesses] = useState([] as Business[]);
  const [noFoundCatalog, setNoFoundCatalog] = useState(false);
  const [selectBusinesses, setSelectBusinesses] = useState('');
  const [businessOption, setbusinessOption] = useState('');
  const [selectCatalogs, setSelectCatalogs] = useState('');
  const { createToastError, createToastSuccess } = useToast();
  const { patchProducts } = usePlgApi();
  const [loading, setLoading] = useState(false);
  const { track } = useTrack();
  const navigate = useNavigate();
  const errorScreen = 'nenhum catálogo encontrado';

  useEffect(() => {
    getUserData(fbConnectedData?.accessToken!);
  }, [fbConnectedData?.accessToken]);

  useEffect(() => {
    if (noFoundCatalog) {
      track(CONNECT_CATALOG_ERROR, { errorScreen: errorScreen });
    }
  }, [noFoundCatalog]);

  const getUserData = async (accessToken: string) => {
    if (!accessToken) {
      createToastError('Login interrompido', 'Por favor, prossiga com o login para conectar seu catálogo');
      return;
    }
    const user = await getUser(accessToken);
    setFacebookUserData(userData => ({ ...userData, user: user }));

    try {
      const businesses = await getUserBusinesses(user.id, accessToken);
      if (businesses.length === 0) {
        setCurrentStep(FacebookCatalogSteps.FacebookCatalogNoOrganizationFound);
        return;
      }
      setBusinesses(businesses);
    } catch (error) {
      let errorMessage;
      switch (error) {
        case FacebookCatalogGetBusinessErrors.InvalidToken:
          errorMessage = 'Falha ao conectar com o Facebook';
          setCurrentStep(FacebookCatalogSteps.FacebookCatalogSwitchProfile);
          break;
        default:
          errorMessage = 'Erro ao buscar as organizações';
          setCurrentStep(FacebookCatalogSteps.FacebookCatalogNoOrganizationFound);
          break;
      }
      setCurrentStep(FacebookCatalogSteps.FacebookCatalogSwitchProfile);
    }
  };

  const saveCatalogsFromBusiness = async (business: Business) => {
    const catalogs = await getProductCatalogsFromBusiness(business.id, fbConnectedData?.accessToken!);
    setCatalogs(catalogs);
    catalogs.length === 0 && setNoFoundCatalog(true);
    setFacebookUserData(userData => ({ ...userData, business: business }));
  };

  const handleBusinessesSelect = (event: any) => {
    saveCatalogsFromBusiness(JSON.parse(event.detail.value));
    setbusinessOption(event.detail.value);
    setSelectBusinesses(event.detail.value);
  };

  const handleChangeFbAccount = () => {
    track(CONNECT_CATALOG_CHANGE_ACCOUNT, { errorScreen: errorScreen });
    setCurrentStep(FacebookCatalogSteps.FacebookCatalogSwitchProfile);
  };

  const handleCatalogSelect = (event: any) => {
    setFacebookUserData(userData => ({ ...userData, catalog: JSON.parse(event.detail.value) }));
    setSelectCatalogs(event.detail.value);
  };

  const handleConnectionTryAgaing = () => {
    setbusinessOption('');
    track(CONNECT_CATALOG_ERROR_TRY_AGAIN, { errorScreen: errorScreen });
    setNoFoundCatalog(false);
  };

  const saveProductsFromCatalog = async () => {
    setLoading(true);
    try {
      const products = await getProductsFromCatalog(facebookUserData.catalog.id, fbConnectedData?.accessToken!);
      await patchProducts(facebookUserData.catalog.id, products, {
        business: facebookUserData.business,
        catalog: facebookUserData.catalog,
        user: facebookUserData.user,
      });
      setFacebookUserData(facebookUserData => ({ ...facebookUserData, isCatalogSubmitted: true }));
      createToastSuccess('Produtos salvos com sucesso!');
      track(CONNECT_CATALOG_CONFIRM_CATALOG, { status: 'Success' });
    } catch (error: any) {
      const err = error as Error;
      track(CONNECT_CATALOG_CONFIRM_CATALOG, { status: 'Error', error: err.message });
      createToastError('Erro ao salvar os produtos', 'Tente novamente em alguns instantes');
    } finally {
      setLoading(false);
      navigate('/dashboard');
    }
  };

  return (
    <>
      {(loading || isEmpty(businesses)) && <BdsLoadingPage />}
      <BdsGrid
        justifyContent={isMobileView ? undefined : 'center'}
        gap={isMobileView ? undefined : '8'}
        direction={isMobileView ? 'column' : 'row'}
        padding="2"
      >
        <BdsGrid>
          {isMobileView ? (
            <BdsGrid alignItems="center" gap="1" margin="b-2">
              <BdsGrid direction="row">
                <BdsAvatar id="avatar" color="success" name={fbConnectedData?.name} size="small"></BdsAvatar>
              </BdsGrid>
              <BdsTypo variant="fs-12" bold="semi-bold">
                {fbConnectedData?.name}
              </BdsTypo>
            </BdsGrid>
          ) : (
            <BdsGrid direction="column" gap="1" justifyContent="center" alignItems="center">
              <BdsAvatar id="avatar" color="success" name={fbConnectedData?.name} size="extra-large"></BdsAvatar>
              <BdsTypo variant="fs-16" bold="semi-bold">
                {fbConnectedData?.name}
              </BdsTypo>
              <BdsChipTag color="success">Conectado</BdsChipTag>
              <BdsGrid margin="t-4">
                <MaterialButton onClickCallback={handleChangeFbAccount} text="Trocar de conta" variant="secondary" />
              </BdsGrid>
            </BdsGrid>
          )}
        </BdsGrid>
        <Divider orientation={isMobileView ? 'horizontal' : 'vertical'} variant="middle" flexItem />
        <BdsGrid direction="column" gap="3" margin="t-3">
          <BdsTypo class="select" variant="fs-20" bold="bold" margin={false}>
            Selecione uma organização e um catálogo para conectar.
          </BdsTypo>
          <BdsGrid direction="column" gap="3">
            <BdsGrid direction="column">
              <BdsSelect
                class={!isMobileView ? 'select' : undefined}
                id="business-select"
                label="Organização"
                onBdsChange={handleBusinessesSelect}
                data-testid="business-select"
                value={businessOption}
              >
                <BuildSelects list={businesses} />
              </BdsSelect>
              {isEmpty(selectBusinesses) && (
                <BdsTooltip
                  className="tooltip"
                  position="right-bottom"
                  tooltipText="Verifique suas permissões no Gerenciador de Negócios ou conecte-se a outra conta."
                >
                  <BdsGrid gap="1" alignItems="center">
                    <BdsTypo variant="fs-12">Não encontrei a organização</BdsTypo>
                    <BdsIcon name="info" size="x-small" />
                  </BdsGrid>
                </BdsTooltip>
              )}
            </BdsGrid>

            {!isEmpty(catalogs) ? (
              <BdsGrid direction="column">
                <BdsSelect
                  class={!isMobileView ? 'select' : undefined}
                  id="catalog-select"
                  label="Catálogo"
                  onBdsChange={handleCatalogSelect}
                  data-testid="catalog-select"
                >
                  <BuildSelects list={catalogs} />
                </BdsSelect>
                {isEmpty(selectCatalogs) && (
                  <BdsTooltip
                    className="tooltip"
                    position="right-bottom"
                    tooltipText="Verifique suas permissões no Gerenciador de Negócios ou conecte-se a outra conta."
                  >
                    <BdsGrid gap="1" alignItems="center">
                      <BdsTypo variant="fs-12">Não encontrei o catálogo</BdsTypo>
                      <BdsIcon name="info" size="x-small" />
                    </BdsGrid>
                  </BdsTooltip>
                )}
              </BdsGrid>
            ) : (
              noFoundCatalog && (
                <BdsGrid direction="column" gap="1">
                  <BdsBanner class="select" variant="error" buttonClose="false" context="inside">
                    <BdsGrid direction="column" padding="none">
                      Não achamos catálogos nessa organização.
                    </BdsGrid>
                  </BdsBanner>
                  <BdsTypo class="select" variant="fs-12">
                    Verifique as permissões do seu usuário no facebook e tente novamente.
                  </BdsTypo>
                </BdsGrid>
              )
            )}
          </BdsGrid>

          <BdsGrid justifyContent="flex-end">
            {!isEmpty(catalogs) || !noFoundCatalog ? (
              <MaterialButton
                text="Conectar Catálogo"
                variant="primary"
                disabled={isEmpty(catalogs)}
                onClickCallback={saveProductsFromCatalog}
                isMobileView={isMobileView}
              ></MaterialButton>
            ) : (
              <MaterialButton
                onClickCallback={handleConnectionTryAgaing}
                text="Tentar novamente"
                variant="primary"
                isMobileView={isMobileView}
              />
            )}
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}
export default FacebookCatalogConnection;
