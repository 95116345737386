import { currentActivationStep } from 'atoms/AppAtoms';
import { useAppContext } from 'contexts/AppContext';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { TEST_ASSISTANT_CLICKED } from 'libs/trackingEvents';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BotStatus } from 'types/Bot';
import Step from '../Step';
import StepDisabled from '../StepDisabled';

function SimulatorStep() {
  const [currentStep, setCurrentStep] = useAtom(currentActivationStep);
  const { botConfiguration, faqs } = useAppContext();
  const navigate = useNavigate();
  const { track } = useTrack();
  const isCurrentStep = currentStep === 2;
  const isStepDisabled = currentStep < 2;

  const trackAndNavigate = () => {
    track(TEST_ASSISTANT_CLICKED);
    navigate('/assistant-tester');
  };

  useEffect(() => {
    if (faqs.length > 0 && botConfiguration.status == BotStatus.Tested) {
      setCurrentStep(3);
    }
  }, [faqs, setCurrentStep]);

  if (isCurrentStep) {
    return (
      <>
        <Step
          number={2}
          buttonArrow={true}
          title="Veja como eu funciono!"
          description="Converse comigo para ver como eu vou responder seus clientes no Insta!"
          buttonLabel="Teste assistente"
          onClickCallback={trackAndNavigate}
        />
      </>
    );
  }
  if (isStepDisabled) {
    return <StepDisabled step={2} text="Veja como eu funciono!" />;
  }
}

export default SimulatorStep;
