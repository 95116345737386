import { BdsGrid, BdsStep, BdsStepper } from 'blip-ds/dist/blip-ds-react';
import Sidebar from 'components/Sidebar';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function ActiveMessages() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  useEffect(() => {
    const stepper = document.querySelector('bds-stepper');
    stepper?.setActiveStep(0);
    navigate('template');
  }, []);

  useEffect(() => {
    const stepper = document.querySelector('bds-stepper');
    switch (pathname) {
      case '/campaign/template':
        stepper?.resetCompletedSteps();
        stepper?.setActiveStep(0);
        break;
      case '/campaign/configuration':
      case '/campaign/direction':
        stepper?.resetCompletedSteps();
        stepper?.setCompletedStep(0);
        stepper?.setActiveStep(1);
        break;
      case '/campaign/confirm':
        stepper?.resetCompletedSteps();
        stepper?.setCompletedStep(0);
        stepper?.setCompletedStep(1);
        stepper?.setActiveStep(2);
        break;
      default:
        break;
    }
  }, [pathname]);

  return (
    <>
      <Sidebar />
      <BdsGrid direction="column" margin="x-8" padding="12" gap="2">
        <BdsStepper data-testid="campaign-stepper">
          <BdsStep>Selecionar modelo</BdsStep>
          <BdsStep>Configurar disparo</BdsStep>
          <BdsStep>Confirmar envio</BdsStep>
        </BdsStepper>
        <div>
          <Outlet />
        </div>
      </BdsGrid>
    </>
  );
}

export default ActiveMessages;
