// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  max-width: 30rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramChooseOtherPageModal/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".modal {\n  max-width: 30rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
