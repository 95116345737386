import { atom } from 'jotai';
import { FacebookAppInformation, FbPagesResult } from 'types/Facebook';
import { MetaAuthResponse } from 'types/Whatsapp';

export const firstDashboardVisit = atom(true);
export const currentActivationStep = atom(1);
export const instagramUsername = atom<null | string>(null);
export const facebookAppInfo = atom<null | FacebookAppInformation>(null);
export const selectedFacebookPage = atom<null | FbPagesResult>(null);
export const facebookAuthResponse = atom<null | MetaAuthResponse>(null);
export const errorMessageMeta = atom<string>('');
export const isMetaError = atom<boolean>(false);
export const signerInfo = atom<null | SignerInfo>(null);
