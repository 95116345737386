import { WebStorageStateStore } from 'oidc-client-ts';

const HOST = `${window.location.origin}/`;
const CLIENT_SECRETS = [
  '}A n2p,Y$:g^Rt61fI[0~3-Q2NDPo6aPMcu"OcaN"N<SY',
  " O/>+nW=x)~f|;1wH8J#=gZ4O.Wo^'],[@FL#pc@a>&gL",
  '"1a+/Bi^$"f?59FwodX5":WglnOHUA$fgrC8y8y)$A@1 % ',
  "{H!gD!$lTt(kgP6,}-<KY`Si,q_C{;m'>(>,.)(203L,",
];

const urlParams = new URLSearchParams(location.search);
const si = urlParams.get('si');

export const OIDC = {
  authority: process.env.REACT_APP_AUTHORITY,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: `${location.origin}?si=${si}&journey_simple=1`,
  responseType: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_ACNT_SCOPE,
  post_logout_redirect_uri: HOST,
  userStore: new WebStorageStateStore({ store: localStorage }),
  automaticSilentRenew: false,
  silent_redirect_uri: `${HOST}renew-callback.html`,
  accessTokenExpiringNotificationTime: parseInt(process.env.REACT_APP_TOKEN_EXPIRING_MIN || '5') * 60,
  clientSecret: CLIENT_SECRETS[Math.floor(Math.random() * CLIENT_SECRETS.length)],
};
