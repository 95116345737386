import { useAppContext } from 'contexts/AppContext';
import { useEffect } from 'react';
import { BotStatus } from 'types/Bot';
import usePlgApi from './usePlgApi';

function useAssistantManager({ statusControlEnabled }: { statusControlEnabled: boolean }) {
  const { whatsAppActivated, botConfiguration, instagramActivated, faqs, facebookUserData } = useAppContext();
  const { setBotStatus } = usePlgApi();

  const isAssistTested = botConfiguration.status === BotStatus.Tested;
  const hasSubmittedAnyData = faqs?.length > 0 || facebookUserData?.isCatalogSubmitted;
  const isAssistPaused = botConfiguration.status > BotStatus.Active;
  const isAssistActive = botConfiguration.status === BotStatus.Active;
  const hasAssistBeenActivated = botConfiguration.status >= BotStatus.Active;

  useEffect(() => {
    if (!statusControlEnabled) return;

    if (!whatsAppActivated && !isAssistTested) {
      setBotStatus(BotStatus.Created);
    } else if (!hasSubmittedAnyData) {
      setBotStatus(BotStatus.PausedWithNoData);
    } else if (hasSubmittedAnyData) {
      if (botConfiguration.status !== BotStatus.Paused && whatsAppActivated) {
        setBotStatus(BotStatus.Active);
      }
    }
  }, [botConfiguration.status, hasSubmittedAnyData, instagramActivated, setBotStatus, whatsAppActivated]);

  return {
    hasSubmittedAnyData,
    hasAssistBeenActivated,
    isAssistTested,
    isAssistPaused,
    isAssistActive,
  };
}

export default useAssistantManager;
